export const SET_ALL_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA = 'tools/SET_ALL_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA';
export const SET_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA = 'tools/SET_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA';
export const UPDATE_DAILY_ALLOWANCE_TYPE_OPTIONS = 'tools/UPDATE_DAILY_ALLOWANCE_TYPE_OPTIONS';
export const SET_DAILY_ALLOWANCE_TYPES_OPTIONS = 'tools/SET_DAILY_ALLOWANCE_TYPES_OPTIONS';
export const UPDATE_DAILY_ALLOWANCE_TYPE = 'tools/UPDATE_DAILY_ALLOWANCE_TYPE';
export const SET_DAILY_ALLOWANCE_TYPES = 'tools/SET_DAILY_ALLOWANCE_TYPES';
export const ADD_DAILY_ALLOWANCE_TYPE = 'tools/ADD_DAILY_ALLOWANCE_TYPE';
export const SET_SHOULD_REFRESH_OFF = 'tools/SET_SHOULD_REFRESH_OFF';
export const SET_SHOULD_REFRESH_ON = 'tools/SET_SHOULD_REFRESH_ON';
export const ADD_NEW_CUSTOM_RULE = 'tools/ADD_NEW_CUSTOM_RULE';
export const UPDATE_CUSTOM_RULE = 'tools/UPDATE_CUSTOM_RULE';
export const DELETE_CUSTOM_RULE = 'tools/DELETE_CUSTOM_RULE';