import { DailyAllowanceType } from '../../enums/DailyAllowanceType';
import Yup from '../../utils/validation';

const validateMessages = {
  company: {
    required: 'Company should be provided',
  },
  businessVertical: {
    required: 'Business vertical should be provided',
  },
  currency: {
    required: 'Currency should be provided',
  },
};

export const dailyAllowanceTypeSchema = Yup.object().shape({
  company: Yup
    .number()
    .when('applyToAllCompanies', {
      is: value => value,
      then: Yup
        .number()
        .nullable(),
      otherwise: Yup
        .number()
        .required(validateMessages.company.required)
        .typeError(validateMessages.company.required),
    }),
  applyToAllCompanies: Yup
    .bool()
    .required(),
  businessVertical: Yup
    .number()
    .when('applyToAllBusinessVerticals', {
      is: value => value,
      then: Yup
        .number()
        .nullable(),
      otherwise: Yup
        .number()
        .required(validateMessages.businessVertical.required)
        .typeError(validateMessages.businessVertical.required),
    }),
  applyToAllBusinessVerticals: Yup
    .bool()
    .required(),
  currency: Yup
    .number()
    .required(validateMessages.currency.required)
    .typeError(validateMessages.currency.required),
});

export const getInitialValues = (data?: DailyAllowanceType | null) => ({
  applyToAllBusinessVerticals: data ? !data?.businessVertical?.id : false,
  applyToAllCompanies: data ? !data?.company?.id : false,
  businessVertical: data?.businessVertical?.id || null,
  isDefault: data?.isDefault ? true : false,
  currency: data?.currency?.id || null,
  company: data?.company?.id || null,
});
