import { DailyAllowanceType } from '../../../enums/DailyAllowanceType';
import { formatLabelForApplyToAll } from '../../../utils/tableHelpers';

export const formatDailyAllowances = (dailyAllowances: DailyAllowanceType[]) => {
  return dailyAllowances.map((dailyAllowance) => ({
    ...dailyAllowance,
    businessVertical: dailyAllowance?.businessVertical?.id
      ? dailyAllowance?.businessVertical?.name
      : formatLabelForApplyToAll('business verticals'),
    currency: dailyAllowance.currency.code.toUpperCase(),
    company: dailyAllowance?.company?.id
      ? dailyAllowance?.company?.name
      : formatLabelForApplyToAll('companies'),
  }));
};

export const formatDeleteDialogText = (allowancesCheckboxesData, isActiveAllAllowancesCheckboxes) => {
  let title = 'Do you want to delete';

  if ((allowancesCheckboxesData && allowancesCheckboxesData.length > 1) || isActiveAllAllowancesCheckboxes) {
    return `${title} these allowances?`;
  }

  return `${title} this allowance?`;
};
