import { useState, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Form, FormikProvider, useFormik } from 'formik';
import { get, isEmpty } from 'lodash';
import { GenericEdit } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import { checkOnIsEqual } from '../../../utils/commonFunctions';
import DynamicFormFields from '../../../components/DynamicFormFields';
import EllipsisText from '../../../components/EllipsisText';
import Accordion from '../../../components/Accordion';
import {
  getInitialFavoriteHotels,
  userFavoriteHotelsSchema,
} from './schema';
import { IUserFavoriteHotelsForm } from './types';
import {
  OverviewItemsWrapper,
  GroupInputWrapper,
  EditButtonWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from '../UserDrawerContent/styles';
import SelectWithExternalDataSource from '../../../components/SelectWithExternalDataSource';
import { fetchCities, fetchHotels } from './utils';

const UserFavoriteHotelsForm: React.FC<IUserFavoriteHotelsForm> = ({
  isCurrentUser,
  data,
  updateUserFavoriteHotels,
}) => {
  const [isEditFavoriteHotels, setIsEditFavoriteHotels] = useState(false);
  const initialFavoriteHotelsValues = useMemo(() => getInitialFavoriteHotels(data.favoriteHotels), [data.favoriteHotels]);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userFavoriteHotelsSchema,
    validateOnMount: true,
    initialValues: initialFavoriteHotelsValues,
    onSubmit: (values): void => {
      updateUserFavoriteHotels(data.id, values, setIsEditFavoriteHotels);
    },
  });
  const { touched, errors, values, resetForm, setFieldValue, setFieldTouched } = formik;

  useEffect(() => {
    setIsEditFavoriteHotels(false);
    resetForm();
  }, [data.id]);

  return (
    <Accordion title="Favorite Hotels" openByDefault withoutPadding>
      {!isEditFavoriteHotels && !isCurrentUser && (
        <EditButtonWrapper>
          <Button
            variant="ghost"
            onClick={() => setIsEditFavoriteHotels(!isEditFavoriteHotels)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <FormikProvider value={formik}>
        <Form>
          {isEditFavoriteHotels ? (
            <DynamicFormFields
              withoutLimit
              fieldName="favoriteHotels"
              touched={touched}
              errors={errors}
              data={values.favoriteHotels}
              setFieldValue={setFieldValue}
              getDefaultValue={() => ({ id: uuidv4(), userId: data.id, hotelId: undefined, regionId: undefined })}
              renderItem={(fieldName, value, index) => (
                <GroupInputWrapper>
                  <SelectWithExternalDataSource
                    currentValue={value.regionId}
                    placeholder='Select city'
                    position="left"
                    isError={!!(get(touched, [fieldName, index, 'regionId']) && get(errors, [fieldName, index, 'regionId']))}
                    fetchOptions={(params: any) => fetchCities(params)}
                    resetValues={() => (
                      setFieldValue(`${fieldName}.${index}.regionId`, undefined, true)
                      && setFieldValue(`${fieldName}.${index}.hotelId`, undefined, true)
                    )}
                    onChange={(value) => {
                      setFieldValue(`${fieldName}.${index}.regionId`, value, true);
                      setFieldValue(`${fieldName}.${index}.hotelId`, undefined, true);
                    }}
                    onBlur={() => setFieldTouched(`${fieldName}.${index}.regionId`, true, false)}
                  />
                  <SelectWithExternalDataSource
                    dependOnFieldValue={value.regionId}
                    isDependedFiled
                    currentValue={value.hotelId}
                    placeholder='Select hotel'
                    isDisabled={!value.regionId}
                    position="right"
                    isError={!!(get(touched, [fieldName, index, 'hotelId']) && get(errors, [fieldName, index, 'hotelId']))}
                    fetchOptions={(params: any) => fetchHotels(params, value.regionId)}
                    resetValues={() => (
                      setFieldValue(`${fieldName}.${index}.hotelId`, undefined, true)
                    )}
                    onChange={(value) => {
                      setFieldValue(`${fieldName}.${index}.hotelId`, value, true);
                    }}
                    onBlur={() => setFieldTouched(`${fieldName}.${index}.hotelId`, true, false)}
                  />
                </GroupInputWrapper>
              )}
            />
          ) : (
            <>
              {data.favoriteHotels.map(favoriteHotels => (
                <OverviewItemsWrapper key={favoriteHotels?.hotel?.id}>
                  <OverviewItemName>
                    <EllipsisText text={favoriteHotels?.hotel?.region?.city} />
                  </OverviewItemName>
                  <OverviewItem>
                    <EllipsisText text={favoriteHotels?.hotel?.name} />
                  </OverviewItem>
                </OverviewItemsWrapper>
              ))}
            </>
          )}
          {isEditFavoriteHotels && (
            <ButtonsWrapper>
              <Button
                variant="secondary"
                type="reset"
                onClick={() => {
                  resetForm();
                  setIsEditFavoriteHotels(!isEditFavoriteHotels);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                disabled={!isEmpty(errors) ||  checkOnIsEqual(values, initialFavoriteHotelsValues)}
              >
                Save
              </Button>
            </ButtonsWrapper>
          )}
        </Form>
      </FormikProvider> 
    </Accordion>
  );
};

export default UserFavoriteHotelsForm;
