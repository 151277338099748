import { UserStatuses } from '../../enums/UserStatuses';
import request from '../../models/request';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import { setIsLoadingData } from '../spinner/actions';
import {
  SET_LOADING_USERS_OPTIONS_DATA,
  SET_UPDATE_OVERVIEW_DETAILS,
  CLOSE_DELETE_USER_MODAL,
  SET_USERS_OPTIONS_DATA,
  OPEN_DELETE_USER_MODAL,
  SET_UPDATE_USER_ROLE,
  CLOSE_USER_OVERVIEW,
  SHOW_USER_OVERVIEW,
  SET_USERS_DATA,
  TOGGLE_FILTERS,
  CLOSE_FILTERS,
  DELETE_USER,
  SET_EMPLOYEES_FOR_DELETION_DATA,
  OPEN_DELETE_UNEMPLOYED_USER_MODAL,
  OPEN_UNEMPLOYED_USERS_LIST,
  CLOSE_UNEMPLOYED_USERS_LIST,
  SET_ANONYMIZE_USER,
} from './actionTypes';

const setUsersData = (data) => ({ type: SET_USERS_DATA, payload: { data } });

export const openDeleteUserModal = (user) => ({
    payload: { user: {...user, anonymizeUser: user.statusName === 'external'} },
    type: OPEN_DELETE_USER_MODAL,
  });

export const setAnonymizeUser = (anonymizeUser) => ({
    payload: anonymizeUser,
    type: SET_ANONYMIZE_USER,
  });

export const closeDeleteUserModal = (user) => ({
  payload: { user },
  type: CLOSE_DELETE_USER_MODAL,
});

export const deleteUser = (userData,  onFinish) => async (dispatch, getState) => {
  dispatch(setIsLoadingData(true));

  try {
    const store = getState();
    const { userId, anonymizeUser } = userData;
    const user = store.usersReducer.usersData.find((item) => item.id === userId);

    if (user?.status === UserStatuses.Unregistered) {
      await request.delete(`users/unregistered/${userId}`, { data: { anonymizeUser } });
    } else if (user?.status === UserStatuses.External) {
      await request.delete(`users/external/${userId}`, { data: { anonymizeUser } });
    } else {
      await request.delete(`users/${userId}`, { data: { anonymizeUser } });
    }

    const {data} = await request.get(`users/should-be-deleted`);
    dispatch(setEmployeesForDeletionData(data));

    dispatch({ type: DELETE_USER });
    getErrorStatusMessage({
      status: 200,
      message: `Success!`,
    });
    onFinish();
  } catch(e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong',
    });
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const showUserOverview = (user, loyaltyNumbers, favoriteHotels, cryptoWallets) => ({
  type: SHOW_USER_OVERVIEW,
  payload: {
    cryptoWallets,
    favoriteHotels,
    loyaltyNumbers,
    user,
  },
});

export const closeUserOverview = () => ({
  type: CLOSE_USER_OVERVIEW,
});

export const toggleFilters = () => ({
  type: TOGGLE_FILTERS,
});

export const closeFilters = () => ({
  type: CLOSE_FILTERS,
});

export const getUserLoyaltyNumbers = async (userId) => {
  try {
    const { data } = await request.get(`user/loyalty-numbers/${userId}`);
    return data.loyaltyNumbers;
  } catch (err) {
    return [];
  }
};

export const getUserFavoriteHotels = async (userId) => {
  try {
    const { data } = await request.get(`user/favorite-hotels/${userId}`);
    return data.favoriteHotels;
  } catch (err) {
    return [];
  }
};

export const getUserCryptoWallets = async (userId) => {
  try {
    const { data } = await request.get(`user/crypto-wallets/${userId}`);
    return data.cryptoWallets;
  } catch (err) {
    return [];
  }
};

export const updateUserLoyaltyNumbers = (user, data, setIsEditForm) => async (dispatch) => {
  try {
    const requestUrl = `user${user.status === UserStatuses.External ? '/external' : ''}/loyalty-numbers/${user.id}`;
    await request.post(requestUrl, data);
    setIsEditForm(false);
    dispatch(setUpdateOverviewDetails(true));
    getErrorStatusMessage({
      status: 200,
      message: `Success!`,
    });
  } catch (err) {
    setIsEditForm(true);
    getErrorStatusMessage({
      status: err?.response?.status,
      message: 'Something went wrong',
    });
  } finally {
    dispatch(setUpdateOverviewDetails(false))
  }
};

export const updateUserFavoriteHotels = (userId, data, setIsEditForm) => async (dispatch) => {
  try {
    const favoriteHotels = (data?.favoriteHotels || []).map(item => ({ userId, hotelId: item?.hotelId }));
    await request.post(`user/favorite-hotels/${userId}`, { favoriteHotels });
    setIsEditForm(false);
    dispatch(setUpdateOverviewDetails(true));
    getErrorStatusMessage({
      status: 200,
      message: `Success!`,
    });
  } catch (err) {
    setIsEditForm(true);
    getErrorStatusMessage({
      status: err?.response?.status,
      message: 'Something went wrong',
    });
  } finally {
    dispatch(setUpdateOverviewDetails(false))
  }
};

export const fetchUsersData = (params: any, isUpdateOverview = false) => async (dispatch, getState) => {
  dispatch(setIsLoadingData(true));

  try {
    let response;

    if (params?.filters?.status === UserStatuses.Unregistered) {
      response = await request.get('users/unregistered', { params });
    } else if (params?.filters?.status === UserStatuses.External) {
      response = await request.get('users/external', { params });
    } else {
      response = await request.get('users', { params });
    }
  
    dispatch(setUsersData(response.data));
  } catch (err) {
    console.error(err);
  } finally {
    const store = getState();
    
    dispatch(setIsLoadingData(false));

    if (isUpdateOverview) {
      const users =  store.usersReducer.usersData;
      const userFoUpdateId = store.usersReducer.overviewData?.id;
      const newOverviewData = users.find((item) => item.id === userFoUpdateId);

      if (newOverviewData) {
        const loyaltyNumbers = await getUserLoyaltyNumbers(userFoUpdateId);
        const favoriteHotels = await getUserFavoriteHotels(userFoUpdateId);
        const cryptoWallets = await getUserCryptoWallets(userFoUpdateId);
        dispatch(showUserOverview(newOverviewData, loyaltyNumbers, favoriteHotels, cryptoWallets));
      } else {
        dispatch(closeUserOverview());
      }
    }
  }
};

const setUpdateUserRole = (data) => ({ type: SET_UPDATE_USER_ROLE, payload: { data } });

const setUpdateOverviewDetails = (isUpdated) => ({ type: SET_UPDATE_OVERVIEW_DETAILS, payload: { isUpdated } });

export const updateOverviewDetails = (user, data, setIsEditForm) => async (dispatch) => {
  try {
    if (user.status === UserStatuses.Unregistered) {
      await request.update(`users/unregistered/${user.id}`, {
        businessVerticalId: data.businessVerticalId,
        employerId: data.employerId,
        assistants: data.assistants,
        firstname: data.firstname,
        lastname: data.lastname,
        teamId: data.teamId,
        role: data.role,
      });
    } else if (user.status === UserStatuses.External) {
      await request.update(`users/external/${user.id}`, {
        businessVerticalId: data.businessVerticalId,
        employerId: data.employerId,
        firstname: data.firstname,
        lastname: data.lastname,
        teamId: data.teamId,
      });
    } else {
      await request.post(`user/change-overview-details/${user.id}`, data);
    }

    setIsEditForm(false);
    dispatch(setUpdateOverviewDetails(true));

    getErrorStatusMessage({
      status: 200,
      message: `Success!`,
    });
  } catch (e) {
    setIsEditForm(false);
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong',
      });
  } finally {
    dispatch(setUpdateOverviewDetails(false))
  }
};

export const updateOverviewDocumentDetails = (user, values, setIsEditForm) => async (dispatch) => {
  try {
    await request.update(`user/change-travel-document-details/${user.id}`, values);
    setIsEditForm(false);
    dispatch(setUpdateOverviewDetails(true));
    getErrorStatusMessage({
      status: 200,
      message: `Success!`,
    });
  } catch (e) {
    setIsEditForm(true);
    getErrorStatusMessage({
      status: e?.response?.status,
      message: 'Something went wrong',
    });
  } finally {
    dispatch(setUpdateOverviewDetails(false))
  }
}; updateOverviewDocumentDetails

export const updateOverviewPassengerDetails = (user, values, setIsEditForm) => async (dispatch) => {
  try {
    const requestUrl = `user${user.status === UserStatuses.External ? '/external' : ''}/change-profile-settings/${user.id}`;
    await request.update(requestUrl, values);
    setIsEditForm(false);
    dispatch(setUpdateOverviewDetails(true));
    getErrorStatusMessage({
      status: 200,
      message: `Success!`,
    });
  } catch (e) {
    setIsEditForm(true);
    getErrorStatusMessage({
      status: e?.response?.status,
      message: 'Something went wrong',
    });
  } finally {
    dispatch(setUpdateOverviewDetails(false))
  }
};

export const updateUserRole = (userId, newRole, callback) => async (dispatch) => {
  try {
    await request.post(`users/roles/${userId}`, { newRole });
    dispatch(setUpdateUserRole(true));
    callback();
    getErrorStatusMessage({
      status: 200,
      message: `The user's role successfully changed.`,
    });
  } catch(e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: `The user's role can't be changed`,
    });
  } finally {
    dispatch(setUpdateUserRole(false))
  }
};

const setOptionsData = (data) => ({ type: SET_USERS_OPTIONS_DATA, payload: { data } });

const setLoadingUsersOptionsData = (data) => ({ type: SET_LOADING_USERS_OPTIONS_DATA, payload: { isLoadingUsersOptions: data } });

export const fetchOptionsData = () => async (dispatch) => {
  try {
    dispatch(setLoadingUsersOptionsData(true));
    const response = await request.get('users/options');
    dispatch(setOptionsData(response.data));
    dispatch(setLoadingUsersOptionsData(false));
  } catch (e) {
    dispatch(setLoadingUsersOptionsData(false));
    console.error(e)
  }
};

export const updateUserCryptoWallets = ({ userId, data, setIsEditForm, resetForm }) => async (dispatch) => {
  try {
    await request.post(`user/crypto-wallets/${userId}`, { cryptoWallets: data.cryptoWallets });
    setIsEditForm(false);
    dispatch(setUpdateOverviewDetails(true));
    getErrorStatusMessage({
      status: 200,
      message: `Success!`,
    });
  } catch (err) {
    setIsEditForm(true);
    getErrorStatusMessage({ 
      status: err?.response?.status, 
      message: err.response?.data?.error ? err.response?.data?.error : 'Something went wrong',
    });
    resetForm();
  } finally {
    dispatch(setUpdateOverviewDetails(false))
  }
};

const setEmployeesForDeletionData = (data) => ({ type: SET_EMPLOYEES_FOR_DELETION_DATA, payload: { data } });

export const fetchEmployeesForDeletionData = () => async (dispatch) => {
  try {
    const {data} = await request.get(`users/should-be-deleted`);

    dispatch(setEmployeesForDeletionData(data));
  } catch (err) {
    return [];
  }
};

export const openDeleteUnemployedUserModal = (user) => ({
  payload: { user },
  type: OPEN_DELETE_UNEMPLOYED_USER_MODAL,
});

export const closeDeleteUnemployedUserModal = (user) => ({
  payload: { user },
  type: CLOSE_DELETE_USER_MODAL,
});

export const openUnemployedUsersList = () => ({
  payload: {},
  type: OPEN_UNEMPLOYED_USERS_LIST,
});

export const closeUnemployedUsersList = () => ({
  payload: {},
  type: CLOSE_UNEMPLOYED_USERS_LIST,
});