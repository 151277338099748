import { useEffect, useState } from 'react';
import { GenericEdit } from '@heathmont/moon-icons';
import { Button, Checkbox } from '@heathmont/moon-core';
import { getBoolStatusLabel, formatBusinessVerticalLabels } from '../../utils/tableHelpers';
import { IEmployerOverviewDrawerContent } from './types';
import {
  OverviewContentValue,
  OverviewContentName,
  EditButtonWrapper,
  OverviewContent,
} from './styles';
import EmployersOverviewDrawerEditContent from '../EmployersOverviewDrawerEditContent';
import { N_A } from '../../utils/commonFunctions';
import { EmployerTypes } from '../../enums/EmployerTypes';

const EmployersOverviewDrawerContent: React.FC<IEmployerOverviewDrawerContent> = ({ data, companies }): JSX.Element => {
  const [isEditFormData, setIsEditFormData] = useState(false);
  const { id, registrationNumber, name, company, status, businessVerticals } = data;

  useEffect(() => {
    setIsEditFormData(false);
  }, [id]);

  return (
    <div>
      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            onClick={() => setIsEditFormData(!isEditFormData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <OverviewContent>
        <OverviewContentName>ID</OverviewContentName>
        <OverviewContentValue>{id}</OverviewContentValue>
      </OverviewContent>

      {!isEditFormData ? (
        <>
          <OverviewContent>
            <OverviewContentName>Employer</OverviewContentName>
            <OverviewContentValue>{name}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Company</OverviewContentName>
            <OverviewContentValue>{company?.name}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Only external users</OverviewContentName>
            <OverviewContentValue>
              <Checkbox checked={data?.type === EmployerTypes.EXTERNAL} disabled />
            </OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Business Verticals</OverviewContentName>
            <OverviewContentValue>{businessVerticals?.length ? formatBusinessVerticalLabels(businessVerticals, true) : null}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Registration number</OverviewContentName>
            <OverviewContentValue>{registrationNumber || N_A}</OverviewContentValue>
          </OverviewContent>

          <OverviewContent>
            <OverviewContentName>Status</OverviewContentName>
            <OverviewContentValue>
              {getBoolStatusLabel(status)}
            </OverviewContentValue>
          </OverviewContent>
        </>
      ) : (
        <EmployersOverviewDrawerEditContent
          companies={companies}
          data={data}
          setIsEditFormData={setIsEditFormData}
        />
      )}
    </div>
  );
};

export default EmployersOverviewDrawerContent;
