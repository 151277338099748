import { useState } from 'react';
import { Text, Button } from '@heathmont/moon-core';
import { IDialog } from './types';
import { ReactComponent as CloseSvg } from '../.././static/icons/close.svg';
import {
  ConfirmationWrapper,
  ButtonsWrapper,
  DialogWrapper,
  DeleteButton,
  CloseIcon,
  TitleText,
  Wrapper,
  Content,
  Footer,
  Title,
} from './styles';
import { HiddenRolePopoverBackground } from '../../styled';


const Dialog: React.FC<IDialog> = ({
  submitButtonLabel = 'Save',
  isSubmitDisabled,
  withoutMinWidth,
  withConfirmation = false ,
  withCloseIcon = true,
  withFooter = true,
  isLoading = false,
  maxWidth,
  children,
  title,
  width,
  data,
  blur,
  renderAdditionalActions,
  onSubmit,
  onClose,
}) => {
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);

  const handleLeave = () => {
    setShowLeaveConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowLeaveConfirmation(false);
  };

  return (
    <>
      <HiddenRolePopoverBackground
        withBackground
        blur={blur}
        onClick={!withConfirmation ? onClose : handleLeave} 
      />

      <DialogWrapper>
        <Wrapper maxWidth={maxWidth} width={width} withoutMinWidth={withoutMinWidth} isConfirmationOn={showLeaveConfirmation}>
          <Title>
            <TitleText>
              <Text size={18}>{title}</Text>
            </TitleText>
            
            {withCloseIcon && (
              <CloseIcon onClick={!withConfirmation ? onClose : handleLeave}>
                <CloseSvg />
              </CloseIcon>
            )}
          </Title>

          <Content>{children}</Content>
          {
            withFooter && (
              <Footer>
                {renderAdditionalActions && renderAdditionalActions()}
                
                <ButtonsWrapper>
                  <Button variant="secondary" onClick={!withConfirmation ? onClose : handleLeave}>Cancel</Button>
                  {onSubmit && (
                    <Button {...(isLoading ? {animation:"progress"} : {})} disabled={isSubmitDisabled || isLoading} onClick={() => onSubmit(data)}>
                      {submitButtonLabel}
                    </Button>
                  )}
                </ButtonsWrapper>
              </Footer>
            )
          }
        </Wrapper>
        <ConfirmationWrapper isConfirmationOn={showLeaveConfirmation}>
        <Title>
            <TitleText>
              <Text size={18}>Unsaved changes</Text>
            </TitleText>
          </Title>
          <Content>
          <Text color={"bulma.100"}>You have unsaved changes. All changes will be lost. Do you want to continue?</Text>
          </Content>
          {
            withFooter && (
              <Footer>
                <ButtonsWrapper>
                  <Button variant="secondary" onClick={handleCloseConfirmation}>Cancel</Button>
                    <DeleteButton>
                      <Button variant={"tertiary"} onClick={() => onClose()}>
                      Leave
                    </Button>
                  </DeleteButton>
                </ButtonsWrapper>
              </Footer>
            )
          }
        </ConfirmationWrapper>
      </DialogWrapper>
    </>
  );
};

export default Dialog;
