import { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { get, isEmpty } from 'lodash';
import { GenericEdit } from '@heathmont/moon-icons';
import { TextInput } from '@heathmont/moon-components';
import { Button } from '@heathmont/moon-core';
import { IDailyAllowanceTypeOptionsForm } from './types';
import {
  OverviewItemsWrapper,
  OptionsBottomElement,
  EditButtonWrapper,
  GroupInputWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from '../DailyAllowanceOverviewDrawerContent/styles';
import FormField from '../FormField';
import { dailyAllowanceTypeOptionsSchema } from './schema';
import Accordion from '../Accordion';
import DynamicFormFields from '../DynamicFormFields';
import EllipsisText from '../EllipsisText';

const DailyAllowanceTypeOptionsForm: React.FC<IDailyAllowanceTypeOptionsForm> = ({
  data,
  handleUpdateOptions,
}) => {
  const [isEditOptionsData, setIsEditOptionsData] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: dailyAllowanceTypeOptionsSchema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: { options: data.options },
    onSubmit: (values, { resetForm }) => {
      handleUpdateOptions(values);
      setIsEditOptionsData(false);
      resetForm();
    },
  });
  const { touched, values, errors, setFieldValue, setFieldTouched, resetForm } = formik;

  useEffect(() => {
    setIsEditOptionsData(false);
  }, [data.id]);

  return (
    <Accordion title="Options" openByDefault withoutPadding>
      {!isEditOptionsData && (
        <EditButtonWrapper>
          <Button
            variant="ghost"
            onClick={() => setIsEditOptionsData(!isEditOptionsData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      {isEditOptionsData ? (
        <FormikProvider value={formik}>
          <Form>
            <DynamicFormFields
              maxItemsLength={10}
              fieldName="options"
              touched={touched}
              errors={errors}
              data={values.options}
              setFieldValue={setFieldValue}
              getDefaultValue={() => ({ id: uuidv4(), value: 0, description: '' })}
              renderItem={(fieldName, value, index) => {
                const valuePath = `${fieldName}.${index}.value`;
                const descriptionPath = `${fieldName}.${index}.description`;
                return (
                  <GroupInputWrapper>
                    <FormField
                      placeholder={'Value'}
                      fieldName={valuePath}
                      inputSize="large"
                      component={TextInput}
                      fieldId={valuePath}
                      isError={get(touched, valuePath) && get(errors, valuePath)}
                      value={value.value}
                      type="text"
                      onChange={({ target: { value } }) => setFieldValue(valuePath, value, true)}
                      onBlur={() => setFieldTouched(valuePath)}
                    />
                    <FormField
                      placeholder={'Description'}
                      fieldName={descriptionPath}
                      inputSize="large"
                      component={TextInput}
                      fieldId={descriptionPath}
                      isError={get(touched, descriptionPath) && get(errors, descriptionPath)}
                      value={value.description}
                      type="text"
                      onChange={({ target: { value } }) => setFieldValue(descriptionPath, value, true)}
                      onBlur={() => setFieldTouched(descriptionPath)}
                    />
                  </GroupInputWrapper>
                )
              }}
            />
            <ButtonsWrapper>
              <Button
                variant="secondary"
                type="reset"
                onClick={() => {
                  resetForm();
                  setIsEditOptionsData(!isEditOptionsData);
                }}
              >
                Cancel
              </Button>

              <Button variant="primary" type="submit" disabled={!isEmpty(errors)}>
                Save
              </Button>
            </ButtonsWrapper>
          </Form>
        </FormikProvider>
      ) : (
        <>
          {data.options.map((option) => (
            <OverviewItemsWrapper key={option.id}>
              <OverviewItem>
                <EllipsisText text={`${option.value} ${data.currency.code.toUpperCase()}`} />
              </OverviewItem>
              <OverviewItemName>{option.description}</OverviewItemName>
            </OverviewItemsWrapper>
          ))}
          <OptionsBottomElement />
        </>
      )}
    </Accordion>
  );
};

export default DailyAllowanceTypeOptionsForm;
