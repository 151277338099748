import Yup from '../../utils/validation';

const DESCRIPTION_MIN_LENGTH = 2;
const DESCRIPTION_MAX_LENGTH = 255;

const MIN_VALUE = 0;
const MAX_VALUE = 10000;

const MIN_OPTIONS_LENGTH = 1;
const MAX_OPTIONS_LENGTH = 10;

const validateMessages = {
  description: {
    required: 'Description cannot be empty',
    min: `Description cannot be less than ${DESCRIPTION_MIN_LENGTH} characters`,
    max: `Description cannot be longer than ${DESCRIPTION_MAX_LENGTH} characters`,
  },
  value: {
    required: 'Value cannot be empty',
    type: 'Value has to be a number',
    min: `Value has to be greater than ${MIN_VALUE}`,
    max: `Value cannot be greater than ${MAX_VALUE}`,
  },
  options: {
    min: `Min number of options is ${MIN_OPTIONS_LENGTH}`,
    max: `Max number of options is ${MAX_OPTIONS_LENGTH}`,
  },
};

export const dailyAllowanceTypeOptionSchema = Yup.object().shape({
  description: Yup
    .string()
    .min(DESCRIPTION_MIN_LENGTH, validateMessages.description.min)
    .max(DESCRIPTION_MAX_LENGTH, validateMessages.description.max)
    .required(validateMessages.description.required),
  value: Yup
    .number()
    .min(MIN_VALUE, validateMessages.value.min)
    .max(MAX_VALUE, validateMessages.value.max)
    .typeError(validateMessages.value.type)
    .required(validateMessages.value.required),
});

export const dailyAllowanceTypeOptionsSchema = Yup.object().shape({
  options: Yup
    .array()
    .of(dailyAllowanceTypeOptionSchema)
    .min(MIN_OPTIONS_LENGTH, validateMessages.options.min)
    .max(MAX_OPTIONS_LENGTH, validateMessages.options.max)
});
