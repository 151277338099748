import { connect } from 'react-redux';
import {
  updateOverviewPassengerDetails,
  closeDeleteUnemployedUserModal,
  openDeleteUnemployedUserModal,
  fetchEmployeesForDeletionData,
  updateOverviewDocumentDetails,
  updateUserLoyaltyNumbers,
  updateUserFavoriteHotels,
  closeUnemployedUsersList,
  updateUserCryptoWallets,
  openUnemployedUsersList,
  updateOverviewDetails,
  closeDeleteUserModal,
  openDeleteUserModal,
  closeUserOverview,
  showUserOverview,
  fetchOptionsData,
  setAnonymizeUser,
  fetchUsersData,
  updateUserRole,
  toggleFilters,
  closeFilters,
  deleteUser,
} from '../../store/users/actions';
import Users from './Users.ui';

const mapStateToProps = ({
  usersReducer: {
    isDeleteUnemployedUserModalOpen,
    isUnemployedUsersListOpen,
    isUpdateOverviewDetails,
    isLoadingUsersOptions,
    employeesForDeletion,
    isDeleteModalOpen,
    isUpdateUserRole,
    userForDeleting,
    isOpenOverview,
    isFiltersOpen,
    anonymizeUser,
    overviewData,
    usersData,
    options,
    total,
  },
  loadingReducer,
  currentUser,
}) => ({
  isDeleteUnemployedUserModalOpen,
  isUnemployedUsersListOpen,
  isUpdateOverviewDetails,
  isLoadingUsersOptions,
  employeesForDeletion,
  isDeleteModalOpen,
  isUpdateUserRole,
  userForDeleting,
  currentUserData: currentUser.currentUserData,
  isOpenOverview,
  anonymizeUser,
  isLoadingData: loadingReducer.isLoadingData,
  isFiltersOpen,
  overviewData,
  usersData,
  options,
  total,
});

const mapDispatchToProps = {
  updateOverviewPassengerDetails,
  closeDeleteUnemployedUserModal,
  openDeleteUnemployedUserModal,
  updateOverviewDocumentDetails,
  fetchEmployeesForDeletion: fetchEmployeesForDeletionData,
  updateUserFavoriteHotels,
  updateUserLoyaltyNumbers,
  closeUnemployedUsersList,
  updateUserCryptoWallets,
  openUnemployedUsersList,
  closeDeleteUserModal,
  openDeleteUserModal,
  closeUserOverview,
  showUserOverview,
  setAnonymizeUser,
  updateDetails: updateOverviewDetails,
  toggleFilters,
  fetchOptions: fetchOptionsData,
  closeFilters,
  fetchUsers: fetchUsersData,
  updateRole: updateUserRole,
  deleteUser,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Users);
