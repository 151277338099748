import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { Button, Text, TextInput } from '@heathmont/moon-core';
import CustomSelect from '../CustomSelect';
import { customRuleFormSchema } from './schema';
import FormField from '../FormField';
import { IDailyAllowanceCustomRuleForm } from './types';
import Dialog from '../Dialog';
import { DialogContent } from '../../pages/Trips/styles';
import {
  handleAddNewCustomRule,
  handleDeleteCustomRule,
  handleUpdateCustomRule,
} from '../../store/tools/dailyAllowanceTypes/actions';
import {
  GroupInputWrapper,
  OverviewItemName,
  ActionsWrapper,
  ButtonsWrapper,
  SelectWrapper,
  DeleteButton,
  InputWrapper,
  Wrapper,
} from './styles';

const normalizeOption = (obj) => {
  return {
    value: obj.id,
    title: obj.title,
  };
};

const DailyAllowanceCustomRuleForm: React.FC<IDailyAllowanceCustomRuleForm> = ({
  rulesOptions,
  allowanceId,
  ruleData,
  isAddNew = false,
  handleCancel,
}) => {
  const actionsOptions = rulesOptions.actions.map((act) => normalizeOption(act));
  const conditionsOptions = rulesOptions.conditions.map((cond) => normalizeOption(cond));

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    conditionValue: ruleData?.conditionValue || 0,
    actionValue: ruleData?.actionValue || 0,
    condition: ruleData?.condition || '',
    action: ruleData?.action || '',
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSubmit = (values) => {
    if (isAddNew) {
      dispatch(handleAddNewCustomRule(values, allowanceId, handleCancel));
    } else {
      dispatch(handleUpdateCustomRule(values, allowanceId, ruleData.id, handleCancel));
    }
  };

  const handleDeleteRule = () => {
    dispatch(handleDeleteCustomRule(allowanceId, ruleData.id, handleCancel));
  };

  return (
    <Wrapper>
      <Formik
        validateOnBlur={true}
        validationSchema={customRuleFormSchema}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }): void => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ errors, values, resetForm, setFieldValue, touched, handleBlur, setFieldTouched }: any): JSX.Element => {
          return (
            <Form>
              <OverviewItemName>WHEN</OverviewItemName>
              <GroupInputWrapper>
                <SelectWrapper>
                  <CustomSelect
                    currentValue={values.condition}
                    placeholder="Condition"
                    isError={touched?.condition && errors?.condition}
                    error={touched?.condition && errors?.condition}
                    items={conditionsOptions}
                    onChange={(value) => {
                      setFieldValue('condition', value);
                      setFieldTouched('condition', true, false);
                    }}
                  />
                </SelectWrapper>
                <InputWrapper>
                  <FormField
                    value={values.conditionValue}
                    fieldId="conditionValue"
                    fieldName="conditionValue"
                    component={TextInput}
                    type="text"
                    inputSize="large"
                    onChange={(e) => setFieldValue('conditionValue', e.target.value)}
                    onBlur={handleBlur}
                    errors={touched?.conditionValue && errors}
                    errorPosition={-20}
                  />
                </InputWrapper>
              </GroupInputWrapper>
              <OverviewItemName>THEN</OverviewItemName>
              <GroupInputWrapper>
                <SelectWrapper>
                  <CustomSelect
                    currentValue={values.action}
                    placeholder="Action"
                    isError={touched?.action && errors?.action}
                    error={touched?.action && errors?.action}
                    items={actionsOptions}
                    onChange={(value) => {
                      setFieldValue('action', value);
                      setFieldTouched('action', true, false);
                    }}
                  />
                </SelectWrapper>
                <InputWrapper>
                  <FormField
                    value={values.actionValue}
                    fieldId="actionValue"
                    fieldName="actionValue"
                    component={TextInput}
                    type="text"
                    inputSize="large"
                    onChange={(e) => setFieldValue('actionValue', e.target.value)}
                    onBlur={handleBlur}
                    errors={touched?.actionValue && errors}
                    errorPosition={-20}
                  />
                </InputWrapper>
              </GroupInputWrapper>

              <ActionsWrapper>
                {!isAddNew && (
                  <DeleteButton>
                    <Button type="button" onClick={handleOpenDeleteModal}>
                      Delete
                    </Button>
                  </DeleteButton>
                )}
                <ButtonsWrapper>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      handleCancel();
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button type="submit" variant="primary" disabled={!isEmpty(errors)}>
                    Save
                  </Button>
                </ButtonsWrapper>
              </ActionsWrapper>
            </Form>
          );
        }}
      </Formik>
      {isDeleteModalOpen && (
        <Dialog submitButtonLabel="Delete" title="Delete" onClose={handleCloseDeleteModal} onSubmit={handleDeleteRule}>
          <DialogContent>
            <Text size={16}>Do you want to delete this custom rule?</Text>
          </DialogContent>
        </Dialog>
      )}
    </Wrapper>
  );
};

export default DailyAllowanceCustomRuleForm;
