import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { ControlsPlus, SoftwareSorting } from '@heathmont/moon-icons';
import { GenericUserSwapping } from '@heathmont/moon-icons-tw';
import Helmet from 'react-helmet';
import { Button, Text } from '@heathmont/moon-core';
import request from '../../models/request';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import { initUsersFilters, useFilters } from '../../hooks/useFilters';
import { useCustomTable } from '../../hooks/useCustomTable';
import { useQuery } from '../../hooks/useQuery';
import { usersDataMapper } from '../../utils/tableHelpers';
import { prepareFilters } from '../../utils/filters';
import { usePageTitle } from '../../models/page';
import { getUserFavoriteHotels, getUserLoyaltyNumbers, getUserCryptoWallets } from '../../store/users/actions';
import UserInvitationForm from '../../components/UserInvitationForm';
import TableContentReciever from '../../components/TableContentReciever';
import { FiltersReciever } from '../../components/FiltersReciever';
import { TogglerWithCount } from '../../components/TogglerWithCount';
import TablePagination from '../../components/TablePagination';
import { Spinner } from '../../components/Spinner';
import Dialog from '../../components/Dialog';
import Drawer from '../../components/Drawer';
import { DEFAULT_SORTING, LIMIT_PER_PAGE } from '../../constants';
import { RowHeight } from '../Reports/suggestions';
import UserDrawerContent from './UserDrawerContent';
import { FilterHeaderWrapper } from '../../styled';
import { getUsersFilters, initialColumns } from './suggestions';
import {
  AdditionalActionsHeaderWrapper,
  ReasonsWrapper,
  DialogContent,
  TableWrapper,
  PageHeader,
  Wrapper,
  Reasons,
} from './styles';
import { IUsers } from './types';
import InfoBlock from '../../components/InfoBlock';
import UnemployedUsersOverview from '../../components/UnemployedUsersOverview';
import { formatPreventDeletionReason } from '../../components/UnemployedUsersOverview/utils';
import { Checkbox } from '@heathmont/moon-core';
import { PreventDeletionReason } from '../../enums/PreventDeletionReason';

const Users: React.FC<IUsers> = ({
  isDeleteUnemployedUserModalOpen,
  isUnemployedUsersListOpen,
  isUpdateOverviewDetails,
  isLoadingUsersOptions,
  employeesForDeletion,
  isDeleteModalOpen,
  isUpdateUserRole,
  currentUserData,
  userForDeleting,
  isOpenOverview,
  isFiltersOpen,
  isLoadingData,
  anonymizeUser,
  overviewData,
  usersData,
  options,
  total,
  updateOverviewPassengerDetails,
  closeDeleteUnemployedUserModal,
  openDeleteUnemployedUserModal,
  updateOverviewDocumentDetails,
  fetchEmployeesForDeletion,
  updateUserLoyaltyNumbers,
  updateUserFavoriteHotels,
  closeUnemployedUsersList,
  updateUserCryptoWallets,
  openUnemployedUsersList,
  closeDeleteUserModal,
  openDeleteUserModal,
  closeUserOverview,
  showUserOverview,
  setAnonymizeUser,
  toggleFilters,
  updateDetails,
  closeFilters,
  fetchOptions,
  fetchUsers,
  deleteUser,
}) => {
  const [isSyncUserUpdatesModalOpen, setIsSyncUserUpdatesModalOpen] = useState(false);
  const [userIdForSyncUpdates, setUserIdForSyncUpdates] = useState(null);
  const [isSyncUpdatesLoading, setIsSyncUpdatesLoading] = useState(false);
  const [isResendActivationLinkLoading, setIsResendActivationLinkLoading] = useState(false);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [isInviteUserLoading, setIsInviteUserLoading] = useState(false);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [sortBy, setSortBy] = useState(DEFAULT_SORTING.USERS);
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const { getQuery, setQuery } = useQuery();
  const { filters, filtersCount, toggleFilter, setFilter } = useFilters(getQuery(), initUsersFilters);
  const { page, perPage, nextPage, prevPage, setPage } = useCustomTable([], LIMIT_PER_PAGE.EXPENSES);

  const setQueryParams = () => {
    const params: any = {
      filters: prepareFilters(filters),
      perPage,
      page,
    };

    if (sortBy && sortBy.length) {
      params.sort = {
        field: sortBy[0].id,
        order: sortBy[0].desc ? 'desc' : 'asc',
      };
    }

    setQuery(params.filters);

    return params;
  };

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  useEffect(() => {
    fetchUsers(params);
    closeUserOverview();
  }, [isUpdateUserRole, filters, page, sortBy]);

  useEffect(() => {
    if (isUpdateOverviewDetails) {
      fetchUsers(params, true);
    }
  }, [isUpdateOverviewDetails, usersData]);

  useEffect(() => {
    fetchOptions();
    fetchEmployeesForDeletion();

    return () => {
      closeUserOverview();
      closeFilters();
    };
  }, []);

  const columns = useMemo(() => initialColumns, []);
  const usersGroupedData = useMemo(() => usersDataMapper(usersData), [usersData, isUpdateOverviewDetails]);
  const queryParams = useRef('');
  const totalItems = useRef(0);
  const pageTitle = usePageTitle('html.users.title');

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const setFiltersAndToFirstPage = (field: string, value: any) => {
    setPage(1);
    setFilter(field, value);
  };

  const toggleFiltersAndToFirstPage = (field, value) => {
    setPage(1);
    toggleFilter(field, value);
  };

  const filterComponents = useMemo(() => {
    return getUsersFilters(filters, options, toggleFiltersAndToFirstPage, setFiltersAndToFirstPage);
  }, [filters, options, toggleFiltersAndToFirstPage]);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const userId = userForDeleting?.userId || userForDeleting?.data?.id
      deleteUser({ userId, anonymizeUser }, () => fetchUsers(params));
    } catch (err) {
      console.error(err);
    }
  };

  const handleIsOverviewOpen = async (row?: any) => {
    const [loyaltyNumbers, favoriteHotels, cryptoWallets] = await Promise.all([
      getUserLoyaltyNumbers(row?.original?.userId),
      getUserFavoriteHotels(row?.original?.userId),
      getUserCryptoWallets(row?.original?.userId),
    ]);
    showUserOverview(usersData[row?.id], loyaltyNumbers, favoriteHotels, cryptoWallets);
  };

  useMemo(() => {
    totalItems.current = total;
  }, [total]);
  

  const openInviteUserModal = () => {
    setIsInviteUserModalOpen(true);
  };

  const closeInviteUserModal = () => {
    setIsInviteUserModalOpen(false);
  };
  
  const handleInviteUser = async (values) => {
    try {
      setIsInviteUserLoading(true);

      await request.post('user/invitation', values);

      getErrorStatusMessage({
        status: 200,
        message: `The user is invited successfully.`,
      });

      closeInviteUserModal();
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || `The user can not be invited. Please try again later.`,
      });
    } finally {
      setIsInviteUserLoading(false);
    }
  };

  const handleSyncUpdatesModalOpen = (userId: number) => {
    setUserIdForSyncUpdates(userId);
    setIsSyncUserUpdatesModalOpen(true);
  };

  const handleSyncUpdatesModalClose = () => {
    setUserIdForSyncUpdates(null);
    setIsSyncUserUpdatesModalOpen(false);
  };

  const handleSyncUpdates = async () => {
    try {
      setIsSyncUpdatesLoading(true);

      const response = await request.post(`users/sync-updates/${userIdForSyncUpdates}`);

      getErrorStatusMessage({
        status: 200,
        message: `The user is refreshed successfully.`,
      });

      fetchUsers(params);
      fetchOptions();
      closeUserOverview();
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error,
      });
    } finally {
      setIsSyncUpdatesLoading(false);
      handleSyncUpdatesModalClose();
    }
  };

  const handleResendActivationLink = async (userId) => {
    try {
      setIsResendActivationLinkLoading(true);

      await request.post(`users/resend-activation-link/${userId}`);

      getErrorStatusMessage({
        status: 200,
        message: 'Activation link was resent successfully.',
      });
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error,
      });
    } finally {
      setIsResendActivationLinkLoading(false);
    }
  };

  const tableContentRecieverProps = {
    isShowTableFilters,
    initialColumns: columns,
    limitPerPage: LIMIT_PER_PAGE.TRIPS,
    queryParams: queryParams.current,
    hoveredRow,
    withHover: true,
    rowHeight,
    sortBy,
    page,
    data: usersGroupedData,
    handleIsOverviewOpen,
    handleRemoveDialog: openDeleteUserModal,
    toggleTableFilters,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  const handleDeleteUnemployedUserModals = (user) => {
    if(user.reasons){
      openDeleteUnemployedUserModal(user);
    } else {
      openDeleteUserModal(user);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Wrapper>
        {isFiltersOpen && (
          <FiltersReciever
            filterComponents={filterComponents}
            isOpenDatePicker={false}
            placeholder="Search for users, email and more"
            filters={filters}
            setFilter={setFiltersAndToFirstPage}
          />
        )}

        <TableWrapper>
          <PageHeader>
            <FilterHeaderWrapper>
              <TogglerWithCount
                isActive={isFiltersOpen}
                count={filtersCount}
                handleToggle={toggleFilters}
                icon={<SoftwareSorting fontSize="1.5rem" />}
              />
              <Text size={32} as="h1" styles={{ marginBottom: 16 }}>
                Users
              </Text>
            </FilterHeaderWrapper>

            <AdditionalActionsHeaderWrapper>
              {!!employeesForDeletion?.length && (
                <TogglerWithCount
                  isActive={isUnemployedUsersListOpen}
                  count={employeesForDeletion.length}
                  handleToggle={openUnemployedUsersList}
                  icon={<GenericUserSwapping fontSize="1.5rem" />}
                  label={'unemployed users'}
                  position={'left'}
                />
              )}

              <Button iconLeft={<ControlsPlus fontSize="1.6rem" />} variant="primary" onClick={openInviteUserModal}>
                Invite user
              </Button>
            </AdditionalActionsHeaderWrapper>
          </PageHeader>

          <TablePagination
            isShowTableFilters={isShowTableFilters}
            limitPerPage={LIMIT_PER_PAGE.USERS}
            totalItems={totalItems}
            data={usersData}
            page={page}
            toggleTableFilters={toggleTableFilters}
            changeRowHeight={changeRowHeight}
            refetchData={() => fetchUsers(params)}
            nextPage={nextPage}
            prevPage={prevPage}
          />
          {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
        </TableWrapper>

        {isInviteUserModalOpen && (
          <Dialog withFooter={false} maxWidth={300} title="Invite user" onClose={closeInviteUserModal}>
            <UserInvitationForm
              businessVerticals={options.businessVerticals}
              employers={options.employers.filter(employer => !!employer.status)}
              loading={isInviteUserLoading}
              teams={options.teams.filter(team => !!team.status)}
              roles={options.roles}
              onSubmit={handleInviteUser}
              onCancel={closeInviteUserModal}
            />
          </Dialog>
        )}

        {isDeleteModalOpen && (
          <Dialog
            submitButtonLabel="Delete"
            title={`Delete user — ${userForDeleting?.fullName || userForDeleting?.data?.fullName}`}
            data={userForDeleting}
            maxWidth={500}
            renderAdditionalActions={() => (
              <Checkbox
                checked={anonymizeUser}
                label={'Anonymize user'}
                onChange={() => setAnonymizeUser(!anonymizeUser)}
              />
            )}
            onSubmit={handleDeleteUser}
            onClose={closeDeleteUserModal}
          >
            <InfoBlock
              text={`
                If user does not want their personal data to be stored in the system, user can be anonymized. In this case their personal data such 
                as First/Last Name, Email, Phone, Travel document, etc. will be deleted from Trips, Reports, Expense Reports, Payments. All these data will 
                be non-personalized and displayed as anonymized user.
              `}
            />
            <Text size={16}>Do you want to delete this user?</Text>
          </Dialog>
        )}

        {isDeleteUnemployedUserModalOpen && (
          <Dialog
            title={`Delete user — ${userForDeleting?.data?.fullName}`}
            data={userForDeleting}
            maxWidth={500}
            onClose={closeDeleteUnemployedUserModal}
          >
            <InfoBlock
              text={`
                Users cannot be deleted due to having incomplete tasks or activities in the system.
                If you want to delete the user manually, please resolve all the reasons described below,
                and then the deletion will be available.
              `}
            />
            <ReasonsWrapper>
              <Reasons>
                {Object.entries(userForDeleting?.reasons).map((reason, i) => (
                  <li key={i}>
                    {formatPreventDeletionReason(reason as PreventDeletionReason)}{Array.isArray(reason[1]) && <p>{reason[1].join(', ')}</p>}
                  </li>
                ))}
              </Reasons>
            </ReasonsWrapper>
          </Dialog>
        )}

        {isSyncUserUpdatesModalOpen && (
          <Dialog
            submitButtonLabel="Confirm"
            isSubmitDisabled={isSyncUpdatesLoading}
            maxWidth={500}
            title="Sync user updates"
            onClose={handleSyncUpdatesModalClose}
            onSubmit={handleSyncUpdates}
          >
            <DialogContent>
              <InfoBlock
                text={`
                  Role, position, employer, team, email, manager, and employment type will be pulled
                  from the company's Hibob and set instead of the current user data. Also Jira ID will be pulled from company's Jira. 
                  First name, last name, mobile number, and passenger email will not be automatically
                  updated because booking providers have a lot of restrictions. And travelopses must set this data manually. 
                `}
              />
              <Text size={16}>
                Are you sure you want to sync updates for user <strong>#{userIdForSyncUpdates}</strong> ?
              </Text>
            </DialogContent>
          </Dialog>
        )}

        {isOpenOverview && !isLoadingUsersOptions &&(
          <div>
            <Drawer handleClose={closeUserOverview} title={overviewData?.fullName} tripId={overviewData?.id}>
              <UserDrawerContent
                businessVerticals={options.businessVerticals}
                availableForCryptoWalletCurrencies={options.availableForCryptoWalletCurrencies}
                isResendActivationLinkLoading={isResendActivationLinkLoading}
                travelDocumentTypes={options.travelDocumentTypes}
                companyEmployees={options.companyEmployees}
                employmentTypes={options.employmentTypes}
                currentUserData={currentUserData}
                countries={options.countries}
                employers={options.employers}
                managers={options.managers}
                genders={options.genders}
                titles={options.titles}
                teams={options.teams}
                roles={options.roles}
                data={overviewData}
                handleResendActivationLink={handleResendActivationLink}
                updateUserLoyaltyNumbers={updateUserLoyaltyNumbers}
                updateUserFavoriteHotels={updateUserFavoriteHotels}
                // @ts-ignore
                updateUserCryptoWallets={updateUserCryptoWallets}
                handleSyncUpdates={handleSyncUpdatesModalOpen}
                updatePassenger={updateOverviewPassengerDetails}
                updateDocument={updateOverviewDocumentDetails}
                updateDetails={updateDetails}
                inviteUser={handleInviteUser}
              />
            </Drawer>
          </div>
        )}

        {isUnemployedUsersListOpen && (
          <div>
            <Drawer
              handleClose={closeUnemployedUsersList}
              title={`Unemployed users • ${employeesForDeletion?.length}`}
            >
              <InfoBlock
                text={`
                  Users who were not automatically removed due to having incomplete tasks
                  or activities in the system are gathered in this list.
                  If you want to delete a user manually or automatically within the next synchronization
                  with Hibob - please fix all reasons described for each user personally and then perform delete.
                `}
              />
              <UnemployedUsersOverview 
                data={employeesForDeletion}
                onClick={handleDeleteUnemployedUserModals}
              />
            </Drawer>
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default Users;
