import { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { TextInput, Button, Checkbox } from '@heathmont/moon-core';
import FormField from '../FormField';
import { teamSchema } from './schema';
import { IAddNewTeamDialog } from './types';
import { FormFieldWrapper, CheckboxWrapper, ButtonsWrapper } from './styles';
import { onCompanyChangeHandler } from '../../pages/Tools/Teams/utils';
import CustomSelect from '../CustomSelect';
import Dialog from '../Dialog';
import { TeamTypes } from '../../enums/TeamTypes';
import { filterItemsWithVerticalsByVerticalObjectId } from '../../utils/filters';
import { filterBusinessVerticalsByType } from '../../utils/filters';

const AddNewTeamDialog: React.FC<IAddNewTeamDialog> = ({
  companies,
  handleCloseDialog,
  handleConfirm,
}) => {
  const formik = useFormik({
    validationSchema: teamSchema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: {
      tripPurposes: [],
      businessVertical: null,
      company: null,
      name: '',
      type: TeamTypes.INTERNAL,
    },
    onSubmit: handleConfirm,
  });
  const { touched, values, errors, setFieldValue, setFieldTouched, setValues } = formik;

  const [companyOptions, setCompanyOptions] = useState({
    tripPurposes: [],
    businessVerticals: [],
  });

  useEffect(() => {
    if (values?.type === TeamTypes.INTERNAL) {
      setValues({
        ...values,
        businessVertical: null,
        tripPurposes: [],
      });
    } else {
      setValues({
        ...values,
        businessVertical: null,
      });
    }
  }, [values?.type]);

  useEffect(() => {
    setValues({
      ...values,
    });
  }, [values?.businessVertical]);

  const [businessVerticalsByType, setBusinessVerticalsByType] = useState([]);
  const [businessVerticals, setBusinessVerticals] = useState([]);

  const assignBusinessVerticalsByType = (businessVerticals, type) => {
    return setBusinessVerticalsByType(filterBusinessVerticalsByType(businessVerticals, type));
  };


  useEffect(() => {
    assignBusinessVerticalsByType(businessVerticals, values?.type)
  }, [businessVerticals, values?.type]);

  useEffect(() => {
    setBusinessVerticals(companyOptions.businessVerticals);
  }, [companyOptions]);

  return (
    <Dialog
      title="Add team"
      withFooter={false}
      onClose={handleCloseDialog}
      width={500}
    >
      <FormikProvider value={formik}>
        <Form>
          <FormFieldWrapper>
            <FormField
              errorPosition={-20}
              fieldName="name"
              component={TextInput}
              inputSize="xlarge"
              fieldId="name"
              isError={touched?.name && errors?.name}
              errors={touched?.name && errors}
              value={values.name}
              label="Team name"
              type="text"
              onChange={(e) => setFieldValue('name', e.target.value)}
              onBlur={() => setFieldTouched('name')}
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <CustomSelect
              currentValue={values.company}
              placeholder="Company"
              isError={!!(touched?.company && errors?.company)}
              error={(touched?.company && errors?.company) as string}
              items={companies}
              onChange={(newCompanyId) => onCompanyChangeHandler({
                newCompanyId,
                companyId: values.company,
                setCompanyOptions,
                setFieldTouched,
                setFieldValue,
              })}
              onBlur={() => setFieldTouched('company', true, false)}                    
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={values?.type === TeamTypes.EXTERNAL}
                label={'For external users'}
                onChange={(event) => {
                  const type = event.target.checked ? TeamTypes.EXTERNAL : TeamTypes.INTERNAL;
                  setFieldValue('type', type);
                }}
              />
            </CheckboxWrapper>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <CustomSelect
              currentValue={values.businessVertical}
              isSearchable
              placeholder="Business Vertical"
              isDisabled={!values.company}
              isClearable
              items={businessVerticalsByType}
              onChange={value => {
                setFieldValue('businessVertical', value);
              }}
            />
          </FormFieldWrapper>

          {
            values?.type === TeamTypes.EXTERNAL && (
              <>
                <FormFieldWrapper>
                  <CustomSelect
                    currentValue={values.tripPurposes}
                    isSearchable
                    placeholder="Trip purposes"
                    isDisabled={!values.company}
                    isMulti
                    isError={!!(touched?.tripPurposes && errors?.tripPurposes)}
                    error={(touched?.tripPurposes && errors?.tripPurposes) as string}
                    items={companyOptions.tripPurposes}
                    onChange={value => {
                      setFieldValue('tripPurposes', value);
                      setFieldTouched('tripPurposes', true, false);
                    }}
                    onBlur={() => setFieldTouched('tripPurposes', true, false)}
                  />
                </FormFieldWrapper>
              </>
            )
          }

          <ButtonsWrapper>
            <Button variant="secondary" type="button" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={!isEmpty(errors)}>
              Add
            </Button>
          </ButtonsWrapper>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default AddNewTeamDialog;
