import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const TableWrapper = styled.div`
  position: relative;
  padding: 24px 32px;
  background-color: ${({ theme }) => theme.color.goku['100']};
  min-width: 400px;
  flex-grow: 1;
  overflow: auto;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  button {
    height: 40px;
  }
`;
