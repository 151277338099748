import React, { useEffect, useState } from 'react';
import { ControlsPlus, GenericDelete, GenericPlus } from '@heathmont/moon-icons';
import { Button } from '@heathmont/moon-core';
import DailyAllowanceCustomRuleForm from '../DailyAllowanceCustomRuleForm';
import DailyAllowanceCustomRuleContent from '../DailyAllowanceCustomRule';
import request from '../../models/request';

const MAX_RULES_NUMBER = 5;

function DailyAllowanceCustomRules({
  customRules,
  allowanceId,
}: {
  customRules: any;
  allowanceId: number;
}) {
  const [isAddNewOpen, setIsAddNewOpen] = useState(false);
  const [editedRuleId, setEditedRuleId] = useState(null);
  const [rulesOptions, setRulesOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await request.get('/tools/daily-allowance-types/custom-rules/options');
      setRulesOptions(data);
    })();
  }, []);

  const handleCloseAddNew = () => {
    setIsAddNewOpen(false);
  };
  const handleOpenAddNew = () => {
    setIsAddNewOpen(true);
    setEditedRuleId(null);
  };

  const handleEdit = (id) => {
    setEditedRuleId(id);
    if (id) {
      setIsAddNewOpen(false);
    }
  };

  const isAmountSmallerThanMax = customRules.length < MAX_RULES_NUMBER;
  return (
    <>
      {customRules.map((rule) => (
        <DailyAllowanceCustomRuleContent
          rule={rule}
          handleEdit={handleEdit}
          editedId={editedRuleId}
          rulesOptions={rulesOptions}
          allowanceId={allowanceId}
        />
      ))}
      {!isAddNewOpen && isAmountSmallerThanMax && (
        <Button iconLeft={<GenericPlus />} variant="ghost" type="button" onClick={handleOpenAddNew}>
          Add rule
        </Button>
      )}
      {isAddNewOpen && (
        <DailyAllowanceCustomRuleForm
          isAddNew
          allowanceId={allowanceId}
          rulesOptions={rulesOptions}
          handleCancel={handleCloseAddNew}
        />
      )}
    </>
  );
}

export default DailyAllowanceCustomRules;
