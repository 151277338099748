import { useMemo } from 'react';
import { filterItemsWithVerticalsByVerticalObjectId } from '../../../utils/filters';
import InfoBlock from '../../../components/InfoBlock';
import { IUserInfoBlocks } from './types';

const UserInfoBlocks: React.FC<IUserInfoBlocks> = ({
  isUserUnregistered,
  isUserExternal,
  data,
  inviteUser,
}) => {
  const doesUserAndTeamVerticalMatch = useMemo(() => data?.businessVertical?.id && data?.businessVertical?.id === data?.team?.businessVerticalId, [data]);
  
  const doesUserAndEmployerVerticalMatch = useMemo(() => {
    const [employer] = filterItemsWithVerticalsByVerticalObjectId([data?.employer],  data?.businessVertical?.id);
    return !!employer;
  }, [data]);

  const doesUserAndManagerVerticalMatch = useMemo(() => {
    if (!data?.manager?.id) {
      return true;
    }

    return data?.businessVertical?.id && data?.businessVertical?.id === data?.manager?.businessVerticalId;
  }, [data]);

  const doesUserAndAssistantsVerticalMatch = useMemo(() => {
    if (!data?.assistants?.length) {
      return true;
    }

    return data?.businessVertical?.id && data?.assistants.every(assistant => assistant?.businessVerticalId === data?.businessVertical?.id);
  }, [data]);

  const handleInviteUser = () => {
    inviteUser({
      businessVertical: data?.businessVertical?.id,
      firstName: data?.firstname,
      lastName: data?.lastname,
      employer: data?.employer?.id,
      external: false,
      email: data?.email,
      role: data?.role?.id,
      team: data?.team?.id,
    });
  };

  return (
    <>
      {isUserUnregistered && (
        <InfoBlock
          actionLabel="Invite"
          text={`  
            User was imported from the company's Hibob but not registered.
            You can invite ${data?.firstname} ${data?.lastname} to sign up to Travel.
          `}
          onHandleAction={handleInviteUser}
        />
      )}

      {isUserExternal && (
        <InfoBlock
          text={`  
            User was pulled up into system after successful registration in the Web application.
            External user does not have access to the BO or app in Slack.
          `}
        />
      )}

      {!doesUserAndTeamVerticalMatch && (
        <InfoBlock text={`Team's vertical doesn't match with user's vertical`} />
      )}

      {!doesUserAndEmployerVerticalMatch && (
        <InfoBlock text={`Employer's vertical doesn't match with user's vertical`} />
      )}

      {!doesUserAndManagerVerticalMatch && (
        <InfoBlock text={`Manager's vertical doesn't match with user's vertical`} />
      )}

      {!doesUserAndAssistantsVerticalMatch && (
        <InfoBlock text={`Assistant's vertical doesn't match with user's vertical`} />
      )}
    </>
  );
};

export default UserInfoBlocks;
