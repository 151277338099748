import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { TeamTypes } from '../../enums/TeamTypes';
import Yup from '../../utils/validation';

const validateMessages = {
  name: {
    min: 'Team name cannot be less than 2 characters',
    max: 'Team name cannot be longer than 40 characters',
    required: 'Team name cannot be empty',
  },
  company: {
    required: 'Company cannot be empty'
  },
  businessVertical:  {
    required: 'Business vertical cannot be empty',
  },
  tripPurposes: {
    required: 'At least one trip purpose should be provided',
  },
};

export const paymentTypeSchema = Yup
  .string()
  .oneOf(Object.values(PAYMENT_TYPE))
  .required();

export const teamSchema = Yup.object().shape({
  name: Yup
    .string()
    .required(validateMessages.name.required)
    .min(2, validateMessages.name.min)
    .max(40, validateMessages.name.max)
    .withoutSpaces(),
  company: Yup
    .number()
    .required(validateMessages.company.required)
    .nullable(),
  businessVertical: Yup
    .number()
    .required(validateMessages.businessVertical.required),
  type: Yup
    .string()
    .oneOf([TeamTypes.INTERNAL, TeamTypes.EXTERNAL])
    .required(),
  tripPurposes: Yup
    .array()
    .when('type', {
      is: value => value === TeamTypes.EXTERNAL,
      then: Yup
        .array()
        .min(1, validateMessages.tripPurposes.required)
        .of(Yup.number()),
      otherwise: Yup
        .array()
        .of(Yup.number()),
    }),
});
