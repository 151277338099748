import { ValidationError,  } from 'yup';
import { isEmpty, get } from 'lodash';

export const MAX_ITEMS_LENGTH = 5;

export const checkOnError = (errors: ValidationError, touched: any, fieldName: string, index: number) => {
  const fieldErrors = errors[fieldName] && errors[fieldName][index];

  if (fieldErrors && !isEmpty(fieldErrors)) {

    if (typeof fieldErrors === 'string') {
      return [fieldErrors];
    }

    return Object.keys(fieldErrors)
      .filter((key) => !!get(touched, [fieldName, index, key]))
      .map(key => fieldErrors[key]);
  }

  return [];
};
