import { Route, Switch, withRouter } from 'react-router-dom';

import IgnoreListAccommodations from '../../pages/Tools/IgnoreListAccommodations';
import ActionsAndNotifications from '../../pages/Tools/ActionsAndNotifications';
import DailyAllowanceTypes from '../../pages/Tools/DailyAllowanceTypes';
import BusinessVerticals from '../../pages/Tools/BusinessVerticals';
import RecommendedHotels from '../../pages/Tools/RecommendedHotels';
import EmploymentTypes from '../../pages/Tools/EmploymentTypes';
import Subscriptions from '../../pages/Tools/Subscriptions';
import TripPurposes from '../../pages/Tools/TripPurposes';
import CustomHotels from '../../pages/Tools/CustomHotels';
import Companies from '../../pages/Tools/Companies';
import Countries from '../../pages/Tools/Countries';
import Employers from '../../pages/Tools/Employers';
import Feedbacks from '../../pages/Tools/Feedbacks';
import Roles from '../../pages/Tools/Roles';
import Teams from '../../pages/Tools/Teams';

import { TOOLS_PAGES } from './suggestions';

const ToolsRoutes = ({ data }) => {
  const getToolsPageProps = (path: string) => {
    return {
      exact: true,
      path,
    };
  };

  return (
    <Switch>
      <Route
        {...getToolsPageProps(TOOLS_PAGES.COMPANIES)}
        render={(props) => <Companies {...data.companiesPage} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.EMPLOYERS)}
        render={(props) => <Employers {...data.employersPage} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.BUSINESS_VERTICALS)}
        render={(props) => <BusinessVerticals {...data.businessVerticals} {...props} />}
      />    
      <Route
        {...getToolsPageProps(TOOLS_PAGES.TEAMS)}
        render={(props) => <Teams {...data.teamsPage} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.ROLES)}
        render={(props) => <Roles {...data.rolesPage} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.PURPOSES)}
        render={(props) => <TripPurposes {...data.purposesPage} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.DAILY_ALLOWANCE_TYPES)}
        render={(props) => <DailyAllowanceTypes {...data.allowancesPage} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.SUBSCRIPTIONS)}
        render={(props) => <Subscriptions {...data.subscriptionsPage} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.ACTIONS_AND_NOTIFICATIONS)}
        render={(props) => <ActionsAndNotifications {...data.actionsAndNotifications} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.EMPLOYMENT_TYPES)}
        render={(props) => <EmploymentTypes {...data.employmentTypes} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.FEEDBACKS)}
        render={(props) => <Feedbacks {...data.feedbacks} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.COUNTRIES)}
        render={(props) => <Countries {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.RECOMMENDED_HOTELS)}
        render={(props) => <RecommendedHotels {...data.recommendedHotels} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.IGNORE_LIST_ACCOMMODATIONS)}
        render={(props) => <IgnoreListAccommodations {...data.ignoreListAccommodations} {...props} />}
      />
      <Route
        {...getToolsPageProps(TOOLS_PAGES.CUSTOM_HOTELS)}
        render={(props) => <CustomHotels {...data.customHotels} {...props} />}
      />
    </Switch>
  );
};

export default withRouter(ToolsRoutes);
