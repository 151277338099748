import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
      </span>
    ),
    SubCell: () => null,
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Company',
    accessor: 'company',
  },
  {
    Header: 'Business Vertical',
    accessor: 'businessVertical',
  },
  {
    Header: 'Currency',
    accessor: 'currency',
  },
  {
    Header: 'Number of Options',
    accessor: 'numberOfOptions',
  }
];