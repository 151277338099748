import { REIMBURSEMENT_ADDRESSES } from '../../../constants';
import { DailyAllowanceType } from '../../../enums/DailyAllowanceType';
import request from '../../../models/request';

export const USDT_CRYPTO_CURRENCY_TITLE = 'Tether USDt';

export const getReimbursementInfoInitialValues = (data) => ({
  reimburseTo: data?.trip?.expensePaymentInfo?.reimburseTo || null,
  reimburseEmployer: data?.trip?.expensePaymentInfo?.reimburseEmployer?.id ? {
    id: data.trip.expensePaymentInfo.reimburseEmployer.id,
    name: data.trip.expensePaymentInfo.reimburseEmployer.name,      
  } : null,
  cryptoWallet: data?.trip?.expensePaymentInfo?.cryptoWallet?.id ? {
    id: data.trip.expensePaymentInfo.cryptoWallet.id,
    address: data.trip.expensePaymentInfo.cryptoWallet.address, 
  } : null,
  cryptoCurrency: data?.trip?.expensePaymentInfo?.cryptoCurrency?.id ? {
    id: data.trip.expensePaymentInfo.cryptoCurrency.id,
    title: data.trip.expensePaymentInfo.cryptoCurrency.title, 
  } : null,
});

export const geBalancedIntegrationInitialValues = (data) => ({
  registrationNumber: data?.trip?.user?.employer?.registrationNumber || '',
  balancedId: data?.trip?.user?.balancedId || '',
});

export const getInitialCryptoCurrencyOptions = (paymentInfo, cryptoCurrencies) => {
  const options = [];

  if (paymentInfo?.reimburseTo === REIMBURSEMENT_ADDRESSES.BANK_ACCOUNT) {
    return options;
  }

  if (paymentInfo?.cryptoWallet?.currencyId) {
    const walletCryptoCurrency = cryptoCurrencies.find(({ id }) => id === paymentInfo?.cryptoWallet?.currencyId);
    options.push({ value: walletCryptoCurrency.id, title: walletCryptoCurrency.title });

    if (walletCryptoCurrency && walletCryptoCurrency.isSupportUSDt) {
      const USDtCryptoCurrency = cryptoCurrencies.find(({ title }) => title === USDT_CRYPTO_CURRENCY_TITLE);
      options.push({ value: USDtCryptoCurrency.id, title: USDtCryptoCurrency.title });
    }
  }

  return options;
};

export const handleFetchDailyAllowanceType = async ({
  tripId,
  setDailyAllowanceType, 
} : {
  tripId: number,
  setDailyAllowanceType: (dailyAllowanceType: DailyAllowanceType) => void,
}) => {
  try {
    const response = await request.get(`expenses/daily-allowance-type/${tripId}`);

    if (response?.data) {
      setDailyAllowanceType(response?.data);
    }
  } catch (err) {
    console.error(err);
  }
};
