import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import Helmet from 'react-helmet';
import { Text, Button } from '@heathmont/moon-core';
import { ControlsPlus, SoftwareSorting, TravelAirplane, TravelHotel } from '@heathmont/moon-icons';
import { ArrowsCrossLines, FilesExport } from '@heathmont/moon-icons-tw';
import Drawer from '../../components/Drawer';
import { usePageTitle } from '../../models/page';
import TableContentReciever from '../../components/TableContentReciever';
import { RowHeight } from '../Reports/suggestions';
import { DEFAULT_SORTING, LIMIT_PER_PAGE, TRIP_USER_TYPES } from '../../constants';
import TablePagination from '../../components/TablePagination';
import OverviewDrawerContent from '../../components/TripsOverviewDrawerContent';
import TicketDrawerContent from '../../components/TripsTicketDrawerContent';
import { TripStatusType } from '../../enums/TripStatusType';
import { getStatusLabel, tripsDataMapper, formatPurpose } from '../../utils/tableHelpers';
import Dialog from '../../components/Dialog';
import ChangeAccommodationPriceDialogContent from '../../components/ChangeAccommodationPriceDialogContent';
import { useQuery } from '../../hooks/useQuery';
import { useCustomTable } from '../../hooks/useCustomTable';
import { useFilters, initTripFilters } from '../../hooks/useFilters';
import { getTripFilters, tripsColumns } from './suggestions';
import DownDrawer from '../../components/DownDrawer';
import { prepareFilters } from '../../utils/filters';
import request from '../../models/request';
import { FiltersReciever } from '../../components/FiltersReciever';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import { ITrips } from './types';
import { TogglerWithCount } from '../../components/TogglerWithCount';
import {
  ConfirmDialogBodyWrapper,
  HiddenStatusBackground,
  TripsActionWrapper,
  ConfirmMessage,
  DialogContent,
  DrawerWrapper,
  TableWrapper,
  PageHeader,
  Container,
} from './styles';
import { FilterHeaderWrapper } from '../../styled';
import { deleteTripData, updateTripStatus } from '../../store/trips/api';
import { Spinner } from '../../components/Spinner';
import DashboardOverview from '../../components/DashboardOverview';
import { NOTIFICATION_TYPES } from '../../enums/NotificationTypes';
import { IPurposeWithDetails } from '../../components/TripsOverviewDrawerContent/types';
import InfoBlock from '../../components/InfoBlock';
import { UserRoleType } from '../../enums/UserRoleTypes';
import UnassignedFlightsDialog from '../../components/UnassignedFlightsDialog';
import { useDispatch, useSelector } from 'react-redux';
import { closeUnassignedFlights, openUnassignedFlights, closeUnassignedAccommodations, openUnassignedAccommodations} from '../../store/trips/actions';
import ExternalFlightForm from '../../components/AddExternalTripModalContent/forms/ExternalFlightForm';
import ExternalTripContentWrapper from '../../components/AddExternalTripModalContent/forms/ExternalTripContentWrapper';
import { IExternalAccommodation, IExternalFlight, IOtherTransport } from '../../components/AddExternalTripModalContent/types';
import UnassignedAccommodationDialog from '../../components/UnassignedAccommodationDialog';
import ExternalAccommodation from '../../components/AddExternalTripModalContent/forms/ExternalAccommodation';
import ChangeTripDestinationDialogContent from '../../components/ChangeTripDestinationDialogContent';
import OtherTransport from '../../components/AddExternalTripModalContent/forms/OtherTransportForm';
import {
  isManagerApprovalRequired,
  normalizeOtherTransport,
  normalizeAccommodation,
  userToPassengerOption,
  normalizeFlight,
  getUserTimeZone,
} from './utils';
import ChangeTripsStatusDialog from '../../components/ChangeTripsStatusDialog';
import EditTripCommentDialogContent from '../../components/EditTripCommentDialogContent';
import EditTripDeclineReasonDialogContent from '../../components/EditTripDeclineReasonDialogContent';
import { PaymentRequest } from '../../enums/PaymentRequest';
import RequestPaymentDialog from '../../components/RequestPaymentDialog';
import { Trip } from '../../enums/Trip';
import MergeTripsDialog from '../../components/MergeTripsDialog';
import { TripStatusUpdateFields } from '../../enums/TripStatusUpdateFields';

const Trips: React.FC<ITrips> = ({
  isPossibleTripsForMergingOpen,
  isDownDrawerStatusModal,
  isOverviewStatusModal,
  tableTripStatusModal,
  tripsForMergingData,
  checkboxesData,
  deletableTrips,
  isOpenOverview,
  isFiltersOpen,
  isDeleteModal,
  isLoadingData,
  overviewData,
  isOpenTicket,
  tripOptions,
  ticketData,
  tripsData,
  userRole,
  total,
  closePossibleTripsForMerging,
  setTableTripStatusModalData,
  openPossibleTripsForMerging,
  setDownDrawerStatusModal,
  setOverviewStatusModal,
  onChangePaymentStatus,
  onChangePaymentInvoice,
  closeDeleteTripModal,
  fetchTripsForMerging,
  deleteOtherTransport,
  openDeleteTripModal,
  deleteAccommodation,
  closeTripOverview,
  openTripOverview,
  closeTripTicket,
  setCheckboxData,
  fetchTripTicket,
  updateFeedback,
  openTripTicket,
  toggleFilters,
  fetchOptions,
  deleteFlight,
  closeFilters,
  fetchTrips,
}) => {
  const [isMergeTripsModalOpen, setIsMergeTripsModalOpen] = useState(false);
  const [isAssignUnassignedFlightModalOpen, setIsAssignUnassignedFlightModalOpen] = useState(false);
  const [isMergeTripsLoading, setIsMergeTripsLoading] = useState(false);
  
  const [isUpdateTripPurposeModalOpen, setIsUpdateTripPurposeModalOpen] = useState(false);
  const [tripForUpdateTripPurpose, setTripForUpdateTripPurpose] = useState(null);

  const [isUpdateAccommodationPriceModalOpen, setIsUpdateAccommodationPriceModalOpen] = useState(false);
  const [accommodationForUpdatePrice, setAccommodationForUpdatePrice] = useState(null);
  const [isAccommodationUpdatePriceLoading, setIsAccommodationUpdatePriceLoading] = useState(false);

  const [isUpdateTripStatusModalOpen, setIsUpdateTripStatusModalOpen] = useState(false);
  const [tripsForUpdateTripStatus, setTripsForUpdateTripStatus] = useState<TripStatusUpdateFields[]>([]);
  const [newStatusForTrip, setNewStatusForTrip] = useState(null);
  const [newStatusForTripUpdateLoading, setNewStatusForTripUpdateLoading] = useState<boolean>(false);

  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [openDatePickerField, setOpenDatePickerField] = useState(null);

  const [sortBy, setSortBy] = useState(DEFAULT_SORTING.TRIPS);

  const [hoveredRow, setHoveredRow] = useState(null);
  const [purposeWithDetails, setPurposeWithDetails] = useState<IPurposeWithDetails | null>(null);

  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [isRefresh, setIsRefetch] = useState(false);

  const { getQuery, setQuery } = useQuery();
  const { filters, filtersCount, toggleFilter, setFilter } = useFilters(getQuery(), initTripFilters);

  const [isPossibleTripsForMergingActive, setIsPossibleTripsForMergingActive] = useState(false);
  const [mergeTripIds, setMergeTripIds] = useState([]);

  const [isSyncTripUpdatesModalOpen, setIsSyncTripUpdatesModalOpen] = useState(false);
  const [tripIdForSyncUpdates, setTripIdForSyncUpdates] = useState(null);
  const [isSyncUpdatesLoading, setIsSyncUpdatesLoading] = useState(false);

  const [isSendToVerificationModalOpen, setIsSendToVerificationModalOpen] = useState(false);
  const [tripIdForSendingToVerification, setTripIdForSendingToVerification] = useState(null);

  const isDrawerWithUnassignedFlightsOpen = useSelector((state: any) => state.tripsReducer.isUnassignedFlightsOpen);
  const [unassignedFlights, setUnassignedFlights] = useState([]); 
  const [isAssignmentLoading, setIsAssignmentLoading] = useState(false); 
  const [selectedFlight, setSelectedFlight] = useState(null); 

  const [isAddExternalTripModalOpen, setIsAddExternalTripModalOpen] = useState(false);
  const [isAttachExternalAccommodationOpen, setIsAttachExternalAccommodationOpen] = useState(false);
  const [isAttachExternalFlightOpen, setIsAttachExternalFlightOpen] = useState(false);
  
  const [isAssignUnassignedAccommodationsModalOpen, setIsAssignUnassignedAccommodationsModalOpen] = useState(false);
  const [isAssignUnassignedAccommodationsIgnoreConfirmModalOpen, setIsAssignUnassignedAccommodationsIgnoreConfirmModalOpen] = useState(false);
  const isDrawerWithUnassignedAccommodationsOpen = useSelector((state: any) => state.tripsReducer.isUnassignedAccommodationsOpen);
  const [unassignedAccommodations, setUnassignedAccommodations] = useState([]); 
  const [selectedAccommodation, setSelectedAccommodation] = useState(null); 
  const [isExternalTripPartLoading, setIsExternalTripPartLoading] = useState(false);
  
  const [isTripChangeDestinationModalOpen, setIsTripChangeDestinationModalOpen] = useState(false);
  const [tripForChangeDestination, setTripForChangeDestination] = useState(null);
  const [isTripChangeDestinationLoading, setIsTripChangeDestinationLoading] = useState(false);

  const [isTripEditCommentModalOpen, setIsTripEditCommentModalOpen] = useState<boolean>(false);
  const [tripForEditComment, setTripForEditComment] = useState(null);
  const [isTripEditCommentLoading, setIsTripEditCommentLoading] = useState<boolean>(false);

  const [isEditDeclineReasonModalOpen, setIsEditDeclineReasonModalOpen] = useState<boolean>(false);
  const [tripForEditDeclineReason, setTripForEditDeclineReason] = useState(null);
  const [isTripEditDeclineReasonLoading, setIsTripEditDeclineReasonLoading] = useState<boolean>(false);

  const [isAttachOtherTransportOpen, setIsAttachOtherTransportOpen] = useState(false);
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] = useState(false);
  const [paymentForInvoiceCreation, setPaymentForInvoiceCreation] = useState(null);
  const [isInvoiceCreationLoading, setIsInvoiceCreationLoading] = useState(false);

  const [isMultiTripsChangeStatusLoading, setIsMultiTripsChangeStatusLoading] = useState<boolean>(false);

  const [isExportStatisticsLoading, setIsExportStatisticsLoading] = useState<boolean>(false);

  const [isRequestPaymentModalOpen, setIsRequestPaymentModalOpen] = useState<boolean>(false);
  const [isRequestPaymentLoading, setIsRequestPaymentLoading] = useState<boolean>(false);
  const [tripForRequestPayment, setTripForRequestPayment] = useState<Trip | null>(null);

  const [isReSendPaymentRequestModalOpen, setIsReSendPaymentRequestModalOpen] = useState<boolean>(false);
  const [isReSendPaymentRequestLoading, setIsReSendPaymentRequestLoading] = useState<boolean>(false);
  const [tripIdForReSendPaymentRequest, setTripIdForReSendPaymentRequest] = useState<string | null>(null);
  const [paymentRequestIdForReSendPaymentRequest, setPaymentRequestIdForReSendPaymentRequest] = useState<string | null>(null);

  const dispatch = useDispatch();

  const toggleFiltersAndToFirstPage = (field, value) => {
    setPage(1);
    toggleFilter(field, value);
  };

  const setFiltersAndToFirstPage = (field: string, value: any) => {
    setPage(1);
    setFilter(field, value);
  };

  const columns = useMemo(() => tripsColumns, []);
  const purposes = useMemo(() => tripOptions.purposes, [tripOptions]);
  const statuses = useMemo(() => tripOptions.statuses, [tripOptions]);

  const queryParams = useRef('');
  const totalItems = useRef(0);
  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);
  const { page, perPage, nextPage, prevPage, setPage } = useCustomTable(totalItems, LIMIT_PER_PAGE.REPORTS);
  const pageTitle = usePageTitle('html.trips.title');

  const handleRefetchData = () => setIsRefetch((prevState: boolean) => !prevState);
  
  const handleFetchUnassignedFlights = async () => {
    try {
      const response = await request.get('trips/unassigned-flights');
      setUnassignedFlights(response?.data?.unassignedFlights || []);
    } catch(e) {
      console.error(e)
    }
  }
  const handleFetchUnassignedAccommodations = async () => {
    try {
      const response = await request.get('trips/unassigned-accommodations');
      setUnassignedAccommodations(response?.data?.unassignedAccommodations || []);
    } catch (err) {
      console.error(err);
    }
  }

  const handleCloseStatusModal = () => {
    setTableTripStatusModalData(false);
    setOverviewStatusModal(false);
    setDownDrawerStatusModal(false);
  };

  const handleChangeStatusForMultiTrips = async (newStatus: TripStatusType, declineReason) => {    
    await updateTripStatus({
      declineReason,
      newStatus,
      trips: checkboxesData,
      setIsLoading: setIsMultiTripsChangeStatusLoading,
    });
    setCheckboxData([], true);
    setTableTripStatusModalData(false, null);
    setOverviewStatusModal(false);
    setDownDrawerStatusModal(false);
    handleRefetchData();
  };

  const handleDeleteTrips = async (tripIds) => {
    await deleteTripData(tripIds);
    setCheckboxData([], true);
    setTableTripStatusModalData(false, null);
    setOverviewStatusModal(false);
    setDownDrawerStatusModal(false);
    closeDeleteTripModal();
    handleRefetchData();
  };

  const handleFetchTicketDetails = async (tripId, callback) => {
    try {
      await fetchTripTicket(tripId, callback);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    }
  };

  const handleIsOverviewOpen = useCallback((row?: any) => {
    const tripId = tripsData[row?.id]?.id;

    if (tripId !== undefined) {
      handleFetchTicketDetails(tripId, openTripOverview);
    } else {
      openTripOverview(tripsData[row?.id])
    }
  }, [tripsData]);

  const handleIsTicketOpen = useCallback((row?: any) => {
    const tripId = tripsData[row?.id]?.id;

    if (tripId !== undefined) {
      handleFetchTicketDetails(tripId, openTripTicket);
    } else {
      openTripTicket(tripsData[row?.id]);
    }
  }, [tripsData]);

  const handleDatePicketOpen = (field) => {
    return (isOpen) => {
      setOpenDatePickerField(field);
      setIsOpenDatePicker(isOpen);
    };
  };

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const setQueryParams = () => {
    const params: any = {
      filters: prepareFilters(filters),
      perPage,
      page,
    };

    if (sortBy && sortBy.length) {
      params.sort = {
        field: sortBy[0].id,
        order: sortBy[0].desc ? 'desc' : 'asc',
      };
    }

    setQuery(params.filters);

    return params;
  };

  useEffect(() => {
    const params = setQueryParams();
    fetchTrips(params);
    closeTripOverview();
    closeTripTicket();
    closePossibleTripsForMerging();
  }, [filters, page, sortBy, isRefresh]);

  useEffect(() => {
    fetchOptions();
    fetchTripsForMerging();
    handleFetchUnassignedFlights();
    handleFetchUnassignedAccommodations();
    return () => {
      closeFilters();
      closeTripOverview();
      closeTripTicket();
    };
  }, []);

  const currentData = useMemo(() => tripsDataMapper(tripsData), [tripsData, isRefresh, sortBy, page, filters]);

  const handleExportPdf = useCallback(async (tripId) => {
    try {
      const response = await request.get(`/trips/ticket/${tripId}`);
      const { data: { ticket } } = response;
      saveAs(ticket.url, `${ticket.name}.pdf`);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    }
  }, []);

  useMemo(() => {
    totalItems.current = total;
  }, [total]);

  const filterComponents = useMemo(
    () =>
      getTripFilters({
        openDatePickerField,
        isOpenDatePicker,
        userRole,
        filters,
        options: tripOptions,
        toggleFiltersAndToFirstPage,
        handleDatePicketOpen,
        setFilter: setFiltersAndToFirstPage,
      }),
    [
      openDatePickerField,
      isOpenDatePicker,
      tripOptions,
      userRole,
      filters,
      toggleFiltersAndToFirstPage,
      setFiltersAndToFirstPage,
      handleDatePicketOpen,
    ]
  );

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const handleMergeTripsModalOpen = (params: any, isItPossibleTripsForMerging: boolean = false) => {
    setIsPossibleTripsForMergingActive(isItPossibleTripsForMerging);
    setMergeTripIds(params);
    setIsMergeTripsModalOpen(true);
  };
  
  const handleMergeTripsModalClose = () => {
    setIsMergeTripsModalOpen(false);
  };

  const handleOpenUnassignedFlightModal = (item) => {
    setIsAssignUnassignedFlightModalOpen(true)
    setSelectedFlight(item)
  }
  
  const handleUnassignedFlightsModalClose = () => {
    setIsAssignUnassignedFlightModalOpen(false)
  };

  const handleOpenUnassignedAccommodationModal = (item) => {
    setIsAssignUnassignedAccommodationsModalOpen(true)
    setSelectedAccommodation(item)
  }
  const handleUnassignedAccommodationModalClose = () => {
    setIsAssignUnassignedAccommodationsModalOpen(false)
    setSelectedAccommodation(null)
  };
  const handleMergeTrips = async (baseTripId, ignoreValidationForMerge) => {
    try {
      const ids = isPossibleTripsForMergingActive ? mergeTripIds : checkboxesData.map((data) => data.tripId);
      setIsMergeTripsLoading(true);

      await request.post('trips/merge-trips', { ids, baseTripId, ignoreValidation: ignoreValidationForMerge });

      getErrorStatusMessage({
        status: 200,
        message: 'Trips successfully merged.',
      });

      setCheckboxData([], true);
      setIsRefetch(!isRefresh);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error,
      });
    } finally {
      setIsMergeTripsLoading(false);
      setIsPossibleTripsForMergingActive(false);
      handleMergeTripsModalClose();
    }
  };

  const handleFinalizeAccommodationAssignment = () => {
    setIsAssignUnassignedAccommodationsModalOpen(false);
    setIsAssignUnassignedAccommodationsIgnoreConfirmModalOpen(false);
    handleFetchUnassignedAccommodations();
    handleIsUnassignedAccommodationsClose();
    handleFinalizeAssignment()
  }

  const handleFinalizeFlightAssignment = () => {
    setIsAssignUnassignedFlightModalOpen(false);
    handleFetchUnassignedFlights();
    handleIsUnassignedFlightsClose()
    handleFinalizeAssignment()
  }

  const handleFinalizeAssignment = () => {
    setIsAssignmentLoading(false);
    handleRefetchData();
  }

  const handleSubmitUnassignedFlights = async (values) => {
    try {
      setIsAssignmentLoading(true);

      await request.post('trips/unassigned-flights', {
        unassignedFlightId: values.id,
        userId: values.user,
      });

      getErrorStatusMessage({
        status: 200,
        message: 'Flight is assigned successfully.',
      });
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error,
      });
    } finally {
      handleFinalizeFlightAssignment();
    }
  }

  const handleSubmitUnassignedAccommodations = async (values) => {
    try {
      setIsAssignmentLoading(true);

      await request.post('trips/unassigned-accommodations', {
        unassignedAccommodationId: values.id,
        userId: values.user,
      });

      getErrorStatusMessage({
        status: 200,
        message: 'Accommodation is assigned successfully.',
      });
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error,
      });
    } finally {
      handleFinalizeAccommodationAssignment();
    }
  };

  const handleSyncUpdatesModalOpen = (tripId: number) => {
    setTripIdForSyncUpdates(tripId);
    setIsSyncTripUpdatesModalOpen(true);
  };

  const handleSyncUpdatesModalClose = () => {
    setTripIdForSyncUpdates(null);
    setIsSyncTripUpdatesModalOpen(false);
  };

  const handleCloseAddTripModal = () => {
    setIsAddExternalTripModalOpen(false)
  }

  const handleSyncUpdates = async () => {
    try {
      setIsSyncUpdatesLoading(true);

      const response = await request.post(`trips/sync-updates/${tripIdForSyncUpdates}`);
      const { declinedFlights = [], flightChanges = [], declinedAccommodations = [], accommodationChanges = [] } = response?.data;

      if (!declinedFlights?.length && !flightChanges?.length && !declinedAccommodations?.length && !accommodationChanges?.length) {
        getErrorStatusMessage({
          status: 200,
          message: 'No updates.',
        });
      } else {
        const notes = [];

        if (declinedFlights?.length) {
          notes.push('Some flight bookings were cancelled.');
        }

        if (declinedAccommodations?.length) {
          notes.push('Some accommodation bookings were cancelled..');
        }

        if (flightChanges?.length) {
          notes.push('Data for some flights were changed.');
        }

        if (accommodationChanges?.length) {
          notes.push('Data for some accommodation were changed.');
        }

        getErrorStatusMessage({
          status: 200,
          message: `${notes.join(' ')} Please check the trip booking for details.`,
        });
      }

      setIsRefetch(!isRefresh);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error,
      });
    } finally {
      setIsSyncUpdatesLoading(false);
      handleSyncUpdatesModalClose();
    }
  };

  const openUpdateAccommodationPriceModal = (accommodation: any) => {
    setIsUpdateAccommodationPriceModalOpen(true);
    setAccommodationForUpdatePrice(accommodation);
  };

  const closeUpdateAccommodationPriceModal = () => {
    setIsUpdateAccommodationPriceModalOpen(false);
    setAccommodationForUpdatePrice(null);
  };

  const handleUpdateAccommodationPrice = async ({ accommodationId, tripId, price }) => {
    setIsAccommodationUpdatePriceLoading(true);
    try {
      await request.update(`trips/change-accommodation-price/${tripId}/${accommodationId}`, { price });
      getErrorStatusMessage({
        status: 200,
        message: `Accommodation price changed for accommodation ID - ${accommodationId}.`,
      });
      setIsRefetch(!isRefresh)
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || `Accommodation price can't be changed for accommodation ID - ${accommodationId}.`,
      });
    } finally {
      setIsAccommodationUpdatePriceLoading(false);
      closeUpdateAccommodationPriceModal();
    } 
  };

  const getTripPurposeLabel = (value) => {
    const purpose = purposes.find((purpose) => purpose.value === value);
    return formatPurpose(purpose);
  };

  const handleUpdateTripPurpose = (trip, newPurposeWithDetails: IPurposeWithDetails) => {
    if(newPurposeWithDetails.purposeId !== overviewData?.purpose?.id){
      setTripForUpdateTripPurpose(trip);
      setPurposeWithDetails(newPurposeWithDetails);
      setIsUpdateTripPurposeModalOpen(true);
    } else {
      handleChangeTripPurposeWithDetails(trip.id, newPurposeWithDetails)
    }
  };

  const handleUpdateTripPurposeClose = () => {
    setTripForUpdateTripPurpose(null);
    setIsUpdateTripPurposeModalOpen(false);
  };
  
  const handleOpenAttachAccommodationModal = () => {
    setIsAttachExternalAccommodationOpen(true);
  }

  const handleOpenAttachFlightModal = () => {
    setIsAttachExternalFlightOpen(true);
  }

  const handleAttachExternalFlight = async(flightData: IExternalFlight, tripId) => {
    try {
      setIsExternalTripPartLoading(true);

      await request.post(`trips/attach-external-flight/${tripId}`, {
        flight: normalizeFlight(flightData, { isExternal: true }),
      });

      getErrorStatusMessage({
        message: 'Flight attached successfully',
        status: 200,
      });
    } catch (err) {
      getErrorStatusMessage({
        message: err?.response?.data?.error || `Flight couldn't be attached.`,
        status: err?.response?.status,
      });
    } finally {
      setIsAttachExternalFlightOpen(false);
      setIsExternalTripPartLoading(false);
      setIsRefetch(!isRefresh);
    }
  };

  const handleAttachExternalAccommodation = async(accommodationData: IExternalAccommodation, tripId) => {
    try {
      setIsExternalTripPartLoading(true);
      const accommodation = normalizeAccommodation(accommodationData)
      await request.post(`trips/attach-external-accommodation/${tripId}`, {accommodation});
      getErrorStatusMessage({
        status: 200,
        message: `Accommodation attached successfuly.`,
      });
      setIsRefetch(!isRefresh)
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || `Flight couldn't be attached.`,
      });
    } finally {
      setIsAttachExternalAccommodationOpen(false);
      setIsExternalTripPartLoading(false);
    }
  };

  const handleCreateExternalTrip = async(data) => {
    try {
      setIsAssignmentLoading(true);

      const trip = {
        managerVerificationRequired: data.isManagerVerificationRequired,
        otherTransports: data.otherTransports.map((otherTransport) => normalizeOtherTransport(otherTransport)),
        accommodations: data.accommodations.map((accommodation) => normalizeAccommodation(accommodation)),
        purposeDetails: data.purposeDetails,
        traveler: data.traveler,
        flights: data.flights.map((flightData) => normalizeFlight(flightData, { isExternal: true })),
        purpose: data.purpose,
      };

      await request.post('trips/external-trips', trip);
  
      getErrorStatusMessage({
        status: 200,
        message: 'External trip created successfully',
      });
      setIsRefetch(!isRefresh);
      setIsAddExternalTripModalOpen(false);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || `Flight couldn't be attached.`,
      });
    } finally {
      setIsAssignmentLoading(false);
    }
  };

  const handleUpdateFlight = async (flightData, isExternal, flightId, tripId) => {
    try {
      setIsExternalTripPartLoading(true);

      await request.update(`trips/${tripId}/flights/${flightId}`, {
        flight: normalizeFlight(flightData, { isExternal }),
      });

      getErrorStatusMessage({
        status: 200,
        message: 'Flight updated successfully',
      });
      setIsRefetch(!isRefresh)
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || `Flight couldn't be updated.`,
      });
    } finally {
      setIsExternalTripPartLoading(false);
    }
  };

  const handleUpdateExternalAccommodation = async(accommodationData, accommodationId, tripId) => {
    try {
      setIsExternalTripPartLoading(true);
      const accommodation = normalizeAccommodation(accommodationData)
      await request.update(`trips/${tripId}/accommodations/${accommodationId}`, {accommodation});
      getErrorStatusMessage({
        status: 200,
        message: `Accommodation updated successfully.`,
      });
      setIsRefetch(!isRefresh)
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || `Accommodation couldn't be updated.`,
      });
    } finally {
      setIsExternalTripPartLoading(false);
    }
  };

  const handleChangeTripPurposeWithDetails = async (tripId, newPurposeWithDetails) => {
    const { purposeId, purposeDetails } = newPurposeWithDetails; 
    try {
      await request.post(`trips/purpose/${tripId}`, { purpose: purposeId, purposeDetails });
      getErrorStatusMessage({
        status: 200,
        message: `Trip purpose changed for ID - ${tripId}.`,
      });
      setIsRefetch(!isRefresh)
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || `Trip purpose can't be changed for ID - ${tripId}.`,
      });
    } finally {
      setIsUpdateTripPurposeModalOpen(false);
    } 
  };
  
  const requestToSubmitExpensesReport = async (tripId) => {
    try {
      await request.post(`/trips/expenses/${tripId}`);
      getErrorStatusMessage({
        status: 200,
        message: 'The expenses successfully requested!',
      });
      setIsRefetch(!isRefresh);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    }
  };

  const requestFeedback = async (tripId) => {
    try {
      await request.post(`/trips/feedback/${tripId}`);
      getErrorStatusMessage({
        status: 200,
        message: 'The feedback successfully requested!',
      });
      setIsRefetch(!isRefresh);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    }
  };

  const createTTR = async (tripId) => {
    try {
      await request.post(`/trips/ttr/${tripId}`);
      getErrorStatusMessage({
        status: 200,
        message: 'TTR successfully created!',
      });
      setIsRefetch(!isRefresh);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    }
  };

  const handleExportStatistics = async () => {
    try {
      setIsExportStatisticsLoading(true);
      const params = setQueryParams();

      const { data } = await request.get(`/trips/statistics/download`, {
        responseType: 'arraybuffer',
        params: {
          timeZone: getUserTimeZone(),
          filters: params?.filters,
        },
      });
    
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      saveAs(blob, `[${moment().format('DD.MM.YYYY')}]statistics.xlsx`);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    } finally {
      setIsExportStatisticsLoading(false);
    }
  };

  const handleUpdateTripStatusConfirmationModalOpen = (trip: TripStatusUpdateFields, newStatus: TripStatusType) => {
    setTripsForUpdateTripStatus([trip]);
    setNewStatusForTrip(newStatus);
    setIsUpdateTripStatusModalOpen(true);
  };

  const handleUpdateTripStatusClose = () => {
    setTripsForUpdateTripStatus([]);
    setNewStatusForTrip(null);
    setIsUpdateTripStatusModalOpen(false);
  };

  const handleChangeTripStatus = async (newStatus: TripStatusType, declineReason: string | null) => {
    await updateTripStatus({
      declineReason,
      newStatus,
      trips: tripsForUpdateTripStatus,
      setIsLoading: setNewStatusForTripUpdateLoading,
    });
    setIsRefetch(!isRefresh);
    handleUpdateTripStatusClose();
  };

  const handleIsPossibleTripsForMergingListOpen = () => {
    if (isPossibleTripsForMergingOpen) {
      closePossibleTripsForMerging();
    } else {
      openPossibleTripsForMerging();
    }
  };
  const handleIsUnassignedFlightsOpen = () => {
    dispatch(isDrawerWithUnassignedFlightsOpen ? closeUnassignedFlights() : openUnassignedFlights())
  };
  const handleIsUnassignedFlightsClose = () => {
    dispatch(closeUnassignedFlights())

  };
  const handleIsUnassignedAccommodationsOpen = () => {
    dispatch(isDrawerWithUnassignedAccommodationsOpen ? closeUnassignedAccommodations() : openUnassignedAccommodations())
  };
  const handleIsUnassignedAccommodationsClose = () => {
    dispatch(closeUnassignedAccommodations())
  };

  const handleChangeDestinationModalOpen = (trip: number) => {
    setTripForChangeDestination(trip);
    setIsTripChangeDestinationModalOpen(true);
  };

  const handleChangeDestinationModalClose = () => {
    setTripForChangeDestination(null);
    setIsTripChangeDestinationModalOpen(false);
  };

  const handleChangeDestination = async (data) => {
    try {
      setIsTripChangeDestinationLoading(true);
  
      await request.update(`/trips/change-destination/${data.tripId}`, {
        destinations: data.newDestination,
      });
      getErrorStatusMessage({
        status: 200,
        message: 'The destination successfully changed!',
      });
      setIsRefetch(!isRefresh);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    } finally {
      setIsTripChangeDestinationLoading(false);
      handleChangeDestinationModalClose();
    }
  };

  const handleEditCommentModalOpen = (trip: number) => {
    setTripForEditComment(trip);
    setIsTripEditCommentModalOpen(true);
  };

  const handleEditCommentModalClose = () => {
    setTripForEditComment(null);
    setIsTripEditCommentModalOpen(false);
  };

  const handleEditComment = async (data) => {
    try {
      setIsTripEditCommentLoading(true);
  
      await request.update(`/trips/comment/${data?.tripId}`, {
        notifyTravelerAndManager: data?.notifyTravelerAndManager,
        comment: data?.comment,
      });
    
      getErrorStatusMessage({
        status: 200,
        message: 'The comment successfully changed!',
      });
      setIsRefetch(!isRefresh);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    } finally {
      setIsTripEditCommentLoading(false);
      handleEditCommentModalClose();
    }
  };


  const handleEditDeclineReasonModalOpen = (trip: number) => {
    setTripForEditDeclineReason(trip);
    setIsEditDeclineReasonModalOpen(true);
  };

  const handleEditDeclineReasonModalClose = () => {
    setTripForEditDeclineReason(null);
    setIsEditDeclineReasonModalOpen(false);
  };

  const handleEditDeclineReason = async (data) => {
    try {
      setIsTripEditDeclineReasonLoading(true);
  
      await request.update(`/trips/decline-reason/${data?.tripId}`, {
        declineReason: data?.declineReason,
      });
    
      getErrorStatusMessage({
        status: 200,
        message: 'The decline reason successfully changed!',
      });
      setIsRefetch(!isRefresh);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    } finally {
      setIsTripEditDeclineReasonLoading(false);
      handleEditDeclineReasonModalClose();
    }
  };

  const deletableData = useMemo(
    () => (deletableTrips.length ? deletableTrips : checkboxesData),
    [deletableTrips, checkboxesData]
  );

  const checkIsRowDisabled = useCallback((trip) => {
    if (!checkboxesData.length){
      return false;
    }

    const selectedTripIsManagerApprovalRequired= isManagerApprovalRequired(checkboxesData[0]);
    const rowTripIsManagerApprovalRequired = isManagerApprovalRequired(trip);

    return selectedTripIsManagerApprovalRequired !== rowTripIsManagerApprovalRequired;
  }, [checkboxesData]);

  const tableContentRecieverProps = {
    tableTripStatusModal,
    isShowTableFilters,
    withCheckboxRow: true,
    checkboxesData: checkboxesData,
    initialColumns: columns,
    limitPerPage: LIMIT_PER_PAGE.TRIPS,
    queryParams: queryParams.current,
    selectable: true,
    hoveredRow,
    withHover: true,
    rowHeight,
    statuses,
    sortBy,
    data: currentData,
    page,
    handleIsOverviewOpen,
    handleRemoveDialog: openDeleteTripModal,
    checkIsRowDisabled,
    handleIsTicketOpen,
    handleSetCheckbox: setCheckboxData,
    handleHoveredRow,
    getStatusLabel,
    handleSortBy: setSortBy,
  };

  const getTextForPurposeChange = () => {
    const foundPurpose = purposes.find((purpose) => purpose.value === purposeWithDetails.purposeId);
    if(foundPurpose) {
      const isExpenseReportRequired = foundPurpose.expensesRequired === 1
      return `Expense report submission is ${
        isExpenseReportRequired ? 'available' : 'not available'
      } for chosen trip purpose.`;
    }
  }

  const isCurrentUserTravelops = userRole === UserRoleType.Travelops;

  const handleSendTripToVerification = async (tripId) => {
    try {
      await request.post(`trips/send-to-verification/${tripId}`);
      getErrorStatusMessage({
        status: 200,
        message: "The trip has been sent for the manager's verification.",
      });
      setIsRefetch(!isRefresh);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || 'Something went wrong. Try again later',
      });
    } finally {
      setIsSendToVerificationModalOpen(false);
    }
  };

  const handleSendTripToVerificationModalOpen = (tripId: number) => {
    setTripIdForSendingToVerification(tripId);
    setIsSendToVerificationModalOpen(true);
  };

  const renderIgnoreUnassignedAccommodationAction = () => (
    <Button
      animation="error"
      onClick={(values) => {
        setIsAssignUnassignedAccommodationsModalOpen(false);
        setIsAssignUnassignedAccommodationsIgnoreConfirmModalOpen(true);
      }}
    >Ignore</Button>
  );

  const handleSubmitIgnoreUnassignedAccommodationAction = async () => {
    try {
      setIsAssignmentLoading(true);

      await request.update(`trips/ignore-unassigned-accommodations/${selectedAccommodation.id}`);

      getErrorStatusMessage({
        status: 200,
        message: 'Unassigned accommodation has been successfully ignored.',
      });
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error,
      });
    } finally {
      handleFinalizeAccommodationAssignment();
    }
  };

  const handleOpenAttachOtherTransportModal = () => {
    setIsAttachOtherTransportOpen(true);
  };

  const handleAttachOtherTransport = async(data: IOtherTransport, tripId) => {
    try {
      setIsExternalTripPartLoading(true);
      const otherTransport = normalizeOtherTransport(data);
      await request.post(`trips/attach-other-transport/${tripId}`, {otherTransport});
      getErrorStatusMessage({
        status: 200,
        message: `Other transport attached successfully.`,
      });
      setIsRefetch(!isRefresh);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || `Other transport couldn't be attached.`,
      });
    } finally {
      setIsAttachOtherTransportOpen(false);
      setIsExternalTripPartLoading(false);
    }
  }

  const handleUpdateOtherTransport = async(data, otherTransportId, tripId) => {
    try {
      setIsExternalTripPartLoading(true);
      const otherTransport = normalizeOtherTransport(data);
      await request.update(`trips/${tripId}/other-transports/${otherTransportId}`, { otherTransport });
      getErrorStatusMessage({
        status: 200,
        message: `Other transport updated successfully.`,
      });
      setIsRefetch(!isRefresh)
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message: err?.response?.data?.error || `Other transport couldn't be updated.`,
      });
    } finally {
      setIsExternalTripPartLoading(false);
    }
  }

  const handleCreateInvoiceModalOpen = (data) => {
    setPaymentForInvoiceCreation(data);
    setIsCreateInvoiceModalOpen(true);
  };

  const handleInvoiceCreation = async () => {
    try {
      setIsInvoiceCreationLoading(true);
      const {tripId, paymentId} = paymentForInvoiceCreation;
      const { data } = await request.post(`/trips/${tripId}/payments/${paymentId}/invoice`);

      getErrorStatusMessage({
        status: 200,
        message: 'The payment invoice created successfully.',
      });
      onChangePaymentInvoice(paymentId, data.balancedIoInvoiceNumber);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later',
      });
    } finally {
      setIsInvoiceCreationLoading(false);
      setPaymentForInvoiceCreation(null);
      setIsCreateInvoiceModalOpen(false);
    }
  };

  const handleOpenRequestPaymentModal = (trip: Trip) => {
    setIsRequestPaymentModalOpen(true);
    setTripForRequestPayment(trip);
  };

  const handleCloseRequestPaymentModal = () => {
    setIsRequestPaymentModalOpen(false);
    setTripForRequestPayment(null);
  };

  const handleRequestPayment = async (paymentRequest: PaymentRequest) => {
    try {
      setIsRequestPaymentLoading(true);
      await request.post(`trips/payments/request/${paymentRequest.tripId}`, paymentRequest);
      handleCloseRequestPaymentModal();
      setIsRefetch(!isRefresh);
      getErrorStatusMessage({
        status: 200,
        message: 'Payment request was sent successfully.',
      });
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });
    } finally {
      setIsRequestPaymentLoading(false);
    }
  };

  const handleOpenReSendPaymentRequestModal = (tripId, paymentRequestId) => {
    setIsReSendPaymentRequestModalOpen(true);
    setTripIdForReSendPaymentRequest(tripId);
    setPaymentRequestIdForReSendPaymentRequest(paymentRequestId);
  };

  const handleCloseReSendPaymentRequestModal = () => {
    setIsReSendPaymentRequestModalOpen(false);
    setTripIdForReSendPaymentRequest(null);
    setPaymentRequestIdForReSendPaymentRequest(null);
  };

  const reSendPaymentRequest = async () => {
    try {
      setIsReSendPaymentRequestLoading(true);
      await request.update(`trips/payments/request/re-send/${tripIdForReSendPaymentRequest}/${paymentRequestIdForReSendPaymentRequest}`);
      handleCloseReSendPaymentRequestModal();
      setIsRefetch(!isRefresh);
      getErrorStatusMessage({
        status: 200,
        message: 'Payment request was resent successfully.',
      });
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: e?.response?.data?.error || 'Something went wrong. Try again later.',
      });
    } finally {
      setIsReSendPaymentRequestLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Container>
        {isFiltersOpen && (
          <FiltersReciever
            filterComponents={filterComponents}
            isOpenDatePicker={isOpenDatePicker}
            placeholder="Search for users, team and more"
            filters={filters}
            setFilter={setFiltersAndToFirstPage}
          />
        )}

        <TableWrapper>
          <PageHeader>
            <FilterHeaderWrapper>
              <TogglerWithCount
                isActive={isFiltersOpen}
                count={filtersCount}
                handleToggle={toggleFilters}
                icon={<SoftwareSorting fontSize="1.5rem" />}
              />
              <Text size={32} as="h1" styles={{ marginBottom: 16 }}>
                Trips
              </Text>
            </FilterHeaderWrapper>
            <TripsActionWrapper>
              {!!tripsForMergingData.length && (
                <TogglerWithCount
                  isActive={isPossibleTripsForMergingOpen}
                  count={tripsForMergingData.length}
                  handleToggle={handleIsPossibleTripsForMergingListOpen}
                  icon={<ArrowsCrossLines fontSize="1.5rem" />}
                  label={'possible trips for merging'}
                  position={'left'}
                />
              )}
              {!!unassignedFlights.length && (
                <TogglerWithCount
                  isActive={isDrawerWithUnassignedFlightsOpen}
                  count={unassignedFlights.length}
                  handleToggle={handleIsUnassignedFlightsOpen}
                  icon={<TravelAirplane fontSize="1.5rem" />}
                  label={'unassigned flights'}
                  position={'left'}
                />
              )}
              {!!unassignedAccommodations.length && (
                <TogglerWithCount
                  isActive={isDrawerWithUnassignedAccommodationsOpen}
                  count={unassignedAccommodations.length}
                  handleToggle={handleIsUnassignedAccommodationsOpen}
                  icon={<TravelHotel fontSize="1.5rem" />}
                  label={'unassigned accommodations'}
                  position={'left'}
                />
              )}
              {isCurrentUserTravelops && (
                <>
                  <Button
                    {...(isExportStatisticsLoading ? { animation: 'progress' } : {})}
                    disabled={isExportStatisticsLoading}
                    variant="primary"
                    style={{ marginRight: '12px' }}
                    size="small"
                    onClick={handleExportStatistics}
                  >
                    <FilesExport /> Export statistics
                  </Button>
                  <Button
                    variant="primary"
                    size="small"
                    onClick={() => setIsAddExternalTripModalOpen(true)}
                  >
                    <ControlsPlus /> Add external trip
                  </Button>
                </>
              )}
            </TripsActionWrapper>
          </PageHeader>

          <TablePagination
            isShowTableFilters={isShowTableFilters}
            limitPerPage={LIMIT_PER_PAGE.TRIPS}
            totalItems={totalItems}
            data={currentData}
            page={page}
            toggleTableFilters={toggleTableFilters}
            changeRowHeight={changeRowHeight}
            refetchData={handleRefetchData}
            nextPage={nextPage}
            prevPage={prevPage}
          />

          {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
        </TableWrapper>

        {isPossibleTripsForMergingOpen && (
          <div>
            <Drawer
              handleClose={closePossibleTripsForMerging}
              title={`Possible trips for merging • ${tripsForMergingData.length}`}
            >
              <DashboardOverview
                data={tripsForMergingData}
                type={NOTIFICATION_TYPES.TRIPS_FOR_MERGING}
                onClick={(params: any) => handleMergeTripsModalOpen(params, true)}
              />
            </Drawer>
          </div>
        )}
        {isDrawerWithUnassignedFlightsOpen && (
          <div>
            <Drawer
              handleClose={handleIsUnassignedFlightsOpen}
              title={`Unassigned flights • ${unassignedFlights.length}`}
            >
              <DashboardOverview
                data={unassignedFlights}
                type={NOTIFICATION_TYPES.UNASSIGNED_FLIGHTS}
                onClick={handleOpenUnassignedFlightModal}
              />
            </Drawer>
          </div>
        )}
        
        {isDrawerWithUnassignedAccommodationsOpen && (
          <div>
            <Drawer
              handleClose={handleIsUnassignedAccommodationsClose}
              title={`Unassigned accommodations • ${unassignedAccommodations.length}`}
            >
              <DashboardOverview
                data={unassignedAccommodations}
                type={NOTIFICATION_TYPES.UNASSIGNED_ACCOMMODATIONS}
                onClick={handleOpenUnassignedAccommodationModal}
              />
            </Drawer>
          </div>
        )}

        {isOpenOverview && (
          <DrawerWrapper>
            <Drawer
              title={overviewData?.user?.fullName ?? ''}
              status={overviewData.status}
              tripId={overviewData.id}
              handleExportPdf={handleExportPdf}
              getStatusLabel={getStatusLabel}
              handleClose={closeTripOverview}
              withStatus
            >
              <OverviewDrawerContent
                isChangeStatusModal={isOverviewStatusModal}
                userRole={userRole}
                // @ts-ignore
                purposes={purposes}
                statuses={statuses}
                data={overviewData}
                handleOpenReSendPaymentRequestModal={handleOpenReSendPaymentRequestModal}
                handleEditDeclineReasonModalOpen={handleEditDeclineReasonModalOpen}
                requestToSubmitExpensesReport={requestToSubmitExpensesReport}
                handleRequestPaymentModalOpen={handleOpenRequestPaymentModal}
                handleSendTripToVerification={handleSendTripToVerificationModalOpen}
                handleChangeTripDestination={handleChangeDestinationModalOpen}
                handleEditCommentModalOpen={handleEditCommentModalOpen}
                handleChangeTripPurpose={handleUpdateTripPurpose}
                handleChangeTripStatus={handleUpdateTripStatusConfirmationModalOpen}
                onChangePaymentStatus={onChangePaymentStatus}
                getTripPurposeLabel={getTripPurposeLabel}
                handleSyncUpdates={handleSyncUpdatesModalOpen}
                requestFeedback={requestFeedback}
                updateFeedback={updateFeedback}
                getStatusLabel={getStatusLabel}
                createTTR={createTTR}
                handleCreateInvoiceModalOpen={handleCreateInvoiceModalOpen}
              />
            </Drawer>
          </DrawerWrapper>
        )}

        {isOpenTicket && (
          <DrawerWrapper>
            <Drawer
              isTicket
              title={ticketData?.user?.fullName ?? ''}
              status={ticketData?.status}
              tripId={ticketData?.id}
              handleExportPdf={handleExportPdf}
              getStatusLabel={getStatusLabel}
              handleClose={closeTripTicket}
              withStatus
            >
              <TicketDrawerContent
                travelDocumentTypes={tripOptions.travelDocumentTypes}
                isLoading={isExternalTripPartLoading}
                countries={tripOptions.countries}
                userRole={userRole}
                data={ticketData}
                handleOpenAttachOtherTransportModal={handleOpenAttachOtherTransportModal}
                handleOpenAttachAccommodationModal={handleOpenAttachAccommodationModal}
                handleUpdateExternalAccommodation={(values, accommodationId) => handleUpdateExternalAccommodation(values, accommodationId, ticketData?.id)}
                handleChangeAccommodationPrice={openUpdateAccommodationPriceModal}
                handleOpenAttachFlightModal={handleOpenAttachFlightModal}
                handleUpdateOtherTransport={(values, otherTransportId) => handleUpdateOtherTransport(values, otherTransportId, ticketData?.id)}
                deleteOtherTransport={deleteOtherTransport}
                deleteAccommodation={deleteAccommodation}
                handleUpdateFlight={(values, isExternal, flightId) => (
                  handleUpdateFlight(values, isExternal, flightId, ticketData?.id)
                )}
                handleRefetchData={handleRefetchData}
                deleteFlight={deleteFlight}
              />
            </Drawer>
          </DrawerWrapper>
        )}
      </Container>

      {isUpdateTripPurposeModalOpen && (
        <Dialog
          submitButtonLabel="Confirm"
          maxWidth={500}
          title={`Update trip purpose — #${tripForUpdateTripPurpose.id}`}
          data={tripForUpdateTripPurpose.id}
          onSubmit={() => handleChangeTripPurposeWithDetails(tripForUpdateTripPurpose.id, purposeWithDetails)}
          onClose={handleUpdateTripPurposeClose}
        >
          <ConfirmDialogBodyWrapper>
            {tripForUpdateTripPurpose.userType === TRIP_USER_TYPES.INTERNAL && <InfoBlock text={getTextForPurposeChange()} />}
            <Text size={16}>Are you sure you want to update trip purpose to </Text>
            <Text size={16}>{getTripPurposeLabel(purposeWithDetails.purposeId)}</Text>
            <Text size={16}>?</Text>
          </ConfirmDialogBodyWrapper>
        </Dialog>
      )}

      {isAddExternalTripModalOpen && (
        <ExternalTripContentWrapper
          isLoading={isAssignmentLoading}
          handleSubmit={(values) => handleCreateExternalTrip(values)}
          handleClose={handleCloseAddTripModal}
        />
      )}

      {isAttachExternalAccommodationOpen && (
        <Dialog
          withFooter={false}
          width={600}
          title="Attach external accommodation"
          onClose={() => setIsAttachExternalAccommodationOpen(false)}
        >
          <ExternalAccommodation
            handleSubmit={(values) => handleAttachExternalAccommodation(values,ticketData?.id)}
            handleClose={() => setIsAttachExternalAccommodationOpen(false)}
            isLoading={isExternalTripPartLoading}
          />
        </Dialog>
      )}
  
      {isAttachExternalFlightOpen && (
        <Dialog
          withFooter={false}
          width={600}
          title="Attach external flight"
          onClose={() => setIsAttachExternalFlightOpen(false)}
        >
          <ExternalFlightForm
            shouldBeScrollable
            isLoading={isExternalTripPartLoading}
            handleSubmit={(values) => handleAttachExternalFlight(values, ticketData?.id)}
            handleClose={() => setIsAttachExternalFlightOpen(false)}
            passengers={ticketData?.user ? [userToPassengerOption(ticketData?.user)] : []}
          />
        </Dialog>
      )}

      {isDeleteModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableData}
          onClose={closeDeleteTripModal}
          onSubmit={handleDeleteTrips}
        >
          <DialogContent>
            <Text size={16}>Do you want to delete this trip?</Text>
          </DialogContent>
        </Dialog>
      )}

      {isSyncTripUpdatesModalOpen && (
        <Dialog
          submitButtonLabel="Confirm"
          isSubmitDisabled={isSyncUpdatesLoading}
          maxWidth={500}
          title="Sync trip updates"
          onClose={handleSyncUpdatesModalClose}
          onSubmit={handleSyncUpdates}
        >
          <DialogContent>
            <InfoBlock
              text={`
                The trip will be declined automatically if all parts of the trip are cancelled.
                Traveler will be notified about updates.
              `}
            />
            <Text size={16}>
              Are you sure you want to sync updates for trip <strong>#{tripIdForSyncUpdates}</strong> ?
            </Text>
          </DialogContent>
        </Dialog>
      )}

      {isTripChangeDestinationModalOpen && (
        <Dialog
          withFooter={false}
          maxWidth={500}
          title={`Change trip destination — #${tripForChangeDestination?.id}`}
          data={tripForChangeDestination}
          onClose={handleChangeDestinationModalClose}
        >
          <DialogContent>
            <ChangeTripDestinationDialogContent
              loading={isTripChangeDestinationLoading}
              trip={tripForChangeDestination}
              onSubmit={handleChangeDestination}
              onCancel={handleChangeDestinationModalClose}
            />
          </DialogContent>
        </Dialog>
      )}

      {isTripEditCommentModalOpen && (
        <Dialog
          withFooter={false}
          maxWidth={500}
          title={`Edit comment — #${tripForEditComment?.id}`}
          onClose={handleEditCommentModalClose}
        >
          <DialogContent>
            <EditTripCommentDialogContent
              loading={isTripEditCommentLoading}
              trip={tripForEditComment}
              onSubmit={handleEditComment}
              onCancel={handleEditCommentModalClose}
            />
          </DialogContent>
        </Dialog>
      )}

      {isEditDeclineReasonModalOpen && (
        <Dialog
          withFooter={false}
          maxWidth={500}
          title={`Edit decline reason — #${tripForEditDeclineReason?.id}`}
          onClose={handleEditDeclineReasonModalClose}
        >
          <DialogContent>
            <EditTripDeclineReasonDialogContent
              loading={isTripEditDeclineReasonLoading}
              trip={tripForEditDeclineReason}
              onSubmit={handleEditDeclineReason}
              onCancel={handleEditDeclineReasonModalClose}
            />
          </DialogContent>
        </Dialog>
      )}

      {(tableTripStatusModal.status || isOverviewStatusModal || isDownDrawerStatusModal) && (
        <HiddenStatusBackground onClick={handleCloseStatusModal} />
      )}

      {isUpdateTripStatusModalOpen && !!tripsForUpdateTripStatus?.length && (
        <ChangeTripsStatusDialog 
          submitButtonLabel={'Update'}
          isEditable={false}
          isLoading={newStatusForTripUpdateLoading}
          status={newStatusForTrip}
          title={`Update trip status — #${tripsForUpdateTripStatus[0].tripId}`}
          trips={tripsForUpdateTripStatus}
          onSubmit={handleChangeTripStatus}
          onClose={handleUpdateTripStatusClose}
        />
      )}

      {isDownDrawerStatusModal && (
        <ChangeTripsStatusDialog 
          submitButtonLabel={'Apply'}
          isEditable
          isLoading={isMultiTripsChangeStatusLoading}
          statuses={statuses}
          status={TripStatusType.PENDING}
          title={'Change status'}
          trips={checkboxesData}
          onSubmit={handleChangeStatusForMultiTrips}
          onClose={handleCloseStatusModal}
        />
      )}

      {isUpdateAccommodationPriceModalOpen && (
        <Dialog
          withFooter={false}
          maxWidth={500}
          title={`Accommodation change price — [${accommodationForUpdatePrice?.reference}]`}
          data={accommodationForUpdatePrice}
          onClose={closeUpdateAccommodationPriceModal}
        >
          <DialogContent>
            <ChangeAccommodationPriceDialogContent
              accommodation={accommodationForUpdatePrice}
              loading={isAccommodationUpdatePriceLoading}
              onSubmit={handleUpdateAccommodationPrice}
              onCancel={closeUpdateAccommodationPriceModal}
            />
          </DialogContent>
        </Dialog>
      )}

      {isMergeTripsModalOpen && (
        <MergeTripsDialog
          isAvailableRenderAdditionalActions={(isCurrentUserTravelops || (!isPossibleTripsForMergingActive && isCurrentUserTravelops))}
          submitButtonLabel={isPossibleTripsForMergingActive ? 'Confirm' : 'Merge'}
          isSubmitDisabled={isMergeTripsLoading}
          onSubmit={handleMergeTrips}
          onClose={handleMergeTripsModalClose}
          mergeTripIds={isPossibleTripsForMergingActive ? mergeTripIds : checkboxesData.map(({ tripId }) => tripId)}
        />
      )}

      {isAssignUnassignedFlightModalOpen && (
        <UnassignedFlightsDialog
          isLoading={isAssignmentLoading}
          flightData={selectedFlight}
          handleSubmitUnassignedTrips={handleSubmitUnassignedFlights}
          handleUnassignedFlightsModalClose={handleUnassignedFlightsModalClose}
        />
      )}

      {isAssignUnassignedAccommodationsModalOpen && (
        <UnassignedAccommodationDialog
          isLoading={isAssignmentLoading}
          accommodationData={selectedAccommodation}
          handleSubmitUnassignedTrips={handleSubmitUnassignedAccommodations}
          handleUnassignedAccommodationModalClose={handleUnassignedAccommodationModalClose}
          renderAdditionalActions={renderIgnoreUnassignedAccommodationAction}
        />
      )}

      {isAssignUnassignedAccommodationsIgnoreConfirmModalOpen && (
        <Dialog
          submitButtonLabel={'Confirm'}
          title={'Ignore accommodation'}
          onSubmit={handleSubmitIgnoreUnassignedAccommodationAction}
          onClose={() => { 
            setIsAssignUnassignedAccommodationsIgnoreConfirmModalOpen(false);
            setIsAssignUnassignedAccommodationsModalOpen(true);
          }}
          maxWidth={500}
        >
          <InfoBlock text='The unassigned accommodation will be ignored. Option will disappear from unassigned accommodation section and no notification will be sent.'/>
          <ConfirmMessage>Are you sure you want to ignore this accommodation?</ConfirmMessage>
        </Dialog>
      )}

      {!!checkboxesData.length && (
        <DownDrawer
          data={checkboxesData}
          text="trip"
          handleMergeTripsDialog={handleMergeTripsModalOpen}
          handleChangeStatus={setDownDrawerStatusModal}
          handleRemoveDialog={openDeleteTripModal}
          handleSetCheckbox={setCheckboxData}
        />
      )}

      {isSendToVerificationModalOpen && (
        <Dialog
          submitButtonLabel="Confirm"
          maxWidth={500}
          title="Send trip to manager verification"
          data={tripIdForSendingToVerification}
          onSubmit={() => handleSendTripToVerification(tripIdForSendingToVerification)}
          onClose={() => setIsSendToVerificationModalOpen(false)}
        >
          <ConfirmDialogBodyWrapper>
            <InfoBlock text="The trip's status will be changed to pending. Managers will be able only to approve this trip." />
            <Text size={16}>Are you sure you want to send this trip to an additional manager's verification?</Text>
          </ConfirmDialogBodyWrapper>
        </Dialog>
      )}

      {isAttachOtherTransportOpen && (
        <Dialog
          withFooter={false}
          width={600}
          title="Attach other transport"
          onClose={() => setIsAttachOtherTransportOpen(false)}
        >
          <OtherTransport 
            handleClose={() => setIsAttachOtherTransportOpen(false)}
            handleSubmit={(values) => handleAttachOtherTransport(values, ticketData?.id)}
            isLoading={isExternalTripPartLoading}
          />
        </Dialog>
      )}

      {isCreateInvoiceModalOpen && (
        <Dialog
          submitButtonLabel="Confirm"
          isLoading={isInvoiceCreationLoading}
          maxWidth={500}
          title="Confirm"
          onSubmit={() => handleInvoiceCreation()}            
          onClose={() => setIsCreateInvoiceModalOpen(false)}
        >
          <InfoBlock
            text={`
              Creating the invoice can produce duplication in balanced.io.
              Please ensure that accountants have not previously added the invoice
              manually for this payment.
            `}
          />
          <Text size={16}>Are you sure you want to create an invoice?</Text>
        </Dialog>
      )}

      {isRequestPaymentModalOpen && (
        <RequestPaymentDialog
          isLoading={isRequestPaymentLoading}
          trip={tripForRequestPayment}
          onSubmit={handleRequestPayment}
          onClose={handleCloseRequestPaymentModal}
        />
      )}

      {isReSendPaymentRequestModalOpen && (
        <Dialog
          submitButtonLabel="Confirm"
          isLoading={isReSendPaymentRequestLoading}
          maxWidth={500}
          title={`Payment request resend — ID ${paymentRequestIdForReSendPaymentRequest}`}
          onSubmit={reSendPaymentRequest}            
          onClose={handleCloseReSendPaymentRequestModal}
        >
          <Text size={16}>Are you sure you want to resend the payment request to the payer?</Text>
        </Dialog>
      )}
    </>
  );
};

export default Trips;
