import { BUSINESS_VERTICAl_TYPES } from '../../constants';
import validationSchema from '../../utils/validation-schema';
import Yup from '../../utils/validation';

const validateMessages = {
  name: {
    min: 'Business vertical name cannot be less than 2 characters',
    max: 'Business vertical name cannot be longer than 255 characters',
    required: 'Business vertical name cannot be empty',
  },
  company: {
    required: 'Company cannot be empty'
  },
  travelopses: {
    min: 'At least one travelops should be added',
    max: `Maximum ${validationSchema.BUSINESS_VERTICAL.TRAVELOPSES.max} travelopses can be added`,
  },
  accountants: {
    min: 'At least one accountant should be added',
    max: `Maximum ${validationSchema.BUSINESS_VERTICAL.ACCOUNTANTS.max} accountants can be added`,
  },
  ceos: {
    max: `Maximum ${validationSchema.BUSINESS_VERTICAL.CEOS.max} CEO(s) can be added`,
  },
};

export const businessVerticalSchema = Yup.object().shape({
  name: Yup
    .string()
    .required(validateMessages.name.required)
    .min(2, validateMessages.name.min)
    .max(255, validateMessages.name.max)
    .withoutSpaces(),
  company: Yup
    .number()
    .required(validateMessages.company.required)
    .nullable(),
  isDefault: Yup
    .bool(),
  type: Yup
    .string()
    .required()
    .oneOf([BUSINESS_VERTICAl_TYPES.INTERNAL, BUSINESS_VERTICAl_TYPES.EXTERNAL])
    .withoutSpaces(), 
  ceos: Yup
    .array()
    .of(Yup.number())
    .max(validationSchema.BUSINESS_VERTICAL.CEOS.max, validateMessages.ceos.max)
    .nullable(),
  travelopses: Yup
    .array()
    .of(Yup.number())
    .min(validationSchema.BUSINESS_VERTICAL.TRAVELOPSES.min, validateMessages.travelopses.min)
    .max(validationSchema.BUSINESS_VERTICAL.TRAVELOPSES.max, validateMessages.travelopses.max),
  accountants: Yup
    .array()
    .of(Yup.number())
    .min(validationSchema.BUSINESS_VERTICAL.ACCOUNTANTS.min, validateMessages.accountants.min)
    .max(validationSchema.BUSINESS_VERTICAL.ACCOUNTANTS.max, validateMessages.accountants.max),
  seniorAccountant: Yup
    .number()
    .nullable(),
});
