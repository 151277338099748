import { isNil } from "lodash";
import moment from "moment";

export const isGenderValid = (isFemale?: number | null) => {
  return isNil(isFemale);
};

export const formatDateFieldOnSave = (dateFieldValue) => {
  return moment(dateFieldValue).format('YYYY-MM-DD');
};
