import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import withNavigation from '../../hocs/withNavigation';
import EditProfile from '../../pages/EditProfile';
import { TextPage } from '../../pages/TextPage';
import Reports from '../../pages/Reports';
import Dashboard from '../../pages/Dashboard';
import Expenses from '../../pages/Expenses';
import { Logout } from '../../pages/Logout';
import Payments from '../../pages/Payments';
import Users from '../../pages/Users';
import Trips from '../../pages/Trips';
import Tools from '../../pages/Tools';
import PrivateRoute from './PrivateRoute';

const PrivateRoutes = () => (
  <Switch>
    <PrivateRoute component={withNavigation(Dashboard)} exact path="/" />
    <PrivateRoute component={withNavigation(Trips)} path="/trips" />
    <PrivateRoute component={withNavigation(Expenses)} path="/expenses" />
    <PrivateRoute component={withNavigation(Reports)} path="/reports" />
    <PrivateRoute component={withNavigation(Payments)} path="/payments" />
    <PrivateRoute component={withNavigation(Users)} path="/users" />
    <PrivateRoute component={withNavigation(Tools)} path="/tools" />

    <Route path="/edit-profile" component={EditProfile} />
    <Route path="/login" render={(props) => <Redirect {...props} to="/edit-profile" />} />
    <Route path="/logout" component={Logout} />

    <PrivateRoute component={TextPage} pageId="not-found" />
  </Switch>
);

export default withRouter(PrivateRoutes);
