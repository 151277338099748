import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import padStart from 'lodash/padStart';
import NumberFormat from 'react-number-format';
import { ReactComponent as SlackIcon } from '../static/icons/slackIcon.svg';
import { TravelAirplane, ControlsPlus, GenericHome, OtherRocket } from '@heathmont/moon-icons';
import { Label } from '@heathmont/moon-core';
import {
  BusinessVerticalLabelWrapper,
  TextWithLabelWrapper,
  GroupedTextAligner,
  AmountRateWrapper,
  SlackIconWrapper,
  TeamsItemWrapper,
  CustomDatesText,
  UserNameLabel,
  LabelsWrapper,
  TextWithLabel,
  GroupedCount,
  LabelWrapper,
  GroupedLabel,
  ExpensesRate,
  LabelForText,
  UserWrapper,
  NumberCell,
  CustomText,
  LabelValue,
  EuroLabel,
  SubTitle,
} from '../styled';
import { N_A, getCronText } from './commonFunctions';
import { tripStatusNames, TripStatusType } from '../enums/TripStatusType';
import {
  tripsExpensesStatusNames,
  tripsExpensesTypeNames,
  TRIPS_EXPENSES_TYPES,
  EXPENSES_TYPE,
} from '../enums/ExpensesType';
import { LOG_STATUS } from '../enums/LogStatus';
import { UserRoleType } from '../enums/UserRoleTypes';
import { TripTypes } from '../enums/TripTypes';
import { getTripTime } from './trips';
import { UserStatuses } from '../enums/UserStatuses';
import EllipsisText from '../components/EllipsisText';
import PaymentProviderIcon from '../components/PaymentProviderIcon'; 
import StarRating from '../components/StarRating';
import { FEEDBACK_TYPE } from '../enums/FeedbackType';
import { TRIP_USER_TYPES, BUSINESS_VERTICAl_TYPES } from '../constants';
import { PAYMENT_STATUS } from '../enums/PaymentStatus';
import { Payment } from '../enums/Payment';
import { PAYMENT_TYPE, paymentTypeOptions } from '../enums/PaymentTypes';
import { CryptoTransactionStatus, CryptoTransactionStatusLabel } from '../enums/CryptoTransaction';
import { CreditCardOrderStatus, CreditCardOrderStatusLabel } from '../enums/CreditCardOrder';
import { payerTypeOptions } from '../enums/PayerTypes';
import { PurposeTypes } from '../enums/PurposeTypes';
import { Hotel } from '../enums/Hotel';
import { HOTEL_TYPES, hotelTypesOptions } from '../enums/HotelType';
import { TeamTypes } from '../enums/TeamTypes';
import { EmployerTypes } from '../enums/EmployerTypes';

export const defineTripTypeIcon = (type: TripTypes, fontSize = '1.5rem') => {
  if (type === TripTypes.FLIGHT_AND_ACCOMMODATION) {
    return (
      <div style={{ display: 'flex' }}>
        <TravelAirplane fontSize={fontSize} /> <ControlsPlus fontSize={fontSize} /> <GenericHome fontSize={fontSize} />
      </div>
    );
  }

  if (type === TripTypes.FLIGHT_OTHER_TRANSPORT) {
    return (
      <div style={{ display: 'flex' }}>
         <TravelAirplane fontSize={fontSize} /> <ControlsPlus fontSize={fontSize} /> <OtherRocket fontSize={fontSize} />
      </div>
    );
  }

  if (type === TripTypes.ACCOMMODATION_OTHER_TRANSPORT) {
    return (
      <div style={{ display: 'flex' }}>
         <GenericHome fontSize={fontSize} /> <ControlsPlus fontSize={fontSize} /> <OtherRocket fontSize={fontSize} />
      </div>
    );
  }

   if (type === TripTypes.FLIGHT_ACCOMMODATION_OTHER_TRANSPORT) {
    return (
      <div style={{ display: 'flex' }}>
         <TravelAirplane fontSize={fontSize} /> <ControlsPlus fontSize={fontSize} /> <GenericHome fontSize={fontSize} /> <ControlsPlus fontSize={fontSize} /> <OtherRocket fontSize={fontSize} />
      </div>
    );
  }

  if (type === TripTypes.FLIGHT) {
    return <TravelAirplane fontSize={fontSize} />;
  }

  if (type === TripTypes.ACCOMMODATION) {
    return <GenericHome fontSize={fontSize} />;
  }

  if (type === TripTypes.OTHER_TRANSPORT) {
    return <OtherRocket fontSize={fontSize} />;
  }
};

export const formatDateAndTime = (date, withTime = false) => (
  <>
    <CustomText>{moment(date).format('MMM DD, YYYY')}</CustomText>
    {withTime && <SubTitle>{moment(date).format('HH:mm')}</SubTitle>}
  </>
);

export const formatManager = (manager) => {
  return (
    <div>
      <EllipsisText text={manager?.id ? `${manager.fullName}` : N_A}/>
      {manager?.id && manager?.status === UserStatuses.Unregistered && (
        <SubTitle>Unregistered</SubTitle>
      )}
    </div>
  )
};

export const formatStartAndEndDates = (start, end) => (
  <CustomDatesText>
    {moment(start).format('MMM DD, YYYY')}
    {end && ' – '}
    {moment(end).format('MMM DD, YYYY')}
  </CustomDatesText>
);

const formatUserAndTeam = (user, tripUserType = null) => {
  const currentUserStatusLabel = user?.status === UserStatuses.External ? 
    <UserNameLabel backgroundColor={'#FDF5E2'} color={'#F5A300'}>EXT</UserNameLabel> : 
    user?.status === UserStatuses.Deleted ?
    <UserNameLabel backgroundColor={'#FFEBED'} color={'#FF1F39'}>DEL</UserNameLabel> :
    undefined;
  const tripUserTypeLabel = user?.status === UserStatuses.Deleted && tripUserType === TRIP_USER_TYPES.EXTERNAL ?
    <UserNameLabel backgroundColor={'#FDF5E2'} color={'#F5A300'} marginLeft={'35px'}>EXT</UserNameLabel> : 
    undefined;

  return (
    <>
      <UserWrapper marginRight={tripUserTypeLabel && '55px'}>
        <CustomText>
          {user?.fullName ?? ''}{currentUserStatusLabel}{tripUserTypeLabel}
        </CustomText>
      </UserWrapper>
      <SubTitle>
        {user?.team?.name ?? ''}
      </SubTitle>
    </>
  )
};

export const formatTtrLink = (tripTicketNumberInJira, companyJiraHost) => {
  return !!tripTicketNumberInJira && !!companyJiraHost && (
    <a
      target="_blank" 
      style={{ whiteSpace: 'nowrap' }}
      href={`https://${companyJiraHost}/browse/${tripTicketNumberInJira}`}
    >
      {tripTicketNumberInJira}
    </a>
  );
};

export const formatPurpose = (purpose, color?: string) => {
  return (
    <LabelWrapper>
      <Label backgroundColor={purpose?.color || color || 'trunks.100'}>
        {purpose?.label || purpose?.title || purpose || 'Other'}
      </Label>
    </LabelWrapper>
  );
};

export const getUserLabelSuperscript = (user, relative?: boolean) => {
  if (user?.status === UserStatuses.External) {
    return (
      <UserNameLabel
        backgroundColor={'#FDF5E2'}
        color={'#F5A300'}
        relative={relative}
      >
        EXT
      </UserNameLabel>
    );
  }

  if (user?.status === UserStatuses.Deleted) {
    return (
      <UserNameLabel
        backgroundColor={'#FFEBED'}
        color={'#FF1F39'}
        relative={relative}
      >
        DEL
      </UserNameLabel>
    );
  }

  return null;
};

export const formatUserNameWithStatus = (user) => {
  return (
    <UserWrapper>
      <CustomText>
        {user?.fullName ?? ''}{getUserLabelSuperscript(user)}
      </CustomText>
    </UserWrapper>
  );
};

export const getBooleanLabel = (isTrue: boolean, options = { trueLabel: 'yes', falseLabel: 'no' }) => {
  const backgroundColor = isTrue ? '#0BC15A' : '#FF1F39';
  const color = isTrue ? '#DAFDE9' : '#FFEBED';
  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {isTrue ? options?.trueLabel : options?.falseLabel}
      </Label>
    </LabelWrapper>
  );
};

export const getRequiredValue = (required: boolean) => {
  const backgroundColor = required ? '#DAFDE9' : '#FFEBED';
  const color = required ? '#0BC15A' : '#FF1F39';
  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {required ? 'REQUIRED' : 'NOT REQUIRED'}
      </Label>
    </LabelWrapper>
  );
};

export const getCryptoPaymentStatusLabel = (status: CryptoTransactionStatus) => {
  let backgroundColor;
  let color;

  switch (status) {
    case CryptoTransactionStatus.SENT_OUT:
    case CryptoTransactionStatus.COMPLETED:
    case CryptoTransactionStatus.ARCHIVED: {
      backgroundColor = '#DAFDE9';
      color = '#0BC15A';
      break;
    }
  
    case CryptoTransactionStatus.RETURNED_OR_REVISION_NEEDED:
    case CryptoTransactionStatus.CANCELLED: {
      backgroundColor = '#FFEBED';
      color = '#FF1F39';
      break;
    }
  
    default:
      backgroundColor = '#FDF5E2';
      color = '#F5A300';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {CryptoTransactionStatusLabel[status]}
      </Label>
    </LabelWrapper>
  );
};

export const getCardPaymentStatusLabel = (status: CreditCardOrderStatus) => {
  let backgroundColor;
  let color;

  switch (status) {
    case CreditCardOrderStatus.AUTHORISED:
    case CreditCardOrderStatus.COMPLETED: {
      backgroundColor = '#DAFDE9';
      color = '#0BC15A';
      break;
    }
  
    case CreditCardOrderStatus.FAILED:
    case CreditCardOrderStatus.CANCELLED: {
      backgroundColor = '#FFEBED';
      color = '#FF1F39';
      break;
    }
  
    default:
      backgroundColor = '#FDF5E2';
      color = '#F5A300';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {CreditCardOrderStatusLabel[status]}
      </Label>
    </LabelWrapper>
  );
};

export const getPaymentStatusLabel = (status: PAYMENT_STATUS) => {
  let backgroundColor;
  let color;

  switch (status) {
    case PAYMENT_STATUS.SUCCESS: {
      backgroundColor = '#DAFDE9';
      color = '#0BC15A';
      break;
    }
    case PAYMENT_STATUS.FAILED: {
      backgroundColor = '#FFEBED';
      color = '#FF1F39';
      break;
    }
    default:
      backgroundColor = '#FDF5E2';
      color = '#F5A300';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {status.toUpperCase()}
      </Label>
    </LabelWrapper>
  );
};

export const getStatusLabel = (status: TripStatusType, title?: string) => {
  let backgroundColor;
  let color;

  switch (status) {
    case TripStatusType.APPROVED: {
      backgroundColor = '#DAFDE9';
      color = '#0BC15A';
      break;
    }
    case TripStatusType.DECLINED: {
      backgroundColor = '#FFEBED';
      color = '#FF1F39';
      break;
    }
    case TripStatusType.PENDING: {
      backgroundColor = '#FDF5E2';
      color = '#F5A300';
      break;
    }
    case TripStatusType.PURCHASED: {
      backgroundColor = '#F0ECFE';
      color = '#5C33CF';
      break;
    }
    default:
      backgroundColor = '#78787D';
      color = '#fffff';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {title ?? (tripStatusNames[status] || 'Other')}
      </Label>
    </LabelWrapper>
  );
};

export const formatCustomLabel = (dateString, startCustomDate, endCustomDate) => {
  const hasValue = dateString === 'custom' && startCustomDate && endCustomDate;

  return (
    <>
      Custom{' '}
      {hasValue ? (
        <LabelValue>
          ({moment(startCustomDate).format('DD.MM.YYYY')}-{moment(endCustomDate).format('DD.MM.YYYY')})
        </LabelValue>
      ) : (
        ''
      )}
    </>
  );
};

const formatGroupedColumnInfo = ({
  businessVertical,
  travelerStatus,
  tripStartDate,
  travelerName,
  paymentDate,
  destination,
  withCount = false,
  employer,
  groupBy,
  amount,
  count,
  team,
}: any) => {
  let label;
  let textValue;

  switch (groupBy) {
    case 'destinationForReports':
      label = 'Destination';
      textValue = destination?.city;
      break;
    case 'paymentDate':
      label = 'Payment date';
      textValue = moment(paymentDate).format('MMM DD, YYYY');
      break;
    case 'employer':
      label = 'Employer';
      textValue = employer;
      break;
    case 'team':
      label = 'Team';
      textValue = team;
      break;
    case 'amount':
      label = 'Amount';
      textValue = amount;
      break;
    case 'travelerName':
      label = 'Traveler name';
      textValue = travelerName;
      break;
    case 'tripStartDate':
      label = 'Trip time';
      textValue = moment(tripStartDate).format('MMM DD, YYYY');
      break;
    case 'businessVertical':
      label = 'Business vertical';
      textValue = businessVertical;
      break;
    default:
      break;
  }

  return (
    <>
      <GroupedLabel>{label}</GroupedLabel>
      {travelerStatus === UserStatuses.Deleted ? (
        <UserWrapper>
          <CustomText>
            {textValue}
            <UserNameLabel backgroundColor={'#FFEBED'} color={'#FF1F39'}>DEL</UserNameLabel>
          </CustomText>
        </UserWrapper>
      ) : (
        <GroupedTextAligner>
          <CustomText>{textValue}</CustomText>
          {withCount && (
            <GroupedCount>
              <span>Count</span> {count}
            </GroupedCount>
          )}
        </GroupedTextAligner>
      )}      
    </>
  );
};

export const formatPaymentMethod = (payments: Payment[]) => {
  if (!payments?.length) {
    return null;
  }

  return uniqBy(payments, 'paymentType')
    .map(payment => {
      const paymentOption = paymentTypeOptions.find(({ value }) => value === payment.paymentType);
      return paymentOption ? paymentOption.label : payment.paymentType;
    })
    .join(', ');
};

const formatCommentForReportTable = (comment) => {
  const MAX_NUMBER_OF_SYMBOLS = 30;

  if (comment?.length && comment?.length > MAX_NUMBER_OF_SYMBOLS) {
    const shortComment = `${comment.substring(0, MAX_NUMBER_OF_SYMBOLS)}...`;

    return (
      <span title={comment}>{shortComment}</span>
    );
  }

  return comment;
};

export const reportsDataMapper = (data) => {
  return data.map(({
    jiraTicketNumber,
    destinationLabel,
    companyJiraHost,
    tripFinishDate,
    tripStartDate,
    purposeColor,
    invoicePrice,
    totalCost,
    payments,
    tripType,
    comment,
    purpose,
    user,
  }) => {
    return {
      destinationForReports: destinationLabel,
      tripTicketLinkInJira: formatTtrLink(jiraTicketNumber, companyJiraHost),
      tripStartDate: formatStartAndEndDates(tripStartDate, tripFinishDate),
      paymentMethod: formatPaymentMethod(payments),
      travelerName: formatUserNameWithStatus(user),
      invoicePrice: <NumberCell>{invoicePrice}</NumberCell>,
      businessVertical: user?.businessVertical?.name,
      paymentDate: formatDateAndTime(tripFinishDate, true),
      totalCost: <NumberCell>{totalCost}</NumberCell>,
      purpose: formatPurpose(purpose, purposeColor),
      comment: formatCommentForReportTable(comment),
      employer: user?.employer?.name,
      team: user?.team?.name,
      type: defineTripTypeIcon(tripType),
    };
  });
};

export const reportsDataMapperByGroup = (data, groupBy) => {
  return data.map(({
    destinationForReports,
    totalCostByGroup,
    tripFinishDate,
    tripStartDate,
    countByGroup,
    payments,
    tripId,
    user,
  }) => {
    return {
      employer: formatGroupedColumnInfo({
        businessVertical: user?.businessVertical?.name,
        tripStartDate,
        paymentMethod: formatPaymentMethod(payments),
        travelerName: formatUserNameWithStatus(user),
        destination: destinationForReports,
        paymentDate: tripFinishDate,
        withCount: true,
        employer: user?.employer?.name,
        groupBy,
        count: countByGroup,
        team: user?.team?.name,
      }),
      destinationId: destinationForReports?.id,
      tripDates: { tripStartDate, tripFinishDate },
      totalCost: <NumberCell>{totalCostByGroup}</NumberCell>,
      tripId,
      user,
    };
  });
};

export const tripsDataMapper = (data) => {
  return data.map((trip) => {
    const { isSelected, avgFeedback, user, flightId, purpose, totalCost, status, id, userType } = trip;
    return {
      paymentRequired: !!trip.purpose.paymentRequired,
      userAndTeam: formatUserAndTeam(user, userType),
      destination: trip.destinationLabel,
      avgFeedback: avgFeedback ? <StarRating rate={+avgFeedback} readOnly /> : N_A,
      ticketName: flightId ? 'Ticket' : 'Booking',
      isSelected,
      totalCost: <NumberCell>{totalCost}</NumberCell>,
      tripTime: getTripTime(trip),
      statusId: status,
      userType,
      purpose: formatPurpose(purpose),
      manager: `${user?.manager?.fullName ?? N_A}`,
      status: getStatusLabel(status),
      tripId: id,
      type: defineTripTypeIcon(trip.tripType),
    };
  });
};

export const getUsersRoleLabel = (role) => {
  const roleName = role && role.name ? role.name : role;
  const color = '#FFFFFF';
  let backgroundColor;

  switch (roleName.toLowerCase()) {
    case UserRoleType.Travelops: {
      backgroundColor = '#0DE76C';
      break;
    }
    case UserRoleType.Traveler: {
      backgroundColor = '#8A67F4';
      break;
    }
    case UserRoleType.Manager: {
      backgroundColor = '#FF5C6F';
      break;
    }
    case UserRoleType.Accounting: {
      backgroundColor = '#F5A300';
      break;
    }
    default:
      backgroundColor = '#78787D';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {roleName.toUpperCase()}
      </Label>
    </LabelWrapper>
  );
};

export const getRoleLabelById = (roleId, roles: { value: number, title: string }[]) => {
  const role = roles.find((role) => role.value === roleId);

  if (!role) {
    return 'unknown';
  }

  return getUsersRoleLabel(role.title);
};

export const getUserStatusLabel = (status): JSX.Element => {
  let backgroundColor;
  let statusName;
  let color;

  if (status === UserStatuses.Registered) {
    backgroundColor = '#DAFDE9';
    statusName = 'Registered';
    color = '#0BC15A';
  } else if (status === UserStatuses.External) {
    backgroundColor = '#FDF5E2';
    statusName = 'External';
    color = '#F5A300';
  } else if (status === UserStatuses.Deleted) {
    backgroundColor = '#FFEBED';
    statusName = 'Deleted';
    color = '#FF1F39';
  } else {
    backgroundColor = '#78787D';
    statusName = 'Not registered';
    color = '#FFFFFF';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {statusName}
      </Label>
    </LabelWrapper>
  );
};

export const getUserTripTypeLabel = ({ type, withDeletedStatus }): JSX.Element => {
  let backgroundColor;
  let statusName;
  let color;

  if (type === UserStatuses.External) {
    backgroundColor = '#FDF5E2';
    statusName = 'External';
    color = '#F5A300';
  } else {
    backgroundColor = '#DAFDE9';
    statusName = 'Internal';
    color = '#0BC15A';
  }

  return (
    <LabelWrapper withSpanMarginRight>
      <Label backgroundColor={backgroundColor} color={color}>
        {statusName}
      </Label>
      {withDeletedStatus && 
        <Label backgroundColor={'#FFEBED'} color={'#FF1F39'}>
          DELETED
        </Label>
      }
    </LabelWrapper>
  );
};

export const getBoolStatusLabel = (status) => {
  let backgroundColor;
  let color;
  let statusName = 'Active';

  if (status === 1) {
    backgroundColor = '#DAFDE9';
    color = '#0BC15A';
  } else {
    backgroundColor = '#78787D';
    color = '#FFFFFF';
    statusName = 'Disabled';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {statusName}
      </Label>
    </LabelWrapper>
  );
};

export const getLabels = (items, key = 'name') => {
  const backgroundColor = 'rgba(253, 239, 196, 0.38)';
  const color = '#181B1C';

  return (
    <LabelsWrapper>
      {items.map((item) => (
        <div key={item?.id || item} title={item?.[key] || item}>
          <Label backgroundColor={backgroundColor} color={color}>
            {item?.[key] || item}
          </Label>
        </div>
      ))}
    </LabelsWrapper>
  );
};
const userGetSlackLink = (slackId, slack) => {
  const SLACK_CONSTANT = 'slack:ws:';

  if (slackId && slack) {
    const isValidData = slackId && slack;
    const rExp = new RegExp(SLACK_CONSTANT, 'g');
    const slackKeyword = slack ? slack.replace(rExp, '') : '';
    const url = `https://app.slack.com/client/${slackKeyword}/activity-page/user_profile/${slackId}`;

    return (
      <SlackIconWrapper isValid={isValidData} onClick={() => isValidData && window.open(url)}>
        <SlackIcon />
        <span>Slack</span>
      </SlackIconWrapper>
    );
  }

  return null;
};

export const usersDataMapper = (data) => {
  return data.map((user, i) => {
    const {
      totalReservation,
      businessVertical,
      activatedAt,
      totalSpent,
      isSelected,
      employer,
      fullName,
      userType,
      company,
      slackId,
      status,
      email,
      phone,
      role,
      id,
    } = user;

    return {
      totalReservation: <NumberCell>{+totalReservation}</NumberCell>,
      registeredAt: activatedAt ? formatDateAndTime(activatedAt, true) : N_A,
      businessVertical: formatBusinessVerticalLabel(businessVertical?.name, businessVertical?.type),
      userAndTeam: formatUserAndTeam(data[i], userType),
      totalSpent: <NumberCell>{+totalSpent}</NumberCell>,
      isSelected,
      employer: employer?.name ?? N_A,
      slackId: slackId ? userGetSlackLink(slackId, company.slack) : N_A,
      status: getUserStatusLabel(status),
      userId: id,
      fullName,
      email,
      phone: phone ?? N_A,
      role: getUsersRoleLabel(role),
      statusName: status,
    };
  });
};

export const getOrderNumber = (paymentId) => {
  return padStart(paymentId, 10, '0')
};

export const paymentsDataMapper = (data: Payment[]) => {
  return data.map(payment => ({
    ...payment,
    orderNumber: getOrderNumber(payment.id),
    paymentType: paymentTypeOptions.find(option => option.value === payment.paymentType)?.label || payment.paymentType,
    payerType: payerTypeOptions.find(option => option.value === payment.payerType)?.label || payment.payerType,
    provider: <PaymentProviderIcon provider={payment?.provider} />,
    createdAt: formatDateAndTime(payment?.createdAt, true),
    status: getPaymentStatusLabel(payment?.status),
    sum: <NumberCell>{+payment.sum}</NumberCell>,
  }));
};

export const toolsRolesDataMapper = (data) => {
  return data.map(({ id, name, amount, pages, isSelected, ...rest }) => {
    return {
      isDefaultRole: !!rest.default,
      rolesAmount: <NumberCell>{amount}</NumberCell>,
      isSelected,
      roleName: getUsersRoleLabel(name),
      roleId: id,
      pages: getLabels(pages),
    };
  });
};

export const getFeedbackTypeLabel = (type: FEEDBACK_TYPE) => {
  switch (type) {
    case FEEDBACK_TYPE.AFTER_FLIGHT_BOOKING_WEB_APP: {
      return 'Feedback after flight booking via Web app';
    }
    case FEEDBACK_TYPE.AFTER_EXPENSE_REPORT: {
      return 'Feedback after expense report';
    }
    case FEEDBACK_TYPE.AFTER_CONFIRM: {
      return 'Feedback after confirmation';
    }
    case FEEDBACK_TYPE.AFTER_TRIP: {
      return 'Feedback after trip';
    }
    case FEEDBACK_TYPE.REQUESTED_BY_ADMIN: {
      return 'Requested feedback';
    }
    default:
      return 'Feedback';
  } 
};

export const toolFeedbacksDataMapper = (data) => {
  return data.map((feedback) => {
    return {
      accommodation: feedback.accommodation
        ? <StarRating rate={+feedback.accommodation} readOnly />
        : null,
      comments: feedback.comments,
      flight: feedback.flight
        ? <StarRating rate={+feedback.flight} readOnly />
        : null,
      webApp: feedback.webApp
        ? <StarRating rate={+feedback.webApp} readOnly />
        : null,
      app: feedback.app
        ? <StarRating rate={+feedback.app} readOnly />
        : null,
      destination: feedback.trip.destinationLabel,
      submittedBy: feedback.submittedBy
        ? feedback.submittedBy?.fullName
        : null,
      submittedAt: feedback.submittedAt
        ? formatDateAndTime(feedback.submittedAt, true)
        : null,
      tripDates: formatStartAndEndDates(feedback.trip.tripStartDate, feedback.trip.tripFinishDate),
      traveler: formatUserAndTeam(feedback.traveler),
      id: feedback.id,
    };
  });
};

export const formatAccommodationGuests = (booking) => {
  return booking.guests.map(guest => ({ ...guest, fullName: `${guest.first_name} ${guest.last_name}` }))
};

export const ignoreListAccommodationsDataMapper = (data) => {
  return data.map((booking) => {
    const guests = formatAccommodationGuests(booking);
    return {
      reference: booking.reference,
      guests: getLabels(guests, 'fullName'),
      dates: formatStartAndEndDates(booking.checkIn, booking.checkOut),
      hotel: booking?.hotel?.name,
      city: (
        <>
          <CustomText>{booking?.region?.city}</CustomText>
          <SubTitle>{booking?.region?.country}</SubTitle>
        </>
      ),
      id: booking.id,
    };
  });
};

export const formatPurposeName = (purpose) => {
  if (purpose?.type === PurposeTypes.EXTERNAL) {
    return (
      <TextWithLabelWrapper>
        <TextWithLabel>
          {purpose?.title}
          <LabelForText>EXT</LabelForText>
        </TextWithLabel>
      </TextWithLabelWrapper>
    );
  }

  return purpose?.title;
};

export const formatPaymentTypes = (paymentTypes: PAYMENT_TYPE[]) => {
  const formatted = paymentTypes.map(paymentType => (
    paymentTypeOptions.find(option => option.value === paymentType)?.label || paymentType
  ));
  return getLabels(formatted, null);
};

export const toolsPurposesDataMapper = (purposes) => {
  return purposes.map(purpose => {
    const {
      accommodationBookingMargin,
      flightBookingMargin,
      expensesRequired,
      paymentRequired,
      paymentTypes,
      companies,
      isDefault,
      value,
    } = purpose;
    return {
      accommodationBookingMargin,
      flightBookingMargin,
      expensesRequired: getRequiredValue(expensesRequired),
      paymentRequired: getRequiredValue(paymentRequired),
      isDefaultPurpose: isDefault,
      paymentTypes: formatPaymentTypes(paymentTypes),
      purposeName: formatPurposeName(purpose),
      purposeId: value,
      companies: isDefault
        ? formatLabelForApplyToAll('companies')
        : getLabels(companies, 'name'),
    };
  });
};

export const toolsEmploymentTypesDataMapper = (data) => {
  return data.map(item => {
    const { id, label, keyword, expensesRequired, status, amount } = item;

    return {
      employmentTypeId: id,
      expensesRequired: getRequiredValue(expensesRequired),
      keyword,
      status: getBoolStatusLabel(status),
      users: amount,
      label,
    };
  });
};

export const formatEmployerName = (employer) => {
  if (employer?.type === EmployerTypes.EXTERNAL) {
    return (
      <TextWithLabelWrapper>
        <TextWithLabel>
          {employer?.name}
          <LabelForText>EXT</LabelForText>
        </TextWithLabel>
      </TextWithLabelWrapper>
    );
  }

  return employer?.name;
};

export const toolsEmployersDataMapper = (employers) => {
  return employers.map(employer => {
    const { id, accountant, registrationNumber, company, name, status, isSelected, businessVerticals } = employer;
    return {
      registrationNumber,
      employerName: formatEmployerName(employer),
      companyName: company?.name ?? '',
      businessVerticals: businessVerticals?.length
        ? formatBusinessVerticalLabels(businessVerticals)
        : null,
      employerId: id,
      accountant: accountant?.id ? getLabels([accountant], 'fullName') : '', 
      isSelected,
      status: getBoolStatusLabel(status),
    };
  });
};

export const toolsActionsAndNotificationsDataMapper = (data) => {
  return data.map(({ id, name, timeAndFrequency, status, description }) => {
    return {
      actionsAndNotificationsId: id,
      name: name,
      timeAndFrequency: getCronText(timeAndFrequency),
      status: getBoolStatusLabel(status),
      isActionsAndNotifications: true,
      description,
    };
  });
};

export const getTeamStatusLabel = (status) => {
  let backgroundColor;
  let color;
  let statusName;

  if (status) {
    backgroundColor = '#DAFDE9';
    color = '#0BC15A';
    statusName = 'Active';
  } else {
    backgroundColor = '#78787D';
    color = '#FFFFFF';
    statusName = 'Disabled';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {statusName}
      </Label>
    </LabelWrapper>
  );
};

export const getSubscriptionStatusLabel = (status) => {
  let backgroundColor;
  let color;
  let statusName;

  if (status === 'success') {
    backgroundColor = '#DAFDE9';
    color = '#0BC15A';
    statusName = 'Active';
  } else {
    backgroundColor = '#78787D';
    color = '#FFFFFF';
    statusName = 'Disabled';
  }

  return (
    <LabelWrapper withMaxHeight>
      <Label backgroundColor={backgroundColor} color={color}>
        {statusName}
      </Label>
    </LabelWrapper>
  );
};

export const getLogLabel = (status: LOG_STATUS) => {
  let backgroundColor;
  let color;
  
  if (status === LOG_STATUS.SUCCESS) {
    backgroundColor = '#0BC15A';
    color = '#DAFDE9';
  } else {
    backgroundColor = '#FF1F39';
    color = '#FFEBED';
  }

  return (
    <LabelWrapper withMaxHeight>
      <Label backgroundColor={backgroundColor} color={color}>
        {status}
      </Label>
    </LabelWrapper>
  );
};

export const formatTeamName = (team) => {
  if (team?.type === TeamTypes.EXTERNAL) {
    return (
      <TextWithLabelWrapper>
        <TextWithLabel>
          {team?.name}
          <LabelForText>EXT</LabelForText>
        </TextWithLabel>
      </TextWithLabelWrapper>
    );
  }

  return team?.name;
};

export const toolsTeamsDataMapper = (teams) => {
  return teams.map(team => {
    const {
      businessVertical,
      tripPurposes,
      isSelected,
      company,
      status,
      id,
    } = team;

    return {
      tripPurposes: getLabels(tripPurposes, 'label'),
      isSelected,
      teamName: <TeamsItemWrapper>{formatTeamName(team)}</TeamsItemWrapper>,
      businessVertical: businessVertical?.id ? formatBusinessVerticalLabel(businessVertical?.name, businessVertical?.type) : N_A,
      company: <TeamsItemWrapper>{company.name}</TeamsItemWrapper>,
      status: <TeamsItemWrapper>{getTeamStatusLabel(status)}</TeamsItemWrapper>,
      teamId: id,
    };
  });
};

export const getExpensesTypeLabel = (status: TRIPS_EXPENSES_TYPES) => {
  let backgroundColor;
  let color;

  switch (status) {
    case TRIPS_EXPENSES_TYPES.DAILY_ALLOWANCE: {
      backgroundColor = '#275ADB';
      color = '#FFFFFF';
      break;
    }

    case TRIPS_EXPENSES_TYPES.BUSINESS_DINNER:
    case TRIPS_EXPENSES_TYPES.TRANSPORT:
    case TRIPS_EXPENSES_TYPES.OTHER: {
      backgroundColor = '#0BC15A';
      color = '#FFFFFF';
      break;
    }
    default:
      backgroundColor = '#78787D';
      color = '#fffff';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {tripsExpensesStatusNames[status]}
      </Label>
    </LabelWrapper>
  );
};

export const getExpensesStatusLabel = (status: EXPENSES_TYPE) => {
  let backgroundColor;
  let color;

  switch (status) {
    case EXPENSES_TYPE.PENDING: {
      backgroundColor = '#FDF5E2';
      color = '#F5A300';
      break;
    }

    case EXPENSES_TYPE.UNDER_CONSIDERATION: {
      backgroundColor = '#F3EBE2';
      color = '#91673B';
      break;
    }

    case EXPENSES_TYPE.VERIFYING: {
      backgroundColor = '#F0ECFE';
      color = '#5C33CF';
      break;
    }

    case EXPENSES_TYPE.CHECKED: {
      backgroundColor = '#DAFDE9';
      color = '#0BC15A';
      break;
    }

    case EXPENSES_TYPE.TO_BE_PAID: {
      backgroundColor = '#dedede';
      color = '#000000';
      break;
    }

    case EXPENSES_TYPE.REFUNDED: {
      backgroundColor = '#E0F0F5';
      color = '#28697B';
      break;
    }

    default:
      backgroundColor = '#78787D';
      color = '#fffff';
  }

  return (
    <LabelWrapper>
      <Label backgroundColor={backgroundColor} color={color}>
        {tripsExpensesTypeNames[status]}
      </Label>
    </LabelWrapper>
  );
};

export const getAmountAndRate = (costInEUR, rate) => (
  <AmountRateWrapper>
    <NumberCell>
      {/* @ts-ignore */}
      <NumberFormat
        value={costInEUR}
        displayType={'text'}
        thousandSeparator={true}
        fixedDecimalScale
        decimalScale={2}
      />
    </NumberCell>
    <ExpensesRate>{rate}</ExpensesRate>
  </AmountRateWrapper>
);

const formatExpenseTravelerName = (name, travelerStatus) => {
  if(travelerStatus === UserStatuses.Deleted){
    return (
      <UserWrapper>
        <CustomText>
          {name}
          <UserNameLabel backgroundColor={'#FFEBED'} color={'#FF1F39'}>DEL</UserNameLabel>
        </CustomText>
      </UserWrapper>
    );
  }
  return name;
};

export const expensesGroupedDataMapper = (trips, groupBy) => {
  if (trips.length) {
    return trips.map((trip) => {
      const travelerName = trip.user.fullName;
      const travelerStatus = trip.user.status;
      const totalPrice = trip.expensesTotalCost;
      const expenses = trip.expenses;
      const tripId = trip.id;

      return {
        travelerName: formatGroupedColumnInfo({ travelerName, groupBy, travelerStatus }),
        paymentDate: (
          <>
            <GroupedLabel>Trip time</GroupedLabel>
            <GroupedTextAligner><strong>{getTripTime(trip)}</strong></GroupedTextAligner>
          </>
        ),
        description: (
          <>
            <GroupedLabel>Destination</GroupedLabel>
            <GroupedTextAligner><strong>{trip?.destinationLabel}</strong></GroupedTextAligner>
          </>
        ),
        expense: (
          <>
          <GroupedLabel>Purpose</GroupedLabel>
          <GroupedTextAligner>{formatPurpose(trip.purpose, trip.purposeColor)}</GroupedTextAligner>
          </>
        ),
        amount: (
          <>
          <GroupedLabel>Status</GroupedLabel>
          <GroupedTextAligner>{getExpensesStatusLabel(trip.expensesStatus)}</GroupedTextAligner>
          </>
        ),
        currency: !!expenses?.length && (
          <>
            <GroupedLabel>Total amount, EUR</GroupedLabel>
            <GroupedTextAligner><strong>{totalPrice}</strong></GroupedTextAligner>
          </>
        ),
        isSelected: trip.isSelected,
        expenses: expenses.map(expense => {
          const { date, description, type, costInEUR, cost, currencyCode, exchangeRate } = expense;
          return {
            amountAndRate: getAmountAndRate(costInEUR, exchangeRate),
            travelerName: formatExpenseTravelerName(travelerName, travelerStatus),
            paymentDate: formatDateAndTime(date),
            description,
            currency: <EuroLabel>{currencyCode}</EuroLabel>,
            expense: getExpensesTypeLabel(type),
            amount: (
              <NumberCell>
                {/* @ts-ignore */}
                <NumberFormat
                  value={cost}
                  displayType={'text'}
                  thousandSeparator={true}
                  fixedDecimalScale
                  decimalScale={2}
                />
              </NumberCell>
            ),
            original: { ...expense, tripId },
          };
        }),
        tripId,
      };
    });
  }

  return [];
};

export const defineGroupById = (groupBy, groupedTableData, row) => {
  const { user, destinationId, tripDates } = groupedTableData[row.index];
  let groupById;

  switch (groupBy) {
    case 'employer':
      groupById = user.employer.id;
      break;
    case 'team':
      groupById = user.team.id;
      break;
    case 'travelerName':
      groupById = user.id;
      break;
    case 'destinationForReports':
      groupById = destinationId;
      break;
    case 'tripStartDate':
      groupById = tripDates.tripStartDate;
      break;
    case 'paymentDate':
      groupById = tripDates.tripFinishDate;
      break;
    case 'businessVertical':
      groupById = user?.businessVertical?.id;
      break;

    default:
      break;
  }

  return groupById;
};

export const formatPurposes = (purposes) => {
  return (
    <LabelsWrapper>
      {purposes.map((purpose) => (
        <div key={purpose.id}>
          <Label backgroundColor={purpose?.color || 'trunks.100'}>
            {purpose?.label || purpose?.title || purpose || 'Other'}
          </Label>
        </div>
      ))}
    </LabelsWrapper>
  );
};

export const formatHotelsRecommendationsDates = (start, end) => {
  if(!start && !end) return 'N/A';

  return (
    <CustomDatesText>
      {start ? moment(start).format('MMM DD, YYYY') : 'N/A'}
      {' – '}
      {end ? moment(end).format('MMM DD, YYYY') : 'N/A'}
    </CustomDatesText>
  )
};

export const formatLabelForApplyToAll = (applyTo) => {
  const backgroundColor = 'rgba(253, 239, 196, 0.38)';
  const color = '#181B1C';

  return (
    <LabelsWrapper>
      {/* @ts-ignore */}
      <Label backgroundColor={backgroundColor} color={color} styles={{}}>
        APPLY TO ALL {applyTo.toUpperCase()}
      </Label>
    </LabelsWrapper>
  );
};

export const toolsRecommendedHotelsDataMapper = (data) => {
  return data.map(recommendation => {
    const {
      applyToAllPurposes,
      applyToAllTeams,
      applyToAllDates,
      id,
      city,
      hotel,
      purposes,
      startDate,
      endDate,
      company,
      status,
      comment,
    } = recommendation;
    return {
      recommendedHotelId: id,
      city: <><CustomText>{city.name}</CustomText><SubTitle>{city.countryName}</SubTitle></>,
      hotel: hotel.name,
      dates: applyToAllDates
        ? formatLabelForApplyToAll('dates')
        : formatHotelsRecommendationsDates(startDate, endDate),
      purposes: applyToAllPurposes
        ? formatLabelForApplyToAll('purposes')
        : formatPurposes(purposes),
      company: company.name,
      teams: applyToAllTeams
        ? formatLabelForApplyToAll('teams')
        : getLabels(company.teams, 'name'),
      comment: comment || 'N/A',
      status: <TeamsItemWrapper>{getTeamStatusLabel(status)}</TeamsItemWrapper>,
    };
  });
};

export const formatHotelCity = (hotel: Hotel) => {
  return (
    <>
      <CustomText>{hotel.region.name}</CustomText>
      <SubTitle>{hotel.region.countryName}</SubTitle>
    </>
  );
};

export const formatHotelAddress = (hotel: Hotel) => {
  const { latitude, longitude, address } = hotel;
  return (
    <a
      target="_blank"
      href={`https://maps.google.com/?q=${latitude},${longitude}`}
    >
      {address}
    </a>
  );
};

export const formatHotelType = (type: HOTEL_TYPES) => {
  const typeData = hotelTypesOptions.find(({ value }) => value === type);
  return typeData?.label || type;
};

export const formatHotelId = (hotelId: string) => {
  const MAX_DISPLAY_LENGTH = 15;

  if (hotelId.length > MAX_DISPLAY_LENGTH) {
    return `${hotelId.substring(0, MAX_DISPLAY_LENGTH)}...`
  }

  return hotelId;
};

export const toolsCustomHotelsDataMapper = (customHotels: Hotel[]) => {
  return customHotels.map((customHotel: Hotel) => {  
    return {
      formattedHotelId: formatHotelId(customHotel.id),
      customHotelId: customHotel.id,
      checkOutTime: customHotel?.checkOutTime || N_A,
      checkInTime: customHotel?.checkInTime || N_A,
      starRating: customHotel?.starRating ? <StarRating rate={+customHotel.starRating} readOnly /> : N_A,
      address: formatHotelAddress(customHotel),
      email: customHotel?.email || N_A,
      phone: customHotel?.phone || N_A,
      name: customHotel.name,
      type: formatHotelType(customHotel.type),
      city: formatHotelCity(customHotel),
      id: customHotel.id,
    };
  });
};

export const getTripApprovedByTitle = (approvedBy) => {
  return approvedBy?.fullName || 'Approved automatically';
};

export const toolsBusinessVerticalsDataMapper = (data = []) => {
  return data.map(({
    seniorAccountant,
    travelopses,
    accountants,
    employees,
    company,
    ceos,
    name,
    type,
    id,
  }) => {
    return {
      businessVerticalId: id,
      seniorAccountant: seniorAccountant?.id ? seniorAccountant.fullName : N_A,
      accountants: accountants?.length ? getLabels(accountants, 'fullName') : N_A,
      travelops: travelopses?.length ? getLabels(travelopses, 'fullName') : N_A,
      employees,
      company: company?.name,
      type,
      name: formatBusinessVerticalLabel(name, type),
      ceo: ceos?.length ? getLabels(ceos, 'fullName') : N_A,
    };
  });
};

export const getTypesMap = (types = {}) => {
  return Object.keys(types).map(type => ({ title: type, value: types[type] }));
};

export const formatBusinessVerticalLabel = (name, type, isOverview = false) => {
  const businessVerticalLabelType = type === BUSINESS_VERTICAl_TYPES.EXTERNAL ? 
        <BusinessVerticalLabelWrapper
          isOverview={isOverview}
          backgroundColor={'#F5A300'}
          color={'#FDF5E2'}> EXT
        </BusinessVerticalLabelWrapper> : undefined;

  return (
    <>
      <UserWrapper marginRight={'55px'}>
        <CustomText>{name ?? N_A}{businessVerticalLabelType}</CustomText>
      </UserWrapper>
    </>
  )
};

export const formatBusinessVerticalLabels = (items, isOverview = false) => {
  return (
    <>
      {items.map((item) => (
       formatBusinessVerticalLabel(item?.name, item?.type, isOverview)
      ))}
    </>
  );
};
