import { useMemo, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { TextInput, Button, Checkbox } from '@heathmont/moon-core';
import FormField from '../FormField';
import { teamSchema } from '../AddNewTeamDialog/schema';
import { getBoolStatusLabel } from '../../utils/tableHelpers';
import { ITeamsOverviewDrawerEditContent } from './types';
import {
  OverviewBusinessVerticalWrapper,
  OverviewBusinessVerticalName,
  ConfirmDialogBodyWrapper,
  OverviewItemsWrapper,
  OverviewEmployerItem,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
  Wrapper 
} from './styles';
import CustomSelect from '../CustomSelect';
import { teamsNames, TEAMS_TYPES } from '../../enums/TeamsTypes';
import { onCompanyChangeHandler } from '../../pages/Tools/Teams/utils';
import { fetchOptionsForCompany } from '../../store/tools/teams/api';
import Dialog from '../../components/Dialog';
import InfoBlock from '../../components/InfoBlock';
import { TeamTypes } from '../../enums/TeamTypes';
import { 
  filterItemsWithVerticalsByVerticalObjectId,
  filterBusinessVerticalsListByValue,
  filterBusinessVerticalsByType,
} from '../../utils/filters';

const getInitialValues = (data) => ({
  tripPurposes: data?.tripPurposes.map((item) => item.id) ?? [],
  businessVertical: data?.businessVertical?.id ?? null,
  company: data?.company?.id ?? null,
  status: data?.status ?? null,
  name: data?.name ?? '',
  employer: null,
  type: data?.type ?? TeamTypes.EXTERNAL,
});

const statuses = [
  { title: teamsNames[TEAMS_TYPES.DISABLED], value: TEAMS_TYPES.DISABLED },
  { title: teamsNames[TEAMS_TYPES.ACTIVE], value: TEAMS_TYPES.ACTIVE },
];

const TeamsOverviewDrawerEditContent: React.FC<ITeamsOverviewDrawerEditContent> = ({
  companies,
  data,
  setIsEditFormData,
  handleUpdateTeam,
}) => {
  const initialValues = useMemo(() => getInitialValues(data), [data]);
  const [isOpenedWarningAboutChangingBusinessVertical, setOpenedWarningAboutChangingBusinessVertical] = useState(false);
  const [employers, setEmployers] = useState([]);
  const [businessVerticalsByType, setBusinessVerticalsByType] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: teamSchema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: () => {},
  });
  const { touched, values, errors, dirty, setFieldValue, setFieldTouched, resetForm, setValues } = formik;

  const [options, setOptions] = useState<{
    businessVerticals: any[],
    tripPurposes: any[],
  }>({
    businessVerticals: [],
    tripPurposes: [],
  });

  const employersToList = (employers) => {
    return employers?.map(employer => ({ value: employer?.id, title: employer.name }))
  };

  useEffect(() => {
    fetchCompanyOptions();
  }, []);

  useEffect(() => {
    setValues({
      ...values,
    });
    const [selectedVertical] = filterBusinessVerticalsListByValue(businessVerticalsByType, values.businessVertical);
    setEmployers(employersToList(selectedVertical?.employers))
  }, [values?.businessVertical]);


  const fetchCompanyOptions = async () => {
    const options = await fetchOptionsForCompany(initialValues.company)
    setOptions(options);
    setBusinessVerticalsByType(filterBusinessVerticalsByType(options.businessVerticals, data?.type));
  };

  const handleSave = () => {
    if (initialValues.businessVertical !== values?.businessVertical) {
      setOpenedWarningAboutChangingBusinessVertical(true);

      return;
    }

    handleUpdateTeam(data.id, values);
    setIsEditFormData(false);
    resetForm();
  };

  const handleCancel = () => {
    setIsEditFormData(false);
    resetForm();
  };

  const getBusinessVerticalName = (currentBusinessVerticalId, businessVerticals) => {
    const [currentBusinessVertical] = businessVerticals.filter(bv => bv.value === currentBusinessVerticalId);
    return currentBusinessVertical?.title;
  };

  return (
    <Wrapper>
      <FormikProvider value={formik}>
        <Form>
          <OverviewItemsWrapper>
            <OverviewItemName>Team</OverviewItemName>

            <OverviewItem>
              <FormField
                errorPosition={-20}
                inputSize="xlarge"
                component={TextInput}
                fieldName="name"
                fieldId="name"
                errors={errors}
                value={values.name}
                label="Team name"
                type="text"
                onChange={(e) => setFieldValue('name', e.target.value)}
                onBlur={() => setFieldTouched('name')}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Company</OverviewItemName>

            <OverviewItem>
              <CustomSelect
                currentValue={values.company}
                isDisabled={true}
                items={companies}
                onChange={(newCompanyId) => onCompanyChangeHandler({
                  newCompanyId,
                  companyId: values.company,
                  setCompanyOptions: setOptions,
                  setFieldTouched,
                  setFieldValue,
                })}
                onBlur={() => setFieldTouched('company', true, false)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Business vertical</OverviewItemName>

            <OverviewItem>
              <CustomSelect
                currentValue={values.businessVertical}
                isSearchable
                placeholder="Business vertical"
                isError={!!(touched.businessVertical && errors.businessVertical)}
                error={(touched.businessVertical && errors.businessVertical) as string}
                items={businessVerticalsByType}
                onChange={value => { 
                  setFieldTouched('businessVertical', true, false);
                  setFieldValue('businessVertical', value)
                }}
                onBlur={() => setFieldTouched('businessVertical', true, false)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Status</OverviewItemName>

            <OverviewItem>
              <CustomSelect
                currentValue={values.status}
                items={statuses}
                getItemLabel={getBoolStatusLabel}
                onChange={(value) => {
                  setFieldValue('status', value);
                  setFieldTouched('status', true, false)
                }}
                onBlur={() => setFieldTouched('status', true, false)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Only external users</OverviewItemName>
            <OverviewItem>
              <Checkbox checked={data?.type === TeamTypes.EXTERNAL} disabled />
            </OverviewItem>
          </OverviewItemsWrapper>

          {data?.type === TeamTypes.EXTERNAL && (
            <>
              <OverviewItemsWrapper>
                <OverviewItemName>Trip purposes</OverviewItemName>

                <OverviewItem>
                  <CustomSelect
                    currentValue={values.tripPurposes}
                    isSearchable
                    placeholder="Trip purposes"
                    isMulti
                    isError={!!(touched.tripPurposes && errors.tripPurposes)}
                    error={(touched.tripPurposes && errors.tripPurposes) as string}
                    items={options.tripPurposes}
                    onChange={value => { 
                      setFieldValue('tripPurposes', value)
                      setFieldTouched('tripPurposes', true, false);
                    }}
                    onBlur={() => setFieldTouched('tripPurposes', true, false)}
                  />
                </OverviewItem>
              </OverviewItemsWrapper>
            </>
          )}

          <ButtonsWrapper>
            <Button variant="secondary" type="button" onClick={handleCancel}>
              Cancel
            </Button>

            <Button variant="primary" type="button" disabled={!isEmpty(errors) || !dirty} onClick={handleSave}>
              Save
            </Button>
          </ButtonsWrapper>
        </Form>

        {isOpenedWarningAboutChangingBusinessVertical && (
          <Dialog
            submitButtonLabel="Confirm"
            maxWidth={500}
            title="Confirm"
            isSubmitDisabled={!values.employer}
            onSubmit={() => {
              setIsEditFormData(false);
              handleUpdateTeam(data.id, values);
              resetForm();
              setOpenedWarningAboutChangingBusinessVertical(false);
              }}
            onClose={() => setOpenedWarningAboutChangingBusinessVertical(false)}
          >
            <ConfirmDialogBodyWrapper>
              {<InfoBlock text={`You want to change business vertical for the Team who has users. Please, select an employer assigned to a new vertical to migrate current users to.`} />}
              <OverviewBusinessVerticalWrapper>
                <OverviewEmployerItem>
                  <OverviewBusinessVerticalName>{getBusinessVerticalName(values.businessVertical, businessVerticalsByType)}</OverviewBusinessVerticalName>
                  <CustomSelect
                    currentValue={values.employer}
                    isSearchable
                    placeholder="Employer"
                    isClearable
                    items={employers}
                    onChange={(value) => setFieldValue('employer', value)}
                  />
                </OverviewEmployerItem>
              </OverviewBusinessVerticalWrapper>
            </ConfirmDialogBodyWrapper>
          </Dialog>
        )}
      </FormikProvider>
    </Wrapper>
  );
};

export default TeamsOverviewDrawerEditContent;
