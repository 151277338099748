import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Text } from '@heathmont/moon-core';
import { ControlsPlus } from '@heathmont/moon-icons';
import { LIMIT_PER_PAGE as limitPerPage} from '../../../constants';
import TablePagination from '../../../components/TablePagination';
import { Spinner } from '../../../components/Spinner';
import TableContentReciever from '../../../components/TableContentReciever';
import { initialColumns } from './suggestions';
import { useCustomTable } from '../../../hooks/useCustomTable';
import { RowHeight } from '../../Reports/suggestions';
import { DEFAULT_SORTING } from '../../../constants';
import {
  setAllDailyAllowanceTypesCheckboxData,
  setDailyAllowanceTypesCheckboxData,
  updateDailyAllowanceTypeOptions,
  fetchDailyAllowanceTypesOptions,
  deleteDailyAllowanceTypes,
  updateDailyAllowanceType,
  addNewDailyAllowanceType,
  fetchDailyAllowanceTypes,
  setShouldRefreshOff,
} from '../../../store/tools/dailyAllowanceTypes/actions';
import Drawer from '../../../components/Drawer';
import AddNewDailyAllowanceTypeModal from '../../../components/AddNewDailyAllowanceTypeModal';
import { PageHeader, TableWrapper, Title } from '../styles';
import Dialog from '../../../components/Dialog';
import DailyAllowanceOverviewDrawerContent from '../../../components/DailyAllowanceOverviewDrawerContent';
import DownDrawer from '../../../components/DownDrawer';
import { useQuery } from '../../../hooks/useQuery';
import { prepareFilters } from '../../../utils/filters';
import { formatDailyAllowances, formatDeleteDialogText } from './utils';
import { IDailyAllowanceTypes } from './types';

const LIMIT_PER_PAGE = limitPerPage.TOOLS.DAILY_ALLOWANCES;

const DailyAllowanceTypes: React.FC<IDailyAllowanceTypes> = ({
  filters,
}) => {  
  const dispatch = useDispatch();

  const isActiveAllAllowancesCheckboxes = useSelector((state: any) => (
    state.dailyAllowanceTypesReducer.isActiveAllAllowancesCheckboxes
  ));
  const allowancesCheckboxesData = useSelector((state:any) => state.dailyAllowanceTypesReducer.allowancesCheckboxesData)
  const businessVerticalsOptions = useSelector((state: any) => state.dailyAllowanceTypesReducer.options.businessVerticals)
  const dailyAllowancesData = useSelector((state: any) => state.dailyAllowanceTypesReducer.dailyAllowances)
  const currenciesOptions = useSelector((state: any) => state.dailyAllowanceTypesReducer.options.currencies)
  const companiesOptions = useSelector((state: any) => state.dailyAllowanceTypesReducer.options.companies)
  const shouldRefresh = useSelector((state: any) => state.dailyAllowanceTypesReducer.shouldRefresh)
  const isLoadingData = useSelector((state: any) => state.loadingReducer.isLoadingData);
  const totalCount = useSelector((state: any) => state.dailyAllowanceTypesReducer.totalCount)
  
  const [isDeletePurposeModal, setIsDeletePurposeModal] = useState(false);
  const [selectedAllowanceId, setSelectedAllowanceId] = useState(null);
  const [deletableAllowances, setDeletableAllowances] = useState([]);
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);
  const [isOverviewOpen,setIsOverviewOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [rowHeight, setRowHeight] = useState(RowHeight.M);
  const [sortBy, setSortBy] = useState(DEFAULT_SORTING.TOOLS.DAILY_ALLOWANCES);

  const selectedAllowance = dailyAllowancesData.find((allowance) => allowance.id === selectedAllowanceId)
  const queryParams = useRef('');
  const totalItems = useRef(0);
  
  const { page, perPage, nextPage, prevPage, setPage } = useCustomTable(totalItems, LIMIT_PER_PAGE);

  const { setQuery } = useQuery();

  const columns = useMemo(() => initialColumns, []);
  
  const queryWithSortBy = useMemo(() => {
    const params: any = {
      filters: prepareFilters(filters),
      perPage,
      page,
    };

    if (sortBy && sortBy.length) {
      params.sort = {
        field: sortBy[0].id,
        order: sortBy[0].desc ? 'desc' : 'asc',
      };
    }
    
    setQuery(params.filters);

    return params;
  }, [perPage, page, sortBy, filters])

  const formattedDailyAllowances = useMemo(
    () => formatDailyAllowances(dailyAllowancesData),
    [dailyAllowancesData]
  );

  const deleteDialogText = useMemo(
    () => formatDeleteDialogText(allowancesCheckboxesData, isActiveAllAllowancesCheckboxes),
    [isActiveAllAllowancesCheckboxes, allowancesCheckboxesData],
  );

  useEffect(() => {
    dispatch(fetchDailyAllowanceTypes(queryWithSortBy));
    dispatch(fetchDailyAllowanceTypesOptions());
   }, []);
 
  useEffect(() => {
    if (shouldRefresh) {
      dispatch(fetchDailyAllowanceTypes(queryWithSortBy))
    }
    return () => {
      dispatch(setShouldRefreshOff());
    }
  }, [shouldRefresh]);

  useEffect(() => {
    setIsOverviewOpen(false);
    dispatch(fetchDailyAllowanceTypes(queryWithSortBy));
  }, [queryWithSortBy]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    totalItems.current = totalCount;
  }, [totalCount]);

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);  

  const handleCloseAddNewPurpose = () => {
    setIsAddModalOpen(false);
  };
    
  const handleAddNewAllowance = async (values) => {
    dispatch(addNewDailyAllowanceType(values))
    setIsAddModalOpen(false);
  };

  const handleUpdateAllowanceType = async (values) => {
    dispatch(updateDailyAllowanceType(selectedAllowance.id, values));
    setIsOverviewOpen(false);
  };

  const handleUpdateOptions = async (values) => {
    const { options } = values;
    dispatch(updateDailyAllowanceTypeOptions({
      allowanceId: selectedAllowance.id,
      options,
    }));
  };

  const handleOpenOverview = (row) => {
    setIsOverviewOpen(true);
    setSelectedAllowanceId(row.original.id);
  };

  const handleOpenRemoveDialog = (checkboxesData) => {
    setDeletableAllowances(checkboxesData.map((allowance) => allowance.allowanceId));
    setIsDeletePurposeModal(true);
  };

  const handleDeleteTableAction = (allowance) => {
    setDeletableAllowances([ allowance.id ]);
    setIsDeletePurposeModal(true);
  };

  const handleRemoveAllowanceType = async (allowances) => {
    await dispatch(deleteDailyAllowanceTypes(allowances));
    dispatch(setDailyAllowanceTypesCheckboxData([]));
    dispatch(setAllDailyAllowanceTypesCheckboxData());
    setIsDeletePurposeModal(false);
  };

  const closeDeletePurposeModal = () => {
    setIsDeletePurposeModal(false);
  };

  const closePurposeOverview = () => {
    setIsOverviewOpen(false);
  };

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const handleSelectAllAllowances = (data, isActive) => {
    dispatch(setAllDailyAllowanceTypesCheckboxData(data, isActive));
  };

  const handleSetAllowanceCheckbox = (data) => {
    dispatch(setDailyAllowanceTypesCheckboxData(data));
  };

  const tableContentRecieverProps = {
    isActiveAllAllowancesCheckboxes,
    isToolsAllowancesPage: true,
    isShowTableFilters,
    withCheckboxRow: true,
    initialColumns: columns,
    checkboxesData: allowancesCheckboxesData,
    limitPerPage: LIMIT_PER_PAGE,
    queryParams: queryParams.current,
    isExpenses: false,
    hoveredRow,
    withHover: true,
    rowHeight,
    sortBy,
    page,
    data: formattedDailyAllowances,
    handleIsOverviewOpen: handleOpenOverview,
    handleSetAllCheckboxs: handleSelectAllAllowances,
    handleRemoveDialog:  !hoveredRow?.original?.default && handleDeleteTableAction,
    toggleTableFilters,
    handleSetCheckbox: handleSetAllowanceCheckbox,
    handleHoveredRow,
    handleSortBy: setSortBy,
  };

  return (
    <>
      <TableWrapper>
        <PageHeader>
          <Title>Daily Allowance Types</Title>
          <Button variant="primary" size="small" onClick={() => setIsAddModalOpen(true)}>
            <ControlsPlus /> Add daily allowance type
          </Button>
        </PageHeader>

        <TablePagination
          isShowTableFilters={isShowTableFilters}
          limitPerPage={LIMIT_PER_PAGE}
          totalItems={totalItems}
          data={formattedDailyAllowances}
          page={page}
          toggleTableFilters={toggleTableFilters}
          changeRowHeight={changeRowHeight}
          refetchData={() => dispatch(fetchDailyAllowanceTypes(queryWithSortBy))}
          nextPage={nextPage}
          prevPage={prevPage}
        />
        {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
      </TableWrapper>

      {isAddModalOpen && (
        <AddNewDailyAllowanceTypeModal 
          businessVerticalsList={businessVerticalsOptions}
          secondButtonLabel="Add"
          currencyList={currenciesOptions}
          companyList={companiesOptions}
          handleCloseDialog={handleCloseAddNewPurpose}
          handleAgree={handleAddNewAllowance}
        />
      )}
      
      {isDeletePurposeModal && (
        <Dialog
          submitButtonLabel="Delete"
          title="Delete"
          data={deletableAllowances}
          onSubmit={handleRemoveAllowanceType}
          onClose={closeDeletePurposeModal}
        >
          <div>
            <Text size={16}>{deleteDialogText}</Text>
          </div>
        </Dialog>
      )}

      {isOverviewOpen && selectedAllowance && (
        <div>
          <Drawer handleClose={closePurposeOverview} title={`${selectedAllowance?.company?.name ? selectedAllowance.company.name : "Default"}`}>
            <DailyAllowanceOverviewDrawerContent
              data={selectedAllowance}
              handleUpdateAllowance={handleUpdateAllowanceType}
              handleUpdateOptions={handleUpdateOptions}
            />
          </Drawer>
        </div>
      )}

      {!!allowancesCheckboxesData.length && (
        <DownDrawer
          data={allowancesCheckboxesData}
          text="allowance"
          handleRemoveDialog={handleOpenRemoveDialog}
          handleSetCheckbox={handleSetAllowanceCheckbox}
        />
      )}
    </>
  );
};

export default DailyAllowanceTypes;
