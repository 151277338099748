import { useState, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Form, FormikProvider, useFormik } from 'formik';
import { get, isEmpty } from 'lodash';
import { GenericEdit } from '@heathmont/moon-icons';
import { TextInput, Button } from '@heathmont/moon-core';
import { checkOnIsEqual } from '../../../utils/commonFunctions';
import DynamicFormFields from '../../../components/DynamicFormFields';
import EllipsisText from '../../../components/EllipsisText';
import FormField from '../../../components/FormField';
import Accordion from '../../../components/Accordion';
import InfoBlock from '../../../components/InfoBlock';
import validationSchema from '../../../utils/validation-schema';
import {
  getInitialLoyaltyNumbersValues,
  userLoyaltyNumbersSchema,
} from './schema';
import { IUserLoyaltyProgramsForm } from './types';
import {
  OverviewItemsWrapper,
  GroupInputWrapper,
  EditButtonWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from '../UserDrawerContent/styles';
import SelectWithExternalDataSource from '../../../components/SelectWithExternalDataSource';
import { fetchAirlines } from './utils';

const UserLoyaltyProgramsForm: React.FC<IUserLoyaltyProgramsForm> = ({
  isCurrentUser,
  data,
  updateUserLoyaltyNumbers,
}) => {
  const [isEditLoyaltyNumbers, setIsEditLoyaltyNumbers] = useState(false);
  const initialLoyaltyNumbersValues = useMemo(() => getInitialLoyaltyNumbersValues(data), [data]);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userLoyaltyNumbersSchema,
    validateOnMount: true,
    initialValues: initialLoyaltyNumbersValues,
    onSubmit: (values): void => {
      updateUserLoyaltyNumbers(data, values, setIsEditLoyaltyNumbers);
    },
  });
  const { touched, errors, values, resetForm, setFieldValue, setFieldTouched } = formik;

  useEffect(() => {
    setIsEditLoyaltyNumbers(false);
    resetForm();
  }, [data.id]);

  return (
    <Accordion title="Loyalty Programs" openByDefault withoutPadding>
      <InfoBlock
        text={`
          Travelopses can provide up to ${validationSchema.USER.LOYALTY_PROGRAMS.max} loyalty numbers for each traveler.
          If you provide an invalid loyalty number system ignores it during the booking process.
        `}
      />

      {!isEditLoyaltyNumbers && !isCurrentUser && (
        <EditButtonWrapper>
          <Button
            variant="ghost"
            onClick={() => setIsEditLoyaltyNumbers(!isEditLoyaltyNumbers)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <FormikProvider value={formik}>
        <Form>
          {
            isEditLoyaltyNumbers ? (
              <DynamicFormFields
                fieldName="loyaltyNumbers"
                touched={touched}
                errors={errors}
                data={values.loyaltyNumbers}
                setFieldValue={setFieldValue}
                getDefaultValue={() => ({ id: uuidv4(), number: '', airline: '' })}
                renderItem={(fieldName, value, index) => (
                  <GroupInputWrapper>
                    <SelectWithExternalDataSource
                      currentValue={value.airline}
                      placeholder='Select airline'
                      position="left"
                      isError={!!(get(touched, [fieldName, index, 'airline']) && get(errors, [fieldName, index, 'airline']))}
                      fetchOptions={(params: any) => fetchAirlines(params)}
                      resetValues={() => (
                        setFieldValue(`${fieldName}.${index}.airline`, undefined, true)
                      )}
                      onChange={(value) => {
                        setFieldValue(`${fieldName}.${index}.airline`, value, true);
                      }}
                      onBlur={() => setFieldTouched(`${fieldName}.${index}.airline`, true, false)}
                    />
                    <FormField
                      placeholder={'Number'}
                      fieldName={`${fieldName}.${index}.number`}
                      inputSize="large"
                      component={TextInput}
                      fieldId={`${fieldName}.${index}.number`}
                      isError={get(touched, [fieldName, index, 'number']) && get(errors, [fieldName, index, 'number'])}
                      value={value.number}
                      type="text"
                      onChange={({ target: { value } }) => setFieldValue(`${fieldName}.${index}.number`, value, true)}
                      onBlur={() => setFieldTouched(`${fieldName}.${index}.number`, true, false)}
                    />
                  </GroupInputWrapper>
                )}
              />
            ) : (
              <>
                {
                  data.loyaltyNumbers.map(loyaltyNumber => (
                    <OverviewItemsWrapper key={loyaltyNumber.airline.id + loyaltyNumber.number}>
                      <OverviewItemName>{loyaltyNumber.airline.title}</OverviewItemName>
                      <OverviewItem>
                        <EllipsisText text={loyaltyNumber.number} />
                      </OverviewItem>
                    </OverviewItemsWrapper>
                  ))
                }
              </>
            )
          }
          {isEditLoyaltyNumbers && (
            <ButtonsWrapper>
              <Button
                variant="secondary"
                type="reset"
                onClick={() => {
                  resetForm();
                  setIsEditLoyaltyNumbers(!isEditLoyaltyNumbers);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                disabled={!isEmpty(errors) ||  checkOnIsEqual(values, initialLoyaltyNumbersValues)}
              >
                Save
              </Button>
            </ButtonsWrapper>
          )}
        </Form>
      </FormikProvider>
    </Accordion>
  );
};

export default UserLoyaltyProgramsForm;
