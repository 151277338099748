import { CryptoWallet } from '../../../enums/CryptoWallet';
import Yup from '../../../utils/validation';

const validateMessages = {
  address: {
    min: 'Wallet address cannot be less than 1 characters',
    max: 'Wallet address cannot be longer than 1000 characters',
    required: 'Wallet address cannot be empty',
  },
  value: {
    required: 'Wallet crypto currency cannot be empty',
  },
};

export const userCryptoWalletsSchema = Yup.object().shape({
  cryptoWallets: Yup.array().of(Yup.object().shape({
    cryptoCurrencyId: Yup
      .number()
      .required(validateMessages.value.required),
    address: Yup
      .string()
      .min(1, validateMessages.address.min)
      .max(1000, validateMessages.address.max)
      .required(validateMessages.address.required)
      .isInLatinCharacters(),
    
  })),
});

export const getInitialCryptoWallets = (cryptoWallets?: CryptoWallet[]) => ({
  cryptoWallets: cryptoWallets?.length
    ? cryptoWallets.map(cryptoWallet => ({
        cryptoCurrencyId: cryptoWallet.cryptoCurrency.id,
        address: cryptoWallet.address,
        id: cryptoWallet.id,
      }))
    : [],
});
