import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { FullScreenRandomBg } from '../../components/FullScreenRandomBgNew';
import { SuccessMessage } from '../../components/SuccessMessage';
import { ErrorMessage } from '../../components/ErrorMessage';
import useIsMobile from '../../hooks/useIsMobile';
import {
  setSignUpUrlValidity,
  setErrorMessage,
  setSignUpError,
  setSignUpData,
  setActiveTab,
  fetchOptions,
} from '../../store/signUp/actions';
import SignUp from './DesktopSignUp';
import MobileSignUp from './MobileSignUp';
import { getInitialValues } from './suggestions';

const SignUpSwitcher: React.FC<any> = (props) => {
  const isMobile = useIsMobile();
  const [checkedTabs, setCheckedTabs] = useState([]);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { search } = useLocation();
  const optionsLoading = useSelector((state: any) => state.signUpReducer.optionsLoading);
  const optionsError = useSelector((state: any) => state.signUpReducer.optionsError);
  const options = useSelector((state: any) => state.signUpReducer.options);
  const activeTab = useSelector((state: any) => state.signUpReducer.activeTab);
  const submitError = useSelector((state: any) => state.signUpReducer.submitError);

  const { code, email, workspaceId, channel, appId } = queryString.parse(search);

  const initialValues = useMemo(() => {
    return getInitialValues({ ...options });
  }, [options]);

  useEffect(() => {
    if (!code) {
      dispatch(setSignUpUrlValidity(false));
      dispatch(setErrorMessage('Url is not valid, please check the link carefully.'));
      return;
    }

    dispatch(fetchOptions(email, code, translate));
  }, []);

  useEffect(() => {
    if (submitError.isError) {
      setTimeout(() => dispatch(setSignUpError(false)), 5000);
    }
  }, [submitError]);

  const handleSubmit = async (values) => {
    const { details, settings, document, security } = values;

    const formData = {
      email: details.email,
      company: details.companyId,
      employer: details.employer,
      businessVertical: `${details.businessVertical}`,
      team: details.team,
      firstname: settings.firstname,
      lastname: settings.lastname,
      passenger_email: settings.email,
      passenger_mobile_number: settings.passengerNumber,
      birthdate: moment(settings.birthDate).format('YYYY-MM-DD'),
      gender: settings.gender,
      title: settings.title,
      document_type: document.type,
      country: document.issuedDocument,
      document_number: document.number,
      document_valid: moment(document.expirationDate).format('YYYY-MM-DD'),
      password: security.password,
      code,
    };

    dispatch(setSignUpData(formData));
  };

  const successSignUp = useSelector((state: any) => state.signUpReducer.successSignUp);
  const errorMessage = useSelector((state: any) => state.signUpReducer.errorMessage);
  const signUpUrlValidity = useSelector((state: any) => state.signUpReducer.signUpUrlValidity);

  const handleBack = () => {
    return dispatch(setActiveTab(activeTab - 1));
  };

  const handleNext = () => {
    dispatch(setActiveTab(activeTab + 1));
    return setCheckedTabs((prev) => [...prev, activeTab]); 
  };

  const renderSignUpPage = () => {
    if (optionsError) {
      return (
        <ErrorMessage
          errorMessage={'Contact the administrator to get the correct link'}
          heading={optionsError}
        />
      );
    }

    if (errorMessage) {
      return (
        <ErrorMessage
          errorMessage={'Contact the administrator to get the correct link'}
          setErrorMessage={setErrorMessage}
          signupUrlValidity={signUpUrlValidity}
          withReturnBtn
        />
      );
    }
  
    if (successSignUp && channel && workspaceId && !appId) {
      return (
        <SuccessMessage
          secLinkText={'Sign in'}
          isExternal
          linkText={'Start booking'}
          secLink={'/login'}
          link={`https://app.slack.com/client/${workspaceId}/${channel}/app`}
        />
      );
    }

    if (successSignUp && workspaceId && appId && !channel) {
      return (
        <SuccessMessage
          isExternal
          linkText={'Connect'}
          heading={'You are successfully registered'}
          message={'To start booking, please connect Your Slack to the platform.'}
          link={`slack://app?team=${workspaceId}&id=${appId}&tab=home`}
        />
      );
    }

    const additionalProps = {
      initialValues,
      checkedTabs,
      options,
      handleSubmit,
      handleBack,
      handleNext,
    };

    return isMobile ? (
      <MobileSignUp {...props} {...additionalProps} />
    ) : (
      <SignUp {...props} {...additionalProps} />
    );
  }

  if (optionsLoading) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      {
        isMobile ? (
          renderSignUpPage()
        ) : (
          <FullScreenRandomBg renderComponent={renderSignUpPage()} />
        ) 
      }
    </>
  );
};

export default SignUpSwitcher;
