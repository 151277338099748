import { connect } from 'react-redux';
import Reports from './Reports.ui';

const mapStateToProps = ({ currentUser }) => {
  return {
    userRole: currentUser?.currentUserData?.role?.name,
  };
};

export default connect(mapStateToProps)(Reports);

