import { useEffect, useState } from 'react';
import { ITeamsOverviewDrawerContent } from './types';
import { OverviewItemsWrapper, OverviewItemName, OverviewItem, EditButtonWrapper } from './styles';
import { getLabels, formatBusinessVerticalLabel } from '../../utils/tableHelpers';
import { GenericEdit, GenericPlus } from '@heathmont/moon-icons';
import { Checkbox, Button } from '@heathmont/moon-core';
import TeamsOverviewDrawerEditContent from '../TeamsOverviewDrawerEditContent';
import { TeamTypes } from '../../enums/TeamTypes';
import { N_A } from '../../utils/commonFunctions';

const TeamsOverviewDrawerContent: React.FC<ITeamsOverviewDrawerContent> = ({
  companies,
  data,
  openGenerateReferralCodeDialog,
  handleUpdateTeam,
  getStatusLabel,
}) => {
  const [isEditFormData, setIsEditFormData] = useState(false);

  useEffect(() => {
    setIsEditFormData(false);
  }, [data.id]);

  return (
    <div>
      {!isEditFormData && (
        <EditButtonWrapper>
          <Button iconLeft={<GenericEdit fontSize="1.2rem" />} variant="ghost" onClick={() => setIsEditFormData(!isEditFormData)}>
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <OverviewItemsWrapper>
        <OverviewItemName>ID</OverviewItemName>
        <OverviewItem>{data.id}</OverviewItem>
      </OverviewItemsWrapper>

      {!isEditFormData ? (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>Team</OverviewItemName>
            <OverviewItem>{data?.name ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Company</OverviewItemName>
            <OverviewItem>{data.company?.name ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Business Vertical</OverviewItemName>
            <OverviewItem>{data?.businessVertical?.id ? formatBusinessVerticalLabel(data?.businessVertical?.name, data?.businessVertical?.type, true) : N_A }</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Status</OverviewItemName>
            <OverviewItem>{getStatusLabel(data?.status)}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Only external users</OverviewItemName>
            <OverviewItem>
              <Checkbox checked={data?.type === TeamTypes.EXTERNAL} disabled />
            </OverviewItem>
          </OverviewItemsWrapper>

          {
            data?.type === TeamTypes.EXTERNAL && (
              <>
                <OverviewItemsWrapper>
                  <OverviewItemName>Trip purposes</OverviewItemName>
                  <OverviewItem>
                    {data.tripPurposes?.length ? getLabels(data.tripPurposes, 'label') : N_A}
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <Button
                    variant="secondary"
                    iconLeft={<GenericPlus fontSize="1.2rem" />}
                    onClick={openGenerateReferralCodeDialog}
                  >
                    Generate referral code
                  </Button>
                </OverviewItemsWrapper>
              </>
            )
          }
        </>
      ) : (
        <TeamsOverviewDrawerEditContent
          companies={companies}
          data={data}
          setIsEditFormData={setIsEditFormData}
          handleUpdateTeam={handleUpdateTeam}
        />
      )}
    </div>
  );
};

export default TeamsOverviewDrawerContent;
