import styled from 'styled-components';

export const OverviewItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const OverviewItemName = styled.span`
  color: #78787d;
  font-weight: 400;
  font-size: 14px;
  padding-right: 5px;
  line-height: 24px;
  max-width: 160px;
  width: 100%;
`;

export const OverviewItem = styled.span`
  font-weight: ${({ isBool }) => (isBool ? 600 : 400)};
  font-size: 14px;
  line-height: 24px;
  color: #181b1c;
  max-width: 190px;
  width: 100%;
  text-align: start;

  ${({ withHover }) => withHover && `
    cursor: pointer;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & button {
    margin-left: 8px;
  }
`;
export const OptionsBottomElement = styled.div`
  height: 15px;
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & span {
    font-size: 16px;
  }
`;

export const GroupInputWrapper = styled.div`
  display: flex;
  justify-items: center;
  gap: 5px;

  & > * {
    width: calc(50% - 2.5px);
  }
`;
