import Yup from '../../../utils/validation';
import validationSchema from '../../../utils/validation-schema';
import {
  getAssistantsWithTheSameUserBusinessVertical,
  isTheSameBusinessVerticalWithEmployer,
  isTheSameBusinessVerticalWithManager,
  isTheSameBusinessVerticalWithTeam,
} from './utils';

const validateMessages = {
  firstName: {
    required: 'First name cannot be empty',
    min: `First name cannot be less than ${validationSchema.USER.FIRST_NAME.min} characters`,
    max: `First name cannot be longer than ${validationSchema.USER.FIRST_NAME.max} characters`,
  },
  lastName: {
    required: 'Last name cannot be empty',
    min: `Last name cannot be less than ${validationSchema.USER.LAST_NAME.min} characters`,
    max: `Last name cannot be longer than ${validationSchema.USER.LAST_NAME.max} characters`,
  },
  email: {
    email: 'Email must be a valid email', 
    required: 'Email can not be empty',  
  },
  businessVerticalId:  {
    required: 'Business vertical cannot be empty',
  },
  teamId: {
    required: 'Team cannot be empty',
  },
  employerId: {
    required: 'Employer cannot be empty',
  },
  roleId: {
    required: 'Role cannot be empty',
  },
  employmentType: {
    required: 'Employment type cannot be empty',
  },
  assistants: {
    max: `Max number of assistants is ${validationSchema.USER.ASSISTANTS.max}.`,
  },
  balancedId: {
    max: `Balanced ID cannot be longer than ${validationSchema.USER.BALANCED_ID.max} characters`,
  },
  jiraId: {
    min: `Jira ID cannot be less than ${validationSchema.USER.JIRA_ID.min} characters`,
    max: `Jira ID cannot be longer than ${validationSchema.USER.JIRA_ID.max} characters`,
  },
  comment: {
    max: `Comment cannot be longer than ${validationSchema.USER.COMMENT.max} characters`,
  },
};

export const userOverviewSchema = Yup.object().shape({
  firstname: Yup
    .string()
    .min(validationSchema.USER.FIRST_NAME.min, validateMessages.firstName.min)
    .max(validationSchema.USER.FIRST_NAME.max, validateMessages.firstName.max)
    .isInLatinCharacters()
    .withoutSpaces()
    .required(validateMessages.firstName.required),
  lastname: Yup
    .string()
    .min(validationSchema.USER.LAST_NAME.min, validateMessages.lastName.min)
    .max(validationSchema.USER.LAST_NAME.max, validateMessages.lastName.max)
    .withoutSpaces()
    .isInLatinCharacters()
    .required(validateMessages.lastName.required),
  businessVerticalId: Yup
    .number()
    .min(1)
    .typeError(validateMessages.businessVerticalId.required)
    .required(validateMessages.businessVerticalId.required),
  teamId: Yup
    .number()
    .min(1)
    .typeError(validateMessages.teamId.required)
    .required(validateMessages.teamId.required),
  employerId: Yup
    .number()
    .min(1)
    .typeError(validateMessages.employerId.required)
    .required(validateMessages.employerId.required),
  role: Yup
    .number()
    .min(1)
    .typeError(validateMessages.roleId.required)
    .required(validateMessages.roleId.required),
  employmentType: Yup
    .number()
    .min(1)
    .typeError(validateMessages.employmentType.required)
    .required(validateMessages.employmentType.required),
  assistants: Yup
    .array()
    .of(Yup.number())
    .max(validationSchema.USER.ASSISTANTS.max, validateMessages.assistants.max),
  jiraId: Yup
    .string()
    .min(validationSchema.USER.JIRA_ID.min, validateMessages.jiraId.min)
    .max(validationSchema.USER.JIRA_ID.max, validateMessages.jiraId.max)
    .withoutSpaces(),
  balancedId: Yup
    .string()
    .max(validationSchema.USER.BALANCED_ID.max, validateMessages.balancedId.max)
    .withoutSpaces(),
  comment: Yup
    .string()
    .max(validationSchema.USER.COMMENT.max, validateMessages.comment.max),
});

export const getInitialFormValues = (data?: any) => ({
  isSkippedManagerNotifications: !!data?.isSkippedManagerNotifications,
  isSkippedManagerApproval: !!data?.isSkippedManagerApproval,
  businessVerticalId: data?.businessVertical?.id,
  employmentType: data?.employmentType?.id,
  passengerId: data?.passengerId ?? '',
  employerId: isTheSameBusinessVerticalWithEmployer(data?.businessVertical, data?.employer) ? data?.employer?.id : null,
  assistants: getAssistantsWithTheSameUserBusinessVertical(data?.businessVertical, data?.assistants),
  balancedId: data?.balancedId ?? '',
  firstname: data?.firstname ?? '',
  lastname: data?.lastname ?? '',
  manager: isTheSameBusinessVerticalWithManager(data?.businessVertical, data?.manager) ? data?.manager?.id : null,
  comment: data?.comment ?? '',
  teamId: isTheSameBusinessVerticalWithTeam(data?.businessVertical, data?.team) ? data?.team?.id : undefined,
  userId: data?.id ?? '',
  jiraId: data?.jiraId ?? '',
  role: data?.role?.id,
});
