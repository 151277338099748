import { useEffect, useState } from 'react';
import { GenericEdit } from '@heathmont/moon-icons';
import { Button, Checkbox } from '@heathmont/moon-core';
import { IDailyAllowanceOverview } from './types';
import {
  OverviewItemsWrapper,
  EditButtonWrapper,
  OverviewItemName,
  OverviewItem,
} from './styles';
import Accordion from '../Accordion';
import InfoBlock from '../InfoBlock';
import DailyAllowanceCustomRules from '../DailyAllowanceCustomRules';
import DailyAllowanceTypeOptionsForm from '../DailyAllowanceTypeOptionsForm';
import DailyAllowanceTypeForm from '../DailyAllowanceTypeForm';
import {
  formatBusinessVerticalName,
  getDefaultTypeWarningText,
  formatCompanyName,
} from './utils';

const DailyAllowanceOverviewDrawerContent: React.FC<IDailyAllowanceOverview> = ({
  data,
  handleUpdateAllowance,
  handleUpdateOptions,
}) => {
  const [isEditFormData, setIsEditFormData] = useState(false);

  useEffect(() => {
    setIsEditFormData(false);
  }, [data.id]);

  return (
    <div>
      {!!data.isDefault && <InfoBlock text={getDefaultTypeWarningText()} />}

      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
            onClick={() => setIsEditFormData(!isEditFormData)}
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      {!isEditFormData ? (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>ID</OverviewItemName>
            <OverviewItem>{data.id}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Company</OverviewItemName>
            <OverviewItem>{formatCompanyName(data)}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Apply to all companies</OverviewItemName>
            <OverviewItem>
              <Checkbox checked={!data.company.id} disabled />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Business Vertical</OverviewItemName>
            <OverviewItem>{formatBusinessVerticalName(data)}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Apply to all business verticals</OverviewItemName>
            <OverviewItem>
              <Checkbox checked={!data.businessVertical.id} disabled />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Currency</OverviewItemName>
            <OverviewItem>{data.currency.code.toUpperCase()}</OverviewItem>
          </OverviewItemsWrapper>
        </>
      ) : (
        <DailyAllowanceTypeForm
          data={data}
          handleUpdateAllowance={handleUpdateAllowance}
          setIsEditFormData={setIsEditFormData}
        />
      )}
    
      <DailyAllowanceTypeOptionsForm
        data={data}
        handleUpdateOptions={handleUpdateOptions}
      />
    
      {!data.isDefault && (
        <Accordion title="Custom Rules" openByDefault withoutPadding>
          <DailyAllowanceCustomRules customRules={data.customRules} allowanceId={data.id}/>
        </Accordion>
      )}
    </div>
  );
};

export default DailyAllowanceOverviewDrawerContent;
