import { useState } from 'react';
import { IReportFilters } from './types';
import { RadioDateGroup } from '../RadioDateGroup';
import { CheckboxGroup } from '../CheckboxGroup';
import { RadioGroup } from '../RadioGroup';
import SearchField from '../SearchField';
import { TableFilters } from '../TableFilters';
import { REPORT_GROUP_BY_FIELDS } from '../../constants';
import { hasAccessToExternalUsers } from '../../utils/roles';

const ReportFilters: React.FC<IReportFilters> = ({
  userRole,
  groupBy,
  filters,
  options,
  toggleFilter,
  setGroupBy,
  setFilter,
}) => {
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [openDatePickerField, setOpenDatePickerField] = useState(null);

  const handleDatePicketOpen = (field) => {
    return (isOpen) => {
      setOpenDatePickerField(field);
      setIsOpenDatePicker(isOpen);
    };
  };

  return (
    <TableFilters
      isOpenDatePicker={isOpenDatePicker}
      searchField={
        <SearchField
          placeholder="Search for user, city, TTR link"
          searchValue={filters.search}
          handleChange={(value) => setFilter('search', value)}
        />
      }
      filters={[
        ...(hasAccessToExternalUsers(userRole) ? ([{
          title: 'Type of traveler',
          openByDefault: true,
          component: (
            <RadioGroup
              selectedValue={filters?.typeOfTraveler}
              data={options?.typeOfTravelers}
              name="typeOfTraveler"
              onSelect={(value) => setFilter('typeOfTraveler', value)}
            />
          ),
        }]) : []),
        {
          title: 'Payment date',
          openByDefault: true,
          component: (
            <RadioDateGroup
              isOpenDatePicker={isOpenDatePicker && openDatePickerField === 'paymentDate'}
              tripTime={filters.paymentDate}
              range={filters.paymentDateRange}
              data={[]}
              name="paymentDate"
              setIsOpenDatePicker={handleDatePicketOpen('paymentDate')}
              handleDateRange={(value) => setFilter('paymentDateRange', value)}
              handleTripDate={(value) => setFilter('paymentDate', value)}
            />
          ),
        },
        {
          title: 'Trip start time',
          openByDefault: true,
          component: (
            <RadioDateGroup
              isOpenDatePicker={isOpenDatePicker && openDatePickerField === 'tripStartTime'}
              tripTime={filters.tripStartTime}
              range={filters.tripStartTimeRange}
              data={options.relativeDates}
              name="tripStartTime"
              setIsOpenDatePicker={handleDatePicketOpen('tripStartTime')}
              handleDateRange={(value) => setFilter('tripStartTimeRange', value)}
              handleTripDate={(value) => setFilter('tripStartTime', value)}
            />
          ),
        },
        {
          title: 'Trip end time',
          openByDefault: true,
          component: (
            <RadioDateGroup
              isOpenDatePicker={isOpenDatePicker && openDatePickerField === 'tripEndTime'}
              tripTime={filters.tripEndTime}
              range={filters.tripEndTimeRange}
              data={options.relativeDates}
              name="tripEndTime"
              setIsOpenDatePicker={handleDatePicketOpen('tripEndTime')}
              handleDateRange={(value) => setFilter('tripEndTimeRange', value)}
              handleTripDate={(value) => setFilter('tripEndTime', value)}
            />
          ),
        },
        {
          title: 'Group by',
          openByDefault: true,
          component: (
            <RadioGroup
              withAllOption={false}
              selectedValue={groupBy}
              data={REPORT_GROUP_BY_FIELDS}
              name="groupBy"
              onSelect={(value) => setGroupBy(value)}
            />
          ),
        },
        {
          title: 'Type',
          openByDefault: true,
          component: (
            <CheckboxGroup
              selectedValues={filters?.tripTypes}
              data={options?.tripTypes}
              onSelect={(value) => toggleFilter('tripTypes', value)}
            />
          ),
        },
        ...(options?.businessVerticals?.length > 1 ? ([{
          title: `Business vertical • ${options?.businessVerticals?.length}`,
          openByDefault: true,
          component: (
            <CheckboxGroup
              selectedValues={filters?.businessVerticals}
              data={options?.businessVerticals}
              onSelect={(value) => toggleFilter('businessVerticals', value)}
            />
          ),
        }]) : []),
        {
          title: `Employer • ${options.employers.length} `,
          openByDefault: true,
          component: (
            <CheckboxGroup
              selectedValues={filters.employers}
              data={options.employers}
              onSelect={(value) => toggleFilter('employers', value)}
            />
          ),
        },
        {
          title: `Team • ${options.teams.length} `,
          openByDefault: true,
          component: (
            <CheckboxGroup
              selectedValues={filters.teams}
              data={options.teams}
              onSelect={(value) => toggleFilter('teams', value)}
            />
          ),
        },
        {
          title: `Purpose • ${options.purposes.length}`,
          openByDefault: true,
          component: (
            <CheckboxGroup
              selectedValues={filters.purposes}
              data={options.purposes}
              onSelect={(value) => toggleFilter('purposes', value)}
            />
          ),
        },
      ]}
    />
  );
};

export default ReportFilters;
