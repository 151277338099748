export enum UserPreventDeletionReasons {
  HAS_PENDING_TRIPS = 'pendingTrips',
  HAS_APPROVED_TRIPS = 'approvedTrips',
  HAS_PENDING_EXPENSES = 'pendingExpenses',
  HAS_UNDER_CONSIDERATION_EXPENSES = 'underConsiderationExpenses',
  HAS_VERIFYING_EXPENSES = 'verifyingExpenses',
  HAS_CHECKED_EXPENSES = 'checkedExpenses',
  HAS_TO_BE_PAID_EXPENSES = 'toBePaidExpenses',
  IS_USER_ONLY_ONE_TRAVELOPS_FOR_SOME_VERTICAL = 'verticalsWithOneTravelops',
  IS_USER_ONLY_ONE_ACCOUNTANT_FOR_SOME_VERTICAL = 'verticalsWithOneAccountant',
  IS_USER_SENIOR_ACCOUNTANT_FOR_SOME_VERTICAL = 'verticalsWithUserAsSeniorAccountant',
  IS_USER_ONLY_ONE_CEO_FOR_SOME_VERTICAL = 'verticalsWithOneCeo',
  IS_USER_ONLY_ONE_TRAVELOPS_FOR_SOME_TEAM = 'companyTeamsWithOneTravelops',
  IS_LAST_DEFAULT_COMPANY_TRAVELOPS = 'isLastDefaultCompanyTravelops',
  IS_LAST_DEFAULT_COMPANY_ACCOUNTANT = 'isLastDefaultCompanyAccountant',
  IS_LAST_DEFAULT_COMPANY_CEO = 'isLastDefaultCompanyCeo',
  IS_SENIOR_ACCOUNTANT = 'isSeniorAccountant',
  IS_TRAVEL_MANAGER = 'isTravelManager',
};
