import request from '../../../models/request';

const formatDestinations = (cities) => {
  return cities.map((city) => ({
    title: `${city.name} (${city.country})`,
    value: city.id,
  }));
};

export const fetchCities = async (params: { search?: string, id?: string | number }) => {
  try {
    const { data } = await request.get(`locations/cities`, { params });
    return formatDestinations(data.airports || []);
  } catch (err) {
    return [];
  }
};

const getFormattedHotels = ({hotels}) => {
  return hotels.map((hotel) => ({
    title: `${hotel.name}`,
    value: hotel.id,
  }));
};

export const fetchHotels = async (params: { search?: string, id?: string | number }, regionId: number) => {
  try {
    const { data } = await request.get('hotels', { params: { ...params, regionId } });
    return getFormattedHotels(data || []);
  } catch(err) {
    return [];
  }
};
