import React from 'react';
import { TextInputGroup } from '@heathmont/moon-select';
import FormField from '../../FormField';
import { ITravelDocumentTab } from './types';
import { ErrorMessage, ErrorMessageWrapper } from '../../../pages/SignUp/styles';
import { DoubleFieldWrapper } from '../styles';
import { changeFieldId } from '../../../pages/SignUp/suggestions';

const TravelDocumentTab: React.FC<ITravelDocumentTab> = ({
  options,
  touched,
  values,
  errors,
  setFieldValue,
  handleBlur,
}) => {
  const { type, issuedDocument, number, expirationDate } = values.document;

  const isErrorDocumentType = !!errors?.document?.type && touched?.type;
  const isErrorIssuedDocument = !!errors?.document?.issuedDocument && touched?.issuedDocument;
  const isErrorPassportNumber = !!errors?.document?.number && touched?.passportNumber;
  const isErrorExpirationDate = !!errors?.document?.expirationDate && touched?.passportExpirationDate;

  const documentTypeField = {
    placeholderSlot: 'Select document type',
    menuPlacement: 'bottom',
    isSearchable: true,
    isDisabled: options?.documentType?.isEditable,
    inputSize: 'xlarge',
    isError: isErrorDocumentType,
    options: options.documentType.options ?? [],
    value: (options.documentType.options ?? []).find((item) => +item.value === type),
    label: 'Document type',
    type: 'select',
    onChange: ({ value }) => setFieldValue('document.type', +value),
    onBlur: handleBlur,
    ref: (ref) => ref && changeFieldId(ref, 'type'),
  };

  const documentIssuedCountryField = {
    placeholderSlot: 'Select country issued document',
    menuPlacement: 'bottom',
    isSearchable: true,
    isDisabled: options?.country?.isEditable,
    inputSize: 'xlarge',
    isError: isErrorIssuedDocument,
    options: options.country?.options ?? [],
    value: (options.country?.options ?? []).find((item) => +item.value === issuedDocument),
    label: 'Country issued document',
    type: 'select',
    onChange: ({ value }) => setFieldValue('document.issuedDocument', +value),
    onBlur: handleBlur, 
    ref: (ref) => ref && changeFieldId(ref, 'issuedDocument'),
  };

  const passportNumberField = {
    dateFormat: 'dd MMM yyyy',
    inputSize: 'xlarge',
    disabled: options?.documentNumber?.isEditable,
    isError: isErrorPassportNumber,
    label: 'Passport number',
    value: number,
    type: 'text',
    id: 'passportNumber',
    onChange: ({ target: { value } }) => setFieldValue('document.number', value),
    onBlur: handleBlur,
  };

  const expirationDateField = {
    inputSize: 'xlarge',
    disabled: options?.documentValidTill?.isEditable,
    isError: isErrorExpirationDate,
    value: expirationDate,
    label: 'Passport expiration date',
    type: 'date',
    id: 'passportExpirationDate',
    onChange: ({ target: { value } }) => setFieldValue('document.expirationDate', value),
    onBlur: handleBlur,
  };

  return (
    <>
      <DoubleFieldWrapper margin="0 0 24px 0">
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          type="text"
          inputProps={{
            input1: documentTypeField,
            input2: documentIssuedCountryField,
          }}
        />

        {isErrorDocumentType && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.document?.type}</ErrorMessage>
          </ErrorMessageWrapper>
        )}

        {isErrorIssuedDocument && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.document?.issuedDocument}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          type="text"
          inputProps={{
            input1: passportNumberField,
            input2: expirationDateField,
          }}
        />

        {isErrorPassportNumber && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.document?.number}</ErrorMessage>
          </ErrorMessageWrapper>
        )}

        {isErrorExpirationDate && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.document?.expirationDate}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>
    </>
  );
};

export default TravelDocumentTab;
