import { useMemo } from 'react';
import { ArrowsRefreshRound, MailSend } from '@heathmont/moon-icons-tw';
import { Button } from '@heathmont/moon-core';
import { IUserDrawerContent } from './types';
import { UserStatuses } from '../../../enums/UserStatuses';
import UserPassengerDocumentForm from '../UserPassengerDocumentForm';
import UserLoyaltyProgramsForm from '../UserLoyaltyProgramsForm';
import UserFavoriteHotelsForm from '../UserFavoriteHotelsForm';
import UserCryptoWalletsForm from '../UserCryptoWalletsForm';
import UserPassengerForm from '../UserPassengerForm';
import UserInfoBlocks from '../UserInfoBlocks';
import UserSettingsForm from '../UserSettingsForm';

const UserDrawerContent: React.FC<IUserDrawerContent> = ({
  availableForCryptoWalletCurrencies,
  isResendActivationLinkLoading,
  travelDocumentTypes,
  businessVerticals,
  companyEmployees,
  employmentTypes,
  currentUserData,
  employers,
  countries,
  managers,
  genders,
  titles,
  teams,
  roles,
  data,
  handleResendActivationLink,
  updateUserLoyaltyNumbers,
  updateUserFavoriteHotels,
  updateUserCryptoWallets,
  handleSyncUpdates,
  updatePassenger,
  updateDocument,
  updateDetails,
  inviteUser,
}) => {
  const isUserUnregistered = useMemo(() => data?.status === UserStatuses.Unregistered, [data]);
  const isUserRegistered = useMemo(() => data?.status === UserStatuses.Registered, [data]);
  const isUserExternal = useMemo(() => data?.status === UserStatuses.External, [data]);
  const canSyncUpdates = useMemo(() => isUserRegistered, [isUserRegistered]);
  const canSendActivationLink = useMemo(() => isUserExternal && !data?.activatedAt, [isUserExternal, data?.activatedAt]);
  const isCurrentUser = useMemo(() => currentUserData?.user?.id === data?.id, [currentUserData, data]);

  return (
    <div>
      <UserInfoBlocks
        isUserUnregistered={isUserUnregistered}
        isUserExternal={isUserExternal}
        data={data}
        inviteUser={inviteUser}
      />

      <UserSettingsForm
        isBalancedActiveForUserCompany={!!currentUserData?.company?.balancedStatus}
        businessVerticals={businessVerticals}
        companyEmployees={companyEmployees}
        isUserRegistered={isUserRegistered}
        employmentTypes={employmentTypes}
        isUserExternal={isUserExternal}
        isCurrentUser={isCurrentUser}
        employers={employers}
        managers={managers}
        teams={teams}
        roles={roles}
        data={data}
        updateDetails={updateDetails}
      />

      {canSyncUpdates && (
        <Button variant="ghost" type="button" onClick={() => handleSyncUpdates(data?.id)}>
          <ArrowsRefreshRound />
          Sync updates
        </Button>
      )}

      {canSendActivationLink && (
        <Button
          disabled={isResendActivationLinkLoading}
          variant="ghost"
          type="button" onClick={() => handleResendActivationLink(data?.id)}
        >
          <MailSend />
          Resend activation link
        </Button>
      )}

      {(isUserRegistered || isUserExternal) && (
        <>
          

          {!!data?.passenger?.id && (
            <>
              <UserPassengerForm
                isUserRegistered={isUserRegistered}
                isCurrentUser={isCurrentUser}
                titles={titles}
                genders={genders}
                data={data}
                updatePassenger={updatePassenger}
              />

              {!!data?.userDocument?.number && (
                <UserPassengerDocumentForm
                  travelDocumentTypes={travelDocumentTypes}
                  isCurrentUser={isCurrentUser}
                  countries={countries}
                  data={data}
                  updateDocument={updateDocument}         
                />
              )}
            </>
          )}

          <UserLoyaltyProgramsForm
            isCurrentUser={isCurrentUser}
            data={data}
            updateUserLoyaltyNumbers={updateUserLoyaltyNumbers}
          />

          {isUserRegistered && (
            <>
              <UserFavoriteHotelsForm
                isCurrentUser={isCurrentUser}
                data={data}
                updateUserFavoriteHotels={updateUserFavoriteHotels}
              />

              <UserCryptoWalletsForm
                availableForCryptoWalletCurrencies={availableForCryptoWalletCurrencies}
                isCurrentUser={isCurrentUser}
                data={data}
                updateUserCryptoWallets={updateUserCryptoWallets}
              />
            </>            
          )}
        </>
      )}
    </div>
  );
};

export default UserDrawerContent;
