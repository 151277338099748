import { useState, useMemo, useEffect } from 'react';
import { Button, TextInput } from '@heathmont/moon-core';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { GenericEdit } from '@heathmont/moon-icons';
import { ArrowsRefreshRound } from '@heathmont/moon-icons-tw';
import { getBoolStatusLabel } from '../../utils/tableHelpers';
import { schema } from './schema';
import FormField from '../FormField';
import Accordion from '../Accordion';
import CustomSelect from '../CustomSelect';
import { Log } from '../Log';
import { actionNames, ACTION_TYPES } from '../../enums/ActionTypes';
import CronParser from '../CronParser';
import { IActionAndNotificationOverviewDrawerContent } from './types';
import {
  OverviewItemsWrapper,
  EditButtonWrapper,
  OverviewItemName,
  NotificationLogs,
  ButtonsWrapper,
  ActionsWrapper,
  OverviewItem,
} from './styles';

const getInitialValues = (data) => ({
  timeAndFrequency: data?.timeAndFrequency ?? '',
  description: data?.description ?? '',
  name: data?.name ?? '',
});

const statuses = [
  { title: actionNames[ACTION_TYPES.DISABLED], value: ACTION_TYPES.DISABLED },
  { title: actionNames[ACTION_TYPES.ACTIVE], value: ACTION_TYPES.ACTIVE },
];

const ActionAndNotificationOverviewDrawerContent: React.FC<IActionAndNotificationOverviewDrawerContent> = ({
  data,
  logs,
  handleUpdateActionAndNotification,
  handleStartImmediately,
  updateActionStatus
}) => {
  const [isEditFormData, setIsEditFormData] = useState(false);

  const initialValues = useMemo(() => getInitialValues(data), [data]);

  useEffect(() => {
    setIsEditFormData(false);
  }, [data]);

  return (
    <div>
      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            onClick={() => setIsEditFormData(!isEditFormData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      {!isEditFormData ? (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>Name</OverviewItemName>
            <OverviewItem>{data?.name ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Time and Frequency</OverviewItemName>

            <OverviewItem>{data?.timeAndFrequency ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Status</OverviewItemName>
            <OverviewItem>
              <CustomSelect
                currentValue={data?.status}
                items={statuses}
                getItemLabel={getBoolStatusLabel}
                onChange={(value) => updateActionStatus(data?.id, value)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Description</OverviewItemName>

            <OverviewItem>{data?.description ?? ''}</OverviewItem>
          </OverviewItemsWrapper>
        </>
      ) : (
        <Formik
          isInitialValid
          validateOnBlur={true}
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          {({ errors, values, resetForm, setFieldValue, touched, handleBlur, dirty }: any): JSX.Element => {
            return (
              <Form>
                <OverviewItemsWrapper>
                  <OverviewItemName>Name</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      value={values.name}
                      fieldId="name"
                      fieldName="name"
                      component={TextInput}
                      type="text"
                      inputSize="xlarge"
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.name && errors?.name}
                      errors={touched?.name && errors}
                      errorPosition={-20}
                      label="Name"
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Time and Frequency</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      value={values.timeAndFrequency}
                      fieldId="timeAndFrequency"
                      fieldName="timeAndFrequency"
                      component={TextInput}
                      type="text"
                      inputSize="xlarge"
                      onChange={(e) => setFieldValue('timeAndFrequency', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.timeAndFrequency && errors?.timeAndFrequency}
                      errors={touched?.timeAndFrequency && errors}
                      errorPosition={-20}
                      label="Time and Frequency"
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <CronParser cronExpression={values.timeAndFrequency} />

                <OverviewItemsWrapper>
                  <OverviewItemName>Status</OverviewItemName>

                  <OverviewItem>{getBoolStatusLabel(data?.status)}</OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Description</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      value={values.description}
                      fieldId="description"
                      fieldName="description"
                      component={TextInput}
                      type="text"
                      inputSize="xlarge"
                      onChange={(e) => setFieldValue('description', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.description && errors?.description}
                      errors={touched?.description && errors}
                      errorPosition={-20}
                      label="Description"
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <ButtonsWrapper>
                  <Button
                    onClick={() => {
                      resetForm();
                      setIsEditFormData(false);
                    }}
                    variant="secondary"
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    disabled={!isEmpty(errors) || !dirty}
                    onClick={() => {
                      handleUpdateActionAndNotification(data.id, values);
                      setIsEditFormData(false);
                      resetForm();
                    }}
                  >
                    Save
                  </Button>
                </ButtonsWrapper>
              </Form>
            );
          }}
        </Formik>
      )}

      {
        !!data.canStartImmediately && (
          <ActionsWrapper>
            <Button
              iconLeft={<ArrowsRefreshRound fontSize="1.2rem" />}
              variant="ghost"
              onClick={() => handleStartImmediately(data)}
            >
              Start immediately
            </Button>
          </ActionsWrapper>  
        )
      } 

      {!!logs.length && (
        <Accordion title="Logs" openByDefault withoutPadding>
          <NotificationLogs>
            {logs.map((item) => (
              <Log
                key={item.id}
                createdAt={item.createdAt}
                status={item.status}
                text={item.text}
              />
            ))}
          </NotificationLogs>
        </Accordion>
      )}
    </div>
  );
};

export default ActionAndNotificationOverviewDrawerContent;
