import request from '../../../models/request';
import { getErrorStatusMessage } from '../../../utils/commonFunctions';
import { setIsLoadingData } from '../../spinner/actions';
import {
  SET_ALL_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA,
  SET_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA,
  UPDATE_DAILY_ALLOWANCE_TYPE_OPTIONS,
  SET_DAILY_ALLOWANCE_TYPES_OPTIONS,
  UPDATE_DAILY_ALLOWANCE_TYPE,
  SET_DAILY_ALLOWANCE_TYPES,
  ADD_DAILY_ALLOWANCE_TYPE,
  SET_SHOULD_REFRESH_OFF,
  SET_SHOULD_REFRESH_ON,
} from './actionTypes';

export const setShouldRefreshOn = () => ({
  type: SET_SHOULD_REFRESH_ON,
});

export const setShouldRefreshOff = () => ({
  type: SET_SHOULD_REFRESH_OFF,
});

export const setDailyAllowanceTypesCheckboxData = (checkboxData) => ({
  type: SET_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA,
  payload: {
    data: checkboxData,
  },
});

export const setAllDailyAllowanceTypesCheckboxData = (checkboxData = [], isActive = false) => ({
  type: SET_ALL_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA,
  payload: {
    data: checkboxData,
    isActive,
  },
});

export const fetchDailyAllowanceTypes = (params) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const response = await request.get('tools/daily-allowance-types', { params });
    dispatch({
      payload: response?.data,
      type: SET_DAILY_ALLOWANCE_TYPES,
    });
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const fetchDailyAllowanceTypesOptions = () => async (dispatch) => {
  try {
    const response = await request.get('tools/daily-allowance-types/options');
    dispatch({
      payload: response?.data,
      type: SET_DAILY_ALLOWANCE_TYPES_OPTIONS,
    });
  } catch (err) {
    console.error(err);
  }
};

export const addNewDailyAllowanceType = (values) => async (dispatch) => {
  try {
    const {  businessVertical, company, currency } = values;
    await request.post('tools/daily-allowance-types', { businessVertical, company, currency });
    
    dispatch({
      payload: values,
      type: ADD_DAILY_ALLOWANCE_TYPE,
    });

    getErrorStatusMessage({
      status: 200,
      message: 'New daily allowance type successfully added.',
    });
    dispatch(setShouldRefreshOn())
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const updateDailyAllowanceType = (dailyAllowanceTypeId, values) => async (dispatch) => {
  try {
    const {  businessVertical, company, currency } = values;

    await request.update(`tools/daily-allowance-types/${dailyAllowanceTypeId}`, { businessVertical, company, currency });

    dispatch({
      payload: values,
      type: UPDATE_DAILY_ALLOWANCE_TYPE,
    });

    getErrorStatusMessage({
      status: 200,
      message: 'Daily allowance type successfully updated.',
    });
    dispatch(setShouldRefreshOn())
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const updateDailyAllowanceTypeOptions = ({ allowanceId, options }) => async (dispatch) => {
  try {
    const updatedDailyAllowanceTypeOptions = await request.post(`tools/daily-allowance-types/${allowanceId}/options`, { options });
    getErrorStatusMessage({
      status: 200,
      message: 'Daily allowance type options successfully updated.',
    });
    dispatch(({
      payload: updatedDailyAllowanceTypeOptions,
      type: UPDATE_DAILY_ALLOWANCE_TYPE_OPTIONS,
    }));
    dispatch(setShouldRefreshOn())
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
    });
  }
};

export const deleteDailyAllowanceType = async (dailyAllowanceTypeId) => {
  try {
    await request.delete(`tools/daily-allowance-types/${dailyAllowanceTypeId}`);
    getErrorStatusMessage({
      status: 200,
      message: `Daily allowance type with ID - ${dailyAllowanceTypeId} successfully deleted`,
    })
  } catch (err) {
    getErrorStatusMessage({
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
      status: err?.response?.status,
    });
  }
};

export const deleteDailyAllowanceTypes = (ids: number[]) => async (dispatch) => {
  try {
    await Promise.allSettled(ids.map((id) => deleteDailyAllowanceType(id)));
    dispatch(setShouldRefreshOn());
  } catch (err) {
    getErrorStatusMessage({
      message: err?.response?.data?.error || 'Something went wrong. Try again later.',
      status: err?.response?.status,
    });
  }
};

export const handleAddNewCustomRule = (values, allowanceId, handleCancel) => async (dispatch) => {
  try {
    await request.post(`/tools/daily-allowance-types/custom-rules/${allowanceId}`, {
      customRule: values,
    });
    getErrorStatusMessage({
      status: 200,
      message: 'Custom rule successfully added.',
    });
    handleCancel()
    dispatch(setShouldRefreshOn())
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: 'Custom rule couldn\'t be added',
    });
  }
};

export const handleUpdateCustomRule = (values, allowanceId, id, handleCancel) => async (dispatch) =>  {
  try {
    await request.update(`/tools/daily-allowance-types/custom-rules/${allowanceId}/${id}`, {
      customRule: values
    });
    getErrorStatusMessage({
      status: 200,
      message: 'Custom rule successfully updated.',
    });
    handleCancel()
    dispatch(setShouldRefreshOn())
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: 'Custom rule couldn\'t be updated',
    });
  }
};

export const handleDeleteCustomRule = (allowanceId, ruleId, handleCancel) => async (dispatch) =>  {
  try {
    await request.delete(`/tools/daily-allowance-types/custom-rules/${allowanceId}/${ruleId}`);
    getErrorStatusMessage({
      status: 200,
      message: 'Custom rule successfully deleted.',
    });
    handleCancel();
    dispatch(setShouldRefreshOn())
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: 'Custom rule couldn\'t be deleted',
    });
  }
};
