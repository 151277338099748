import { useState, useMemo, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { Form, FormikProvider, useFormik } from 'formik';
import { GenericEdit } from '@heathmont/moon-icons';
import { TextInput, Button } from '@heathmont/moon-core';
import { checkOnIsEqual } from '../../../utils/commonFunctions';
import DynamicFormFields from '../../../components/DynamicFormFields';
import EllipsisText from '../../../components/EllipsisText';
import CustomSelect from '../../../components/CustomSelect';
import FormField from '../../../components/FormField';
import Accordion from '../../../components/Accordion';
import {
  getInitialCryptoWallets,
  userCryptoWalletsSchema,
} from './schema';
import { IUserCryptoWalletsForm } from './types';
import {
  OverviewItemsWrapper,
  EditButtonWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from '../UserDrawerContent/styles';
import CopyToClipboard from '../../../components/CopyToClipboard';

const UserCryptoWalletsForm: React.FC<IUserCryptoWalletsForm> = ({
  availableForCryptoWalletCurrencies,
  isCurrentUser,
  data,
  updateUserCryptoWallets,
}) => {
  const [isEditCryptoWallets, setIsEditCryptoWallets] = useState(false);
  const initialCryptoWalletsValues = useMemo(() => getInitialCryptoWallets(data?.cryptoWallets), [data.cryptoWallets]);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userCryptoWalletsSchema,
    validateOnMount: true,
    initialValues: initialCryptoWalletsValues,
    onSubmit: (values, { resetForm }): void => {
      updateUserCryptoWallets({
        userId: data.id,
        data: values,
        setIsEditForm: setIsEditCryptoWallets,
        resetForm,
      });
    },
  });
  const { touched, errors, values, resetForm, setFieldValue, setFieldTouched } = formik;

  useEffect(() => {
    setIsEditCryptoWallets(false);
    resetForm();
  }, [data.id]);

  return (
    <Accordion title="Crypto wallets" openByDefault withoutPadding>
      {!isEditCryptoWallets && !isCurrentUser && (
        <EditButtonWrapper>
          <Button
            variant="ghost"
            onClick={() => setIsEditCryptoWallets(!isEditCryptoWallets)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <FormikProvider value={formik}>
        <Form>              
          {isEditCryptoWallets ? (
            <DynamicFormFields
              fieldName="cryptoWallets"
              touched={touched}
              errors={errors}
              data={values.cryptoWallets}
              setFieldValue={setFieldValue}
              getDefaultValue={() => ({ id: null, address: undefined, cryptoCurrencyId: undefined })}
              renderItem={(fieldName, value, index) => (
                <>
                  <FormField
                    placeholder={'Select crypto wallet address'}
                    fieldName={`${fieldName}.${index}.address`}
                    inputSize="large"
                    component={TextInput}
                    fieldId={`${fieldName}.${index}.address`}
                    isError={!!(get(touched, [fieldName, index, 'address']) && get(errors, [fieldName, index, 'address']))}
                    value={value.address}
                    type="text"
                    onChange={({ target: { value } }) => setFieldValue(`${fieldName}.${index}.address`, value, true)}
                    onBlur={() => setFieldTouched(`${fieldName}.${index}.address`, true, false)}
                  />
                  <CustomSelect
                    placeholder={'Select crypto wallet currency'}
                    currentValue={value.cryptoCurrencyId}
                    isError={!!(get(touched, [fieldName, index, 'cryptoCurrencyId']) && get(errors, [fieldName, index, 'cryptoCurrencyId']))}
                    items={availableForCryptoWalletCurrencies}
                    onChange={(cryptoCurrencyId) => setFieldValue(`${fieldName}.${index}.cryptoCurrencyId`, cryptoCurrencyId, true)} 
                    onBlur={() => setFieldTouched(`${fieldName}.${index}.cryptoCurrencyId`, true, false)}
                  />
                </>
              )}
            />
          ) : (
            <>
              {data.cryptoWallets.map(cryptoWallet => (
                <OverviewItemsWrapper key={cryptoWallet?.id}>
                  <OverviewItemName>
                    <EllipsisText text={cryptoWallet?.cryptoCurrency?.title} />
                  </OverviewItemName>
                  <OverviewItem>
                    <CopyToClipboard text={cryptoWallet?.address} />
                  </OverviewItem>
                </OverviewItemsWrapper>
              ))}
            </>
          )}
          {isEditCryptoWallets && (
            <ButtonsWrapper>
              <Button
                variant="secondary"
                type="reset"
                onClick={() => {
                  resetForm();
                  setIsEditCryptoWallets(!isEditCryptoWallets);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                disabled={!isEmpty(errors) ||  checkOnIsEqual(values, initialCryptoWalletsValues)}
              >
                Save
              </Button>
            </ButtonsWrapper>
          )}
        </Form>
      </FormikProvider>  
    </Accordion>
  );
};

export default UserCryptoWalletsForm;
