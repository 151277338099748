import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Helmet from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import values from 'lodash/values';
import { FilesExport, SoftwareSorting } from '@heathmont/moon-icons';
import { Checkbox, Text, Button } from '@heathmont/moon-core';
import request from '../../models/request';
import { usePageTitle } from '../../models/page';
import { initExpensesFilters, useFilters } from '../../hooks/useFilters';
import { useQuery } from '../../hooks/useQuery';
import { useCustomTable } from '../../hooks/useCustomTable';
import { DEFAULT_SORTING, LIMIT_PER_PAGE } from '../../constants';
import { getExpensesFilters, initialColumns, getUpdateStatusInfoText } from './suggestions';
import ExpensesOverview from './ExpensesOverview';
import { RowHeight } from '../Reports/suggestions';
import ExpenseReportReceiptsModal from '../../components/ExpenseReportReceiptsModal';
import TableContentReciever from '../../components/TableContentReciever';
import { FiltersReciever } from '../../components/FiltersReciever';
import AddNewReceiptModal from '../../components/AddNewReceiptModal';
import { TogglerWithCount } from '../../components/TogglerWithCount';
import TablePagination from '../../components/TablePagination';
import InfoBlock from '../../components/InfoBlock';
import DownDrawer from '../../components/DownDrawer';
import { Spinner } from '../../components/Spinner';
import Drawer from '../../components/Drawer';
import Dialog from '../../components/Dialog';

import ExpenseForm from '../../components/ExpenseForm';
import { expensesGroupedDataMapper, getExpensesStatusLabel } from '../../utils/tableHelpers';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import { prepareFilters } from '../../utils/filters';
import { IExpenses } from './types';
import {
  ConfirmDialogBodyWrapper,
  ModalCheckboxWrapper,
  TableWrapper,
  PageHeader,
  Wrapper,
} from './styles';
import { FilterHeaderWrapper, HiddenRolePopoverBackground } from '../../styled';
import { EXPENSES_TYPE, TRIPS_EXPENSES_TYPES } from '../../enums/ExpensesType';

const GROUP_BY = 'travelerName';

const Expenses: React.FC<IExpenses> = ({
  isUploadingReceipts,
  isOpenAddReceipts,
  currentUserData,
  checkboxesData,
  isOpenOverview,
  isFiltersOpen,
  isLoadingData,
  expensesData,
  overviewData,
  options,
  total,
  updateExpensesVerificationData,
  updateInvoiceCreationData,
  updateBalancedIntegration,
  updateReimbursementInfo,
  setExpensesCheckboxData,
  closeExpensesOverview,
  showExpensesOverview,
  setIsOpenAddReceipts,
  fetchExpenses,
  removeReceipt,
  toggleFilters,
  fetchOptions,
  closeFilters,
  updateStatus,
  addReceipt,
}) => {
  const [expenseUpdateLoading, setExpenseUpdateLoading] = useState(false);
  const [expensesForDelete, setExpensesForDelete] = useState([]);
  const [tripIdForExpensesDelete, setTripIdForExpensesDelete] = useState(null);
  const [isUpdateExpensesStatusModalOpen, setIsUpdateExpensesStatusModalOpen] = useState(false);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const [tripIdForUpdateStatus, setTripIdForUpdateStatus] = useState(null);
  const [newExpenseStatusForTrip, setNewExpenseStatusForTrip] = useState(null);
  const [shouldCreateInvoice, setShouldCreateInvoice] = useState(true);
  const [isDeleteExpenseModalOpen, setIsDeleteExpenseModalOpen] = useState(false);
  const [isDeleteTripExpensesModalOpen, setIsDeleteTripExpensesModalOpen] = useState(false);
  const [expensesForEdit, setExpensesForEdit] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [invoiceCreationLoading, setInvoiceCreationLoading] = useState(false);
  const [tripIdForInvoiceCreation, settTripIdForInvoiceCreation] = useState(null);
  const [isInvoiceCreationConfirmModalOpen, setIsInvoiceCreationConfirmModalOpen] = useState(false);

  const [remainToVerifyLoading, setRemainToVerifyLoading] = useState(false);
  const [tripIdForRemindToVerify, setTripIdForRemindToVerify] = useState(null);
  const [isRemindToVerifyModalOpen, setRemindToVerifyModalOpen] = useState(false);
  
  const [expenseForAdd, setExpenseForAdd] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [openDatePickerField, setOpenDatePickerField] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [sortBy, setSortBy] = useState(DEFAULT_SORTING.EXPENSES);
  const [isShowTableFilters, setIsShowTableFilters] = useState(false);

  const [receiptsModalData, setReceiptsModalData] = useState({ isOpen: false, defaultReceiptId: null });
  const [rowHeight, setRowHeight] = useState(RowHeight.M);

  const pageTitle = usePageTitle('html.expenses.title');
  const { getQuery, setQuery } = useQuery();

  const totalItems = useRef(0);

  useEffect(() => {
    fetchOptions();

    return () => {
      closeFilters();
      closeExpensesOverview();
    };
  }, []);

  const columns = useMemo(() => initialColumns, []);

  const queryParams = useRef('');

  const { page, perPage, nextPage, prevPage, setPage } = useCustomTable([], LIMIT_PER_PAGE.EXPENSES);

  const { filters, filtersCount, toggleFilter, setFilter } = useFilters(getQuery(), initExpensesFilters);

  const setFiltersAndToFirstPage = (field: string, value: any) => {
    setPage(1);
    setFilter(field, value);
  };

  const toggleFiltersAndToFirstPage = (field, value) => {
    setPage(1);
    toggleFilter(field, value);
  };

  const handleDatePicketOpen = (field) => {
    return (isOpen) => {
      setOpenDatePickerField(field);
      setIsOpenDatePicker(isOpen);
    };
  };

  const handleUpdateStatusOpen = (tripId, newStatus) => {
    setTripIdForUpdateStatus(tripId);
    setNewExpenseStatusForTrip(newStatus);
    setIsUpdateExpensesStatusModalOpen(true);
  };

  const handleUpdateStatusClose = () => {
    setIsUpdateExpensesStatusModalOpen(false);
    setTripIdForUpdateStatus(null);
    setNewExpenseStatusForTrip(null);
    setStatusUpdateLoading(false);
    setShouldCreateInvoice(true);
  };

  const handleUpdateExpensesStatus = async () => {
    setStatusUpdateLoading(true);
    try {
      const response = await request.post(`expenses/status/${tripIdForUpdateStatus}`, {
        shouldCreateInvoice,
        status: newExpenseStatusForTrip,
      });

      getErrorStatusMessage({
        status: 200,
        message: `Expense report status updated for Trip ID - ${tripIdForUpdateStatus} status changed.`,
      });
      updateStatus(response.data.trip);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message:
          err?.response?.data?.error || `The expenses status with Trip ID - ${tripIdForUpdateStatus} can't be changed.`,
      });
    } finally {
      handleUpdateStatusClose();
    }
  };

  const handleInvoiceConfirmModalOpen = (tripId) => {
    settTripIdForInvoiceCreation(tripId);
    setIsInvoiceCreationConfirmModalOpen(true);
  };

  const handleInvoiceConfirmModalClose = () => {
    settTripIdForInvoiceCreation(null);
    setIsInvoiceCreationConfirmModalOpen(false);
    setInvoiceCreationLoading(false);
  };

  const createExpensesReportInvoice = async () => {
    setInvoiceCreationLoading(true);
    try {
      const response = await request.post(`expenses/invoice/${tripIdForInvoiceCreation}`);
      getErrorStatusMessage({
        status: 200,
        message: 'The expenses report invoice created successfully.',
      });
      updateInvoiceCreationData(tripIdForInvoiceCreation, response.data.user, response.data.date);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message:
          err?.response?.data?.error || `The expenses report invoice can not be created.`,
      });
    } finally {
      handleInvoiceConfirmModalClose();
    }
  };

  const handleRemindToVerifyModalOpen = (tripId) => {
    setTripIdForRemindToVerify(tripId);
    setRemindToVerifyModalOpen(true);
  };

  const handleRemindToVerifyModalClose = () => {
    setTripIdForRemindToVerify(null);
    setRemindToVerifyModalOpen(false);
    setRemainToVerifyLoading(false);
  };

  const remindToVerifyExpenseReport = async () => {
    setRemainToVerifyLoading(true);
    try {
      const response = await request.post(`expenses/remind-to-verify/${tripIdForRemindToVerify}`);
      getErrorStatusMessage({
        status: 200,
        message: 'The expenses report was sent to verification successfully.',
      });
      updateExpensesVerificationData(response.data.trip);
    } catch (err) {
      getErrorStatusMessage({
        status: err?.response?.status,
        message:
          err?.response?.data?.error || `The expenses report can not be sent to verification.`,
      });
    } finally {
      handleRemindToVerifyModalClose();
    }
  };

  const handleSetReceiptData = useCallback((data = { isOpen: false, receiptId: null }) => {
    const { isOpen, receiptId } = data;
    setReceiptsModalData({ isOpen, defaultReceiptId: receiptId });
  }, []);

  const handleCloseReceipt = () => {
    setReceiptsModalData({ isOpen: false, defaultReceiptId: null });
  };

  const handleRemoveReceipt = async (receiptId) => {
    await removeReceipt(overviewData.trip.id, receiptId, params);
    handleCloseReceipt();
  };

  const handleIsOverviewOpen = async (row?: any) => {
    const id = expensesData[row?.id].id;
    const selectedTrip = expensesData.filter((item) => item.id === id);
    const { data } = await request.get(`expenses/details/${id}`);

    data.trip.totalCost = selectedTrip[0].expensesTotalCost;
    data.trip.status = data.trip.expensesStatus;

    showExpensesOverview(data);
  };

  const handleHoveredRow = useCallback((row: any) => setHoveredRow(row), []);

  const filterComponents = useMemo(() => {
    return getExpensesFilters(
      isOpenDatePicker,
      openDatePickerField,
      filters,
      options,
      handleDatePicketOpen,
      setFiltersAndToFirstPage,
      toggleFiltersAndToFirstPage
    );
  }, [
    isOpenDatePicker,
    openDatePickerField,
    filters,
    options,
    handleDatePicketOpen,
    setFiltersAndToFirstPage,
    toggleFiltersAndToFirstPage,
  ]);

  useMemo(() => {
    totalItems.current = total;
  }, [total]);

  const expensesGroupedData = useMemo(() => expensesGroupedDataMapper(expensesData, GROUP_BY), [expensesData]);

  const toggleTableFilters = () => setIsShowTableFilters((prevState: boolean) => !prevState);

  const changeRowHeight = () => {
    if (rowHeight === RowHeight.M) {
      setRowHeight(RowHeight.S);
    }
    if (rowHeight === RowHeight.S) {
      setRowHeight(RowHeight.M);
    }
  };

  const setQueryParams = () => {
    const params: any = {
      filters: prepareFilters(filters),
      perPage,
      page,
    };

    if (sortBy && sortBy.length) {
      params.sort = {
        field: sortBy[0].id,
        order: sortBy[0].desc ? 'desc' : 'asc',
      };
    }

    setQuery(params.filters);

    return params;
  };

  const params = useMemo(() => setQueryParams(), [filters, page, sortBy]);

  const handleExport = async () => {
    try {
      const { data } = await request.get(`expenses/file`, {
        params,
        responseType: 'arraybuffer',
      });

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      saveAs(blob, `[${moment().format('DD.MM.YYYY')}]expenses report.xlsx`);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: 'Something went wrong. Try again later',
      });
    }
  };

  const handleDeleteExpenseModalOpen = (row, isMulti?: boolean) => {
    setExpensesForDelete(isMulti ? values(row) : [row]);
    setIsDeleteExpenseModalOpen(true);
  };

  const handleDeleteExpenseModalClose = () => {
    setExpensesForDelete([]);
    setIsDeleteExpenseModalOpen(false);
  };

  const handleDeleteTripExpensesModalOpen = (row) => {
    setTripIdForExpensesDelete(row.tripId);
    setIsDeleteTripExpensesModalOpen(true);
  };

  const handleDeleteTripExpensesModalClose = () => {
    setTripIdForExpensesDelete(null);
    setIsDeleteTripExpensesModalOpen(false);
  };

  const handleEditModalOpen = (row, isMulti?: boolean) => {
    setExpensesForEdit(isMulti ? values(row) : [row.original]);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setExpensesForEdit([]);
    setIsEditModalOpen(false);
  };

  const handleAddModalOpen = (row) => {
    setExpenseForAdd({ tripId: row.tripId });
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setExpenseForAdd(null);
    setIsAddModalOpen(false);
  };

  const handleDeleteExpense = async () => {
    try {
      setExpenseUpdateLoading(true);

      await request.delete(`expenses/multiple/${expensesForDelete[0].tripId}`, {
        data: {
          ids: expensesForDelete.map(expense => expense.id)
        },
      });

      getErrorStatusMessage({
        status: 200,
        message: `The expense deleted successfully.`,
      });

      setExpensesCheckboxData([], true);
      closeExpensesOverview();
      fetchExpenses(params);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: `The expense can not be deleted.`,
      });
    } finally {
      setExpenseUpdateLoading(false);
      handleDeleteExpenseModalClose();
    }
  };

  const handleDeleteTripExpenses = async () => {
    try {
      setExpenseUpdateLoading(true);

      await request.delete(`expenses/${tripIdForExpensesDelete}`);

      getErrorStatusMessage({
        status: 200,
        message: `The trip expenses deleted successfully.`,
      });

      setExpensesCheckboxData([], true);
      closeExpensesOverview();
      fetchExpenses(params);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: `The trip expenses can not be deleted.`,
      });
    } finally {
      setExpenseUpdateLoading(false);
      handleDeleteTripExpensesModalClose();
    }
  };

  const handleResetCheckboxes = () => {
    handleEditModalClose();
    handleDeleteExpenseModalClose();
    setExpensesCheckboxData([], true);
  };

  const handleAddNew = async (expense) => {
    try {
      setExpenseUpdateLoading(true);
      await request.post(`expenses/${expenseForAdd.tripId}`, { expense });

      getErrorStatusMessage({
        status: 200,
        message: `The expense added successfully.`,
      });

      setExpensesCheckboxData([], true);
      fetchExpenses(params);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: `The expense can not be added.`,
      });
    } finally {
      setExpenseUpdateLoading(false);
      handleAddModalClose();
    }
  };

  const handleEdit = async (expense) => {
    try {
      setExpenseUpdateLoading(true);
      await request.update(`expenses/multiple/${expensesForEdit[0].tripId}`, {
        data: expensesForEdit?.length === 1 ?  expense : omit(expense, ['type', 'date']),
        ids: expensesForEdit.map(expense => expense.id),
      });

      getErrorStatusMessage({
        status: 200,
        message: `The expense changed successfully.`,
      });

      setExpensesCheckboxData([], true);
      closeExpensesOverview();
      fetchExpenses(params);
    } catch (e) {
      getErrorStatusMessage({
        status: e?.response?.status,
        message: `The expense can not be changed.`,
      });
    } finally {
      setExpenseUpdateLoading(false);
      handleEditModalClose();
    }
  };

  const handleAddReceipt = useCallback((data) => {
    addReceipt(overviewData.trip.id, data);
  }, [overviewData]);

  useEffect(() => {
    fetchExpenses(params);
    setExpensesCheckboxData([], true);
  }, [filters, page, sortBy]);

  const isRowDisabledSubRow = useCallback((expense) => {
    const isDailyAllowance = expense.type === TRIPS_EXPENSES_TYPES.DAILY_ALLOWANCE;
    const selectedReports = values(checkboxesData);
    const isExpenseFromAnotherReportSelected = selectedReports?.length && selectedReports[0].tripId !== expense.tripId;
    return !isDailyAllowance || isExpenseFromAnotherReportSelected;
  }, [checkboxesData]);

  const isRowCheckedSubRow = useCallback((expense) => {
    return !!checkboxesData[expense.id];
  }, [checkboxesData]);

  const tableContentRecieverProps = {
    isShowTableFilters,
    initialColumns: columns,
    limitPerPage: LIMIT_PER_PAGE.TRIPS,
    queryParams: queryParams.current,
    isExpenses: true,
    hoveredRow,
    withHover: true,
    rowHeight,
    groupBy: GROUP_BY,
    sortBy,
    page,
    data: expensesGroupedData,
    handleSetCheckboxSubRow: setExpensesCheckboxData,
    handleIsOverviewOpen,
    isRowDisabledSubRow,
    isRowCheckedSubRow,
    handleDeleteSubRow: handleDeleteExpenseModalOpen,
    handleRemoveDialog: handleDeleteTripExpensesModalOpen,
    toggleTableFilters,
    handleEditSubRow: handleEditModalOpen,
    handleHoveredRow,
    handleSortBy: setSortBy,
    handleAddNew: handleAddModalOpen,
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Wrapper>
        {isFiltersOpen && (
          <FiltersReciever
            filterComponents={filterComponents}
            isOpenDatePicker={isOpenDatePicker}
            placeholder="Search for users, team"
            filters={filters}
            setFilter={setFiltersAndToFirstPage}
          />
        )}

        <TableWrapper>
          <PageHeader>
            <FilterHeaderWrapper>
              <TogglerWithCount
                isActive={isFiltersOpen}
                count={filtersCount}
                handleToggle={toggleFilters}
                icon={<SoftwareSorting fontSize="1.5rem" />}
              />

              <Text size={32} as="h1" styles={{ marginBottom: 16 }}>
                Expenses
              </Text>
            </FilterHeaderWrapper>

            <Button
              iconLeft={<FilesExport fontSize="1.6rem" />}
              disabled={!totalItems.current}
              variant="primary"
              onClick={handleExport}
            >
              Export as excel
            </Button>
          </PageHeader>

          <TablePagination
            isShowTableFilters={isShowTableFilters}
            limitPerPage={LIMIT_PER_PAGE.EXPENSES}
            totalItems={totalItems}
            data={expensesData}
            page={page}
            toggleTableFilters={toggleTableFilters}
            changeRowHeight={changeRowHeight}
            refetchData={() => fetchExpenses(params)}
            nextPage={nextPage}
            prevPage={prevPage}
          />

          {isLoadingData ? <Spinner /> : <TableContentReciever {...tableContentRecieverProps} />}
        </TableWrapper>

        {isOpenAddReceipts && (
          <HiddenRolePopoverBackground
            onClick={() => !isUploadingReceipts && setIsOpenAddReceipts(false)}
            withBackground
          />
        )}

        {receiptsModalData.isOpen && (
          <ExpenseReportReceiptsModal
            receiptId={receiptsModalData.defaultReceiptId}
            receipts={overviewData?.receipts}
            handleRemoveReceipt={handleRemoveReceipt}
            handleCloseReceipt={handleCloseReceipt}
          />
        )}

        {isOpenOverview && (
          <div>
            <Drawer
              withStatus
              status={overviewData?.trip?.status}
              tripId={overviewData?.trip?.id}
              title={overviewData?.trip?.user?.fullName}
              getStatusLabel={getExpensesStatusLabel}
              handleClose={closeExpensesOverview}
            >
              <ExpensesOverview
                reimbursementAddresses={options.reimbursementAddresses}
                reimbursementEmployers={options.employers}
                currentUserData={currentUserData}
                statuses={options.expensesStatuses}
                cryptoCurrencies={options.cryptoCurrencies}
                data={overviewData}
                handleChangeExpensesStatus={handleUpdateStatusOpen}
                updateBalancedIntegration={updateBalancedIntegration}
                updateReimbursementInfo={updateReimbursementInfo}
                handleInvoiceCreation={handleInvoiceConfirmModalOpen}
                handleRemindToVerify={handleRemindToVerifyModalOpen}
                handleSetReceiptData={handleSetReceiptData}
                setIsOpenAddReceipts={setIsOpenAddReceipts}
                getStatusLabel={getExpensesStatusLabel}
              />
            </Drawer>
          </div>
        )}

        {isOpenAddReceipts && (
          <AddNewReceiptModal
            handleAgree={handleAddReceipt}
            handleClose={setIsOpenAddReceipts}
            isProgress={isUploadingReceipts}
          />
        )}

        {isInvoiceCreationConfirmModalOpen && (
          <Dialog
            submitButtonLabel="Confirm"
            isSubmitDisabled={invoiceCreationLoading}
            maxWidth={500}
            title="Confirm"
            onSubmit={createExpensesReportInvoice}
            onClose={handleInvoiceConfirmModalClose}
          >
            <InfoBlock
              text={`
                Creating the invoice can produce duplication in balanced.io.
                Please ensure that accountants have not previously added the invoice
                manually for this expense report.
              `}
            />
            <Text size={16}>Are you sure you want to create an invoice?</Text>
          </Dialog>
        )}

        {isUpdateExpensesStatusModalOpen && (
          <Dialog
            submitButtonLabel="Update"
            isSubmitDisabled={statusUpdateLoading}
            maxWidth={500}
            title="Update trip expenses status"
            data={tripIdForUpdateStatus}
            onSubmit={newExpenseStatusForTrip !== EXPENSES_TYPE.PENDING && handleUpdateExpensesStatus}
            onClose={handleUpdateStatusClose}
          >
            <InfoBlock text={getUpdateStatusInfoText(newExpenseStatusForTrip)} />
            {newExpenseStatusForTrip !== EXPENSES_TYPE.PENDING && (
              <ConfirmDialogBodyWrapper>
                <Text size={16}>Are you sure you want to update the expense report status to</Text>
                {getExpensesStatusLabel(newExpenseStatusForTrip)}
                <Text size={16}>?</Text>
              </ConfirmDialogBodyWrapper>
            )}
            {newExpenseStatusForTrip === EXPENSES_TYPE.TO_BE_PAID && !!currentUserData.company.balancedStatus && (
              <ModalCheckboxWrapper>
                <Checkbox
                  checked={shouldCreateInvoice}
                  label={'Automatically create an invoice'}
                  onChange={() => setShouldCreateInvoice(!shouldCreateInvoice)}
                />
              </ModalCheckboxWrapper>
            )}
          </Dialog>
        )}

        {isDeleteExpenseModalOpen && (
          <Dialog
            submitButtonLabel="Delete"
            title="Delete expense(s)"
            onSubmit={handleDeleteExpense}
            onClose={handleDeleteExpenseModalClose}
          >
            <Text size={16}>Do you want to delete {expensesForDelete.length > 1 ? 'these expenses' : 'this expense'}?</Text>
          </Dialog>
        )}

        {isDeleteTripExpensesModalOpen && (
          <Dialog
            submitButtonLabel="Delete"
            title="Delete trip expenses"
            data={tripIdForExpensesDelete}
            onSubmit={handleDeleteTripExpenses}
            onClose={handleDeleteTripExpensesModalClose}
          >
            <Text size={16}>Do you want to delete trip expenses?</Text>
          </Dialog>
        )}

        {isRemindToVerifyModalOpen && (
          <Dialog
            submitButtonLabel="Remind"
            isSubmitDisabled={remainToVerifyLoading}
            isLoading={remainToVerifyLoading}
            title="Remind to verify expenses report"
            data={tripIdForRemindToVerify}
            onSubmit={remindToVerifyExpenseReport}
            onClose={handleRemindToVerifyModalClose}
          >
            <Text size={16}>Do you want to remind manager to verify expenses report?</Text>
          </Dialog>
        )}

        {isAddModalOpen && (
          <Dialog
            submitButtonLabel="Add"
            withFooter={false}
            maxWidth={500}
            title="Add new expense"
            data={expenseForAdd}
            onClose={handleAddModalClose}
          >
            <ExpenseForm
              expenseTypes={options.expenseTypes}
              initialData={expenseForAdd}
              currencies={options.currencies}
              loading={expenseUpdateLoading}
              onSubmit={handleAddNew}
              onCancel={handleAddModalClose}
            />
          </Dialog>
        )}

        {isEditModalOpen && (
          <Dialog
            submitButtonLabel="Save"
            withFooter={false}
            maxWidth={500}
            title="Edit expense(s)"
            onClose={handleEditModalClose}
          >
            <ExpenseForm
              expenseTypes={options.expenseTypes}
              initialData={expensesForEdit[0]}
              currencies={options.currencies}
              expenses={expensesForEdit}
              loading={expenseUpdateLoading}
              isMulti={expensesForEdit.length > 1}
              onSubmit={handleEdit}
              onCancel={handleEditModalClose}
            />
          </Dialog>
        )}

        {!isEmpty(checkboxesData) && (
          <DownDrawer
            data={values(checkboxesData)}
            text="expense"
            handleRemoveDialog={() => handleDeleteExpenseModalOpen(checkboxesData, true)}
            handleSetCheckbox={handleResetCheckboxes}
            handleEdit={() => handleEditModalOpen(checkboxesData, true)}
          />
        )}
      </Wrapper>
    </>
  );
};

export default Expenses;
