export const FLIGHT_SOURCE = {
  GDS: 'GDS',
  PYTON: 'PYTON',
};

export const LIMIT_PER_PAGE = {
  PAYMENTS: 20,
  EXPENSES: 20,
  REPORTS: 20,
  TRIPS: 20,
  USERS: 20,
  TOOLS: {
    IGNORE_LIST_ACCOMMODATIONS: 20,
    ACTIONS_AND_NOTIFICATIONS: 20,
    BUSINESS_VERTICALS: 20,
    RECOMMENDED_HOTELS: 20,
    DAILY_ALLOWANCES: 20,
    EMPLOYMENT_TYPES: 20,
    SUBSCRIPTIONS: 20,
    CUSTOM_HOTELS: 20,
    EMPLOYERS: 20,
    COMPANIES: 20,
    FEEDBACKS: 20,
    PURPOSES: 20,
    ROLES: 20,
    TEAMS: 20,
  },
};

export const DEFAULT_SORTING = {
  PAYMENTS: [{ id: 'id', desc: true }],
  EXPENSES: [],
  REPORTS: [{ id: 'paymentDate', desc: true }],
  TRIPS: [{ id: 'tripTime', desc: true }],
  USERS: [],
  TOOLS: {
    IGNORE_LIST_ACCOMMODATIONS: [{ id: 'id', desc: false }],
    ACTIONS_AND_NOTIFICATIONS: [],
    RECOMMENDED_HOTELS: [],
    DAILY_ALLOWANCES: [],
    EMPLOYMENT_TYPES: [],
    CUSTOM_HOTELS: [{ id: 'name', desc: false }],
    SUBSCRIPTIONS: [],
    EMPLOYERS: [{ id: 'employerId', desc: false }],
    FEEDBACKS: [],
    COMPANIES: [{ id: 'name', desc: false }],
    PURPOSES: [{ id: 'purposeId', desc: false }],
    ROLES: [{ id: 'roleId', desc: false }],
    TEAMS: [{ id: 'teamId', desc: false }],
  },
};

export const REPORT_GROUP_BY_FIELDS = [
  {
    value: '',
    title: 'None',
  },
  {
    value: 'employer',
    title: 'Employer',
    default: true,
  },
  {
    value: 'businessVertical',
    title: 'Business vertical',
  },
  {
    value: 'travelerName',
    title: 'Traveler name',
  },
  {
    value: 'team',
    title: 'Team',
  },
  {
    value: 'destinationForReports',
    title: 'Destination',
  },
  {
    value: 'paymentDate',
    title: 'Payment date',
  },
  {
    value: 'tripStartDate',
    title: 'Trip time',
  },
];

export const RECEIPTS_SETTINGS = {
  ACCEPTED_TYPES: [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'svg',
    'webp',
    'png',
    'pdf',
  ],
  MAX_FILE_SIZE: 2e+7, // 20mb
  MAX_LENGTH: 10,
};

export const FLIGHTS_PROVIDERS = {
  AMADEUS: 'amadeus',
  EXTERNAL: 'external',
};

export const ACCOMMODATION_PROVIDERS = {
  RATEHAWK: 'ratehawk',
  EXTERNAL: 'external',
};

export const EXTERNAL_REFERENCE_PREFIX = 'EXT-';

export const DEFAULT_BAGGAGE_WEIGHT_UNIT = 'KG';

export const DEFAULT_MIN_LENGTH_TO_START_SEARCHING = 3;

export const DEFAULT_DEBOUNCE_MS_COUNT = 500;

export const MAX_NUMBER_OF_MULTI_DESTINATIONS = 3;

export const REIMBURSEMENT_ADDRESSES = {
  BANK_ACCOUNT: 'bank_account',
  CRYPTO_WALLET: 'crypto_wallet',
};

export const STATUS_CODE = {
  USER_UNAUTHORIZED: 401,
};

export const TRIP_USER_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const BUSINESS_VERTICAl_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};
