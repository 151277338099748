import { useState } from 'react';
import Helmet from 'react-helmet';
import { TOOLS_PAGES } from '../../components/Routes/suggestions';
import ToolsRoutes from '../../components/Routes/ToolsRoutes';
import SearchField from '../../components/SearchField';
import { useQuery } from '../../hooks/useQuery';
import { usePageTitle } from '../../models/page';
import { prepareFilters } from '../../utils/filters';
import { useFilters } from '../../hooks/useFilters';
import { useCustomTable } from '../../hooks/useCustomTable';
import { getRecommendedHotelsPageData } from './suggestions';
import {
  getIgnoreListAccommodationsPageData,
  getActionsAndNotificationsPageData,
  getBusinessVerticalsPageData,
  getEmploymentTypesPageData,
  getSubscriptionsPageData,
  getCustomHotelsPageData,
  getFeedbacksPageData,
  getEmployersPageData,
  getPurposesPageData,
  getRolesPageData,
  getTeamsPageData,
  getPageData,
} from './suggestions';
import {
  NavigationItemName,
  NavigationHeader,
  ToolsNavigation,
  NavigationBlock,
  NavigationItem,
  Wrapper,
  Header,
} from './styles';

const Tools: React.FC<any> = (props) => {
  const { defaultSortBy, limitPerPage, searchPlaceholder, initFilters } = getPageData(props.history.location.pathname);
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const { getQuery, setQuery } = useQuery();
  const { filters, setFilter } = useFilters(getQuery(), initFilters);
  const { page, perPage, nextPage, prevPage, setPage } = useCustomTable([], limitPerPage);

  const setFiltersAndToFirstPage = (field: string, value: any) => {
    setFilter(field, value);
    setPage(1);
  };

  const setQueryParams = () => {
    let params: any = {
      filters: prepareFilters(filters),
    };

    if (props.history.location.pathname !== TOOLS_PAGES.SUBSCRIPTIONS) {
      params = { ...params, perPage, page };
    }

    if (sortBy && sortBy.length) {
      params.sort = {
        field: sortBy[0].id,
        order: sortBy[0].desc ? 'desc' : 'asc',
      };
    }

    setQuery(params.filters);

    return params;
  };

  const additionalPageData = {
    filters,
    sortBy,
    page,
    setQueryParams,
    setSortBy,
    nextPage,
    prevPage,
  };

  const data = {
    rolesPage: {
      ...getRolesPageData(props),
      ...additionalPageData,
    },
    teamsPage: {
      ...getTeamsPageData(props),
      ...additionalPageData,
    },
    employersPage: {
      ...getEmployersPageData(props),
      ...additionalPageData,
    },
    companiesPage: {
      ...additionalPageData,
    },
    purposesPage: {
      ...getPurposesPageData(props),
      ...additionalPageData,
    },
    subscriptionsPage: {
      ...getSubscriptionsPageData(props),
      ...additionalPageData,
    },
    actionsAndNotifications: {
      ...getActionsAndNotificationsPageData(props),
      ...additionalPageData,
    },
    employmentTypes: {
      ...getEmploymentTypesPageData(props),
      ...additionalPageData,
    },
    feedbacks: {
      ...getFeedbacksPageData(props),
      ...additionalPageData,
    },
    allowancesPage:{
      ...additionalPageData
    },
    recommendedHotels: {
      ...getRecommendedHotelsPageData(props),
      ...additionalPageData,
    },
    ignoreListAccommodations: {
      ...getIgnoreListAccommodationsPageData(props),
      ...additionalPageData,
    },
    customHotels: {
      ...getCustomHotelsPageData(props),
      ...additionalPageData,
    },
    businessVerticals: {
      ...getBusinessVerticalsPageData(props),
      ...additionalPageData,
    },
  };

  const pageTitle = usePageTitle('html.tools.title');

  return (
    <Wrapper>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      {!props.isOpenRolesOverview &&
        !props.isOpenTeamsOverview &&
        !props.isOpenEmployerOverview &&
        !props.isOpenPurposeOverview &&
        !props.isOpenSubscriptionsOverview &&
        !props.isOpenActionAndNotificationOverview &&
        !props.isOpenCompanyOverview && 
        !props.isOpenBookingForIgnoreOverview &&
        !props.isOpenCustomHotelOverview && (
          <ToolsNavigation>
            <Header>Admin space</Header>

            <SearchField
              placeholder={searchPlaceholder}
              searchValue={filters.search}
              handleChange={(value) => setFiltersAndToFirstPage('search', value)}
            />

            <NavigationBlock>
              <NavigationHeader>Companies</NavigationHeader>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.COMPANIES}
                onClick={() => props.history.push(TOOLS_PAGES.COMPANIES)}
              >
                <NavigationItemName>Companies</NavigationItemName>
              </NavigationItem>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.BUSINESS_VERTICALS}
                onClick={() => props.history.push(TOOLS_PAGES.BUSINESS_VERTICALS)}
              >
                <NavigationItemName>Business Verticals</NavigationItemName>
              </NavigationItem>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.TEAMS}
                onClick={() => props.history.push(TOOLS_PAGES.TEAMS)}
              >
                <NavigationItemName>Teams</NavigationItemName>
              </NavigationItem>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.EMPLOYERS}
                onClick={() => props.history.push(TOOLS_PAGES.EMPLOYERS)}
              >
                <NavigationItemName>Employers</NavigationItemName>
              </NavigationItem>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.PURPOSES}
                onClick={() => props.history.push(TOOLS_PAGES.PURPOSES)}
              >
                <NavigationItemName>Trip purposes</NavigationItemName>
              </NavigationItem>
              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.DAILY_ALLOWANCE_TYPES}
                onClick={() => props.history.push(TOOLS_PAGES.DAILY_ALLOWANCE_TYPES)}
              >
                <NavigationItemName>Daily allowance types</NavigationItemName>
              </NavigationItem>
            </NavigationBlock>

            <NavigationBlock>
              <NavigationHeader>Position</NavigationHeader>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.ROLES}
                onClick={() => props.history.push(TOOLS_PAGES.ROLES)}
              >
                <NavigationItemName>Roles</NavigationItemName>
              </NavigationItem>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.EMPLOYMENT_TYPES}
                onClick={() => props.history.push(TOOLS_PAGES.EMPLOYMENT_TYPES)}
              >
                <NavigationItemName>Employment types</NavigationItemName>
              </NavigationItem>
            </NavigationBlock>

            <NavigationBlock>
              <NavigationHeader>Recommendations</NavigationHeader>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.RECOMMENDED_HOTELS}
                onClick={() => props.history.push(TOOLS_PAGES.RECOMMENDED_HOTELS)}
              >
                <NavigationItemName>Hotels</NavigationItemName>
              </NavigationItem>
            </NavigationBlock>

            <NavigationBlock lastItem>
              <NavigationHeader>Other</NavigationHeader>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.CUSTOM_HOTELS}
                onClick={() => props.history.push(TOOLS_PAGES.CUSTOM_HOTELS)}
              >
                <NavigationItemName>Custom hotels</NavigationItemName>
              </NavigationItem>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.IGNORE_LIST_ACCOMMODATIONS}
                onClick={() => props.history.push(TOOLS_PAGES.IGNORE_LIST_ACCOMMODATIONS)}
              >
                <NavigationItemName>Ignored accommodations</NavigationItemName>
              </NavigationItem>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.FEEDBACKS}
                onClick={() => props.history.push(TOOLS_PAGES.FEEDBACKS)}
              >
                <NavigationItemName>Feedbacks</NavigationItemName>
              </NavigationItem>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.SUBSCRIPTIONS}
                onClick={() => props.history.push(TOOLS_PAGES.SUBSCRIPTIONS)}
              >
                <NavigationItemName>Subscriptions</NavigationItemName>
              </NavigationItem>

              <NavigationItem
                isActive={props.history.location.pathname === TOOLS_PAGES.ACTIONS_AND_NOTIFICATIONS}
                onClick={() => props.history.push(TOOLS_PAGES.ACTIONS_AND_NOTIFICATIONS)}
              >
                <NavigationItemName>Actions and notifications</NavigationItemName>
              </NavigationItem>
            </NavigationBlock>
          </ToolsNavigation>
        )}

      <ToolsRoutes data={data} />
    </Wrapper>
  );
};

export default Tools;
