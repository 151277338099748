import { connect } from 'react-redux';
import Expenses from './Expenses.ui';
import {
  updateExpensesVerificationData,
  updateInvoiceCreationData,
  updateBalancedIntegration,
  setExpensesCheckboxData,
  updateReimbursementInfo,
  closeExpensesOverview,
  showExpensesOverview,
  setIsOpenAddReceipts,
  fetchExpensesData,
  fetchOptionsData,
  removeReceipt,
  toggleFilters,
  closeFilters,
  updateStatus,
  addReceipt,
} from '../../store/expenses/actions';

const mapStateToProps = ({
  expensesReducer: {
    isUpdateExpensesStatus,
    isUploadingReceipts,
    isOpenAddReceipts,
    isOpenOverview,
    checkboxesData,
    isFiltersOpen,
    expensesData,
    overviewData,
    
    totalItems,
    options,
  },
  loadingReducer: { isLoadingData },
  currentUser,
}) => ({
  isUpdateExpensesStatus,
  isUploadingReceipts,
  isOpenAddReceipts,
  currentUserData: currentUser.currentUserData,
  checkboxesData,
  isOpenOverview,
  isFiltersOpen,
  overviewData,
  expensesData,
  options,
  total: totalItems,
  isLoadingData,
});

const mapDispatchToProps = {
  updateExpensesVerificationData,
  updateInvoiceCreationData,
  updateBalancedIntegration,
  setExpensesCheckboxData,
  updateReimbursementInfo,
  closeExpensesOverview,
  setIsOpenAddReceipts,
  showExpensesOverview,
  fetchExpenses: fetchExpensesData,
  removeReceipt,
  toggleFilters,
  fetchOptions: fetchOptionsData,
  updateStatus,
  closeFilters,
  addReceipt,
};

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
