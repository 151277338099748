export const TOOLS_PAGES = {
  IGNORE_LIST_ACCOMMODATIONS: '/tools/ignored-accommodations',
  ACTIONS_AND_NOTIFICATIONS: '/tools/actions-and-notifications',
  DAILY_ALLOWANCE_TYPES: '/tools/daily-allowance-types',
  BUSINESS_VERTICALS: '/tools/business-verticals',
  RECOMMENDED_HOTELS: '/tools/recommended-hotels',
  EMPLOYMENT_TYPES: '/tools/employment-types',
  SUBSCRIPTIONS: '/tools/subscriptions',
  CUSTOM_HOTELS: '/tools/custom-hotels',
  COMPANIES: '/tools/companies',
  EMPLOYERS: '/tools/employers',
  COUNTRIES: '/tools/countries',
  FEEDBACKS: '/tools/feedbacks',
  PURPOSES: '/tools/purposes',
  TEAMS: '/tools/teams',
  ROLES: '/tools/roles',
  TOOLS: '/tools',
};

export const EDIT_PROFILE_TABS = {
  LOYALTY_PROGRAMS: '/edit-profile/loyalty',
  FAVORITE_HOTELS: '/edit-profile/favorites',
  CRYPTO_WALLET: '/edit-profile/crypto',
  DOCUMENT: '/edit-profile/document',
  SECURITY: '/edit-profile/security',
  SETTINGS: '/edit-profile/settings',
  DETAILS: '/edit-profile/details',
  MAIN: '/edit-profile',
};

export const TAB_NAMES = {
  [EDIT_PROFILE_TABS.LOYALTY_PROGRAMS]: 'Loyalty programs',
  [EDIT_PROFILE_TABS.FAVORITE_HOTELS]: 'Favorite hotels',
  [EDIT_PROFILE_TABS.CRYPTO_WALLET]: 'Crypto wallets',
  [EDIT_PROFILE_TABS.DETAILS]: 'Account details',
  [EDIT_PROFILE_TABS.SETTINGS]: 'Profile settings',
  [EDIT_PROFILE_TABS.DOCUMENT]: 'Travel document',
  [EDIT_PROFILE_TABS.SECURITY]: 'Security',
  [EDIT_PROFILE_TABS.MAIN]: 'Profile details',
};
