import { useState, useMemo, useEffect } from 'react';
import { Button, TextInput } from '@heathmont/moon-core';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { GenericEdit } from '@heathmont/moon-icons';
import { getSubscriptionStatusLabel } from '../../utils/tableHelpers';
import { updatePurposeSchema } from './schema';
import FormField from '../FormField';
import Accordion from '../Accordion';
import { Log } from '../Log';
import { ISubscriptionOverviewDrawerContent } from './types';
import {
  OverviewItemsWrapper,
  EditButtonWrapper,
  OverviewItemName,
  SubscriptionLogs,
  OverviewLinkItem,
  ButtonsWrapper,
  OverviewItem,
} from './styles';

const getInitialValues = (data) => ({
  description: data?.description ?? '',
  title: data?.title ?? '',
  email: data?.email ?? '',
  link: data?.link ?? '',
  plan: data?.plan ?? '',
});

const SubscriptionOverviewDrawerContent: React.FC<ISubscriptionOverviewDrawerContent> = ({
  subscriptionLogs,
  data,
  handleUpdateSubscription,
}) => {
  const [isEditFormData, setIsEditFormData] = useState(false);
  const initialValues = useMemo(() => getInitialValues(data), [data]);

  useEffect(() => {
    setIsEditFormData(false);
  }, [data]);

  return (
    <div>
      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            onClick={() => setIsEditFormData(!isEditFormData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      {!isEditFormData ? (
        <>
          <OverviewItemsWrapper>
            <OverviewItemName>Name</OverviewItemName>
            <OverviewItem>{data?.title ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Description</OverviewItemName>

            <OverviewItem>{data?.description ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Link</OverviewItemName>

            <OverviewLinkItem href={data.link} target="_blank">
              {data.link}
            </OverviewLinkItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Price</OverviewItemName>

            <OverviewItem>{data?.plan ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Email</OverviewItemName>

            <OverviewItem>{data?.email ?? ''}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Status</OverviewItemName>

            <OverviewItem>{getSubscriptionStatusLabel(data.status)}</OverviewItem>
          </OverviewItemsWrapper>
        </>
      ) : (
        <Formik
          isInitialValid
          validateOnBlur={true}
          validationSchema={updatePurposeSchema}
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          {({ errors, values, resetForm, setFieldValue, touched, handleBlur, dirty }: any): JSX.Element => {
            return (
              <Form>
                <OverviewItemsWrapper>
                  <OverviewItemName>Name</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      value={values.title}
                      fieldId="title"
                      fieldName="title"
                      component={TextInput}
                      type="text"
                      inputSize="xlarge"
                      onChange={(e) => setFieldValue('title', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.title && errors?.title}
                      errors={touched?.title && errors}
                      errorPosition={-20}
                      label="Name"
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Description</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      value={values.description}
                      fieldId="description"
                      fieldName="description"
                      component={TextInput}
                      type="text"
                      inputSize="xlarge"
                      onChange={(e) => setFieldValue('description', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.description && errors?.description}
                      errors={touched?.description && errors}
                      errorPosition={-20}
                      label="Description"
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Link</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      value={values.link}
                      fieldId="link"
                      fieldName="link"
                      component={TextInput}
                      type="text"
                      inputSize="xlarge"
                      onChange={(e) => setFieldValue('link', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.link && errors?.link}
                      errors={touched?.link && errors}
                      errorPosition={-20}
                      label="Link"
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Price</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      value={values.plan}
                      fieldId="plan"
                      fieldName="plan"
                      component={TextInput}
                      type="text"
                      inputSize="xlarge"
                      onChange={(e) => setFieldValue('plan', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.plan && errors?.plan}
                      errors={touched?.plan && errors}
                      errorPosition={-20}
                      label="Price"
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Email</OverviewItemName>

                  <OverviewItem>
                    <FormField
                      value={values.email}
                      fieldId="email"
                      fieldName="email"
                      component={TextInput}
                      type="text"
                      inputSize="xlarge"
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      onBlur={handleBlur}
                      isError={touched?.email && errors?.email}
                      errors={touched?.email && errors}
                      errorPosition={-20}
                      label="Email"
                    />
                  </OverviewItem>
                </OverviewItemsWrapper>

                <OverviewItemsWrapper>
                  <OverviewItemName>Status</OverviewItemName>

                  <OverviewItem>{getSubscriptionStatusLabel(data.status)}</OverviewItem>
                </OverviewItemsWrapper>

                <ButtonsWrapper>
                  <Button
                    onClick={() => {
                      resetForm();
                      setIsEditFormData(false);
                    }}
                    variant="secondary"
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    disabled={!isEmpty(errors) || !dirty}
                    onClick={() => {
                      handleUpdateSubscription(data.id, values);
                      setIsEditFormData(false);
                      resetForm();
                    }}
                  >
                    Save
                  </Button>
                </ButtonsWrapper>
              </Form>
            );
          }}
        </Formik>
      )}

      <Accordion title="Subscription logs" openByDefault withoutPadding>
        <SubscriptionLogs>
          {subscriptionLogs.map(item => (
            <Log
              key={item.id}
              createdAt={item.createdAt}
              status={item.status}
            />
          ))}
        </SubscriptionLogs>
      </Accordion>
    </div>
  );
};

export default SubscriptionOverviewDrawerContent;
