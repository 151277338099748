import React from 'react'
import Dialog from '../Dialog'
import { DialogContent } from '../../pages/Trips/styles'
import UnassignedAccommodationDialogContent from '../UnassignedAccommodationDialogContent'
import { IUnassignedAccommodationDialog } from './types'
import { Formik } from 'formik'
import { UnassignedAccommodationForm } from '../UnassignedAccommodationDialogContent/schema'
import { isEmpty } from 'lodash'

const UnassignedAccommodationDialog: React.FC<IUnassignedAccommodationDialog> = ({
  accommodationData,
  isLoading,
  handleUnassignedAccommodationModalClose,
  handleSubmitUnassignedTrips,
  renderAdditionalActions,
}) => {
  const initialValues = {
    user: null,
  };

  const handleSubmitAssignment = (values) => {
    handleSubmitUnassignedTrips({...values, id: accommodationData.id})
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={UnassignedAccommodationForm}
      isInitialValid
      initialValues={initialValues}
      validateOnBlur
      onSubmit={handleSubmitAssignment}
    >
      {({ errors, values, setFieldValue, touched, setFieldTouched, submitForm}: any): JSX.Element => {
        return (
          <Dialog
            submitButtonLabel="Confirm"
            isSubmitDisabled={!isEmpty(errors) || !touched?.user}
            maxWidth={500}
            isLoading={isLoading}
            title="Match unassigned accommodation with user"
            data={[]}
            onSubmit={() => submitForm()}
            onClose={handleUnassignedAccommodationModalClose}
            renderAdditionalActions={renderAdditionalActions}
          >
            <DialogContent>
              <UnassignedAccommodationDialogContent
                accommodationData={accommodationData}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
              />
            </DialogContent>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UnassignedAccommodationDialog;