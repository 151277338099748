import { useState, useMemo, useEffect } from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { TextInput, Button, Checkbox } from '@heathmont/moon-core';
import { GenericEdit } from '@heathmont/moon-icons';
import {
  formatBusinessVerticalLabel,
  getUserStatusLabel,
  formatDateAndTime,
  getUsersRoleLabel,
  getRoleLabelById,
  formatManager,
} from '../../../utils/tableHelpers';
import { checkOnIsEqual, N_A } from '../../../utils/commonFunctions';
import EllipsisText from '../../../components/EllipsisText';
import CustomSelect from '../../../components/CustomSelect';
import FormField from '../../../components/FormField';
import {
  getInitialFormValues,
  userOverviewSchema,
} from './schema';
import { IUserSettingsForm } from './types';
import {
  OverviewItemsWrapper,
  EditButtonWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from '../UserDrawerContent/styles';
import UserConfirmationSettingsModal from '../UserConfirmationSettingsModal';
import {
  getAvailableBusinessVerticalsForUser,
  getAvailableEmployeesForUser,
  getAvailableEmployersForUser,
  getAvailableTeamsForUser,
  formatManagerAssistants,
  isManager,
} from './utils';

const UserSettingsForm: React.FC<IUserSettingsForm> = ({
  isBalancedActiveForUserCompany,
  businessVerticals,
  isUserRegistered,
  companyEmployees,
  employmentTypes,
  isUserExternal,
  isCurrentUser,
  employers,
  managers,
  teams,
  roles,
  data,
  updateDetails,
}) => {
  const initialFormValues = useMemo(() => getInitialFormValues(data), [data]);
  const [isOpenedWarningAboutSkippingManagerApproval, setOpenedWarningAboutSkippingManagerApproval] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userOverviewSchema,
    validateOnMount: true,
    initialValues: initialFormValues,
    onSubmit: (values, { resetForm }): void => {
      if (!initialFormValues.isSkippedManagerApproval && values.isSkippedManagerApproval) {
        setOpenedWarningAboutSkippingManagerApproval(true);
        return;
      }

      updateDetails(data, values, setIsEditFormData);
      resetForm();
    },
  });
  const { touched, errors, values, resetForm, setFieldValue, setFieldTouched, setValues } = formik;
  const [isEditFormData, setIsEditFormData] = useState(false);

  useEffect(() => {
    setIsEditFormData(false);
    resetForm();
  }, [data.id]);

  const handleUpdateRole = (value) => {
    const newValues = {
      ...values,
      isSkippedManagerNotifications: false,
      assistants: [],
      role: value,
    };
    setValues(newValues, true);
  };

  const handleBusinessVerticalChange = (value) => {
    const newValues = {
      ...values,
      businessVerticalId: value,
      assistants: [],
      employerId: null,
      manager: null,
      teamId: null,
    };
    setValues(newValues, true);
  };

  const handleAssistantsChange = (value) => {
    const newValues = {
      ...values,
      isSkippedManagerNotifications: value?.length
        ? values?.isSkippedManagerNotifications
        : false,
      assistants: value,
    };
    setValues(newValues, true);
  };

  return (
    <>
      {!isEditFormData && !isCurrentUser && (
        <EditButtonWrapper>
          <Button variant="ghost" onClick={() => setIsEditFormData(!isEditFormData)} iconLeft={<GenericEdit fontSize="1.2rem" />}>
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <FormikProvider value={formik}>
        <Form>
          <OverviewItemsWrapper>
            <OverviewItemName>User ID</OverviewItemName>
            <OverviewItem>{data?.id}</OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>First name</OverviewItemName>

            <OverviewItem>
              {isEditFormData ? (
                <FormField
                  errorPosition={-20}
                  placeholder="First name"
                  fieldName="firstname"
                  inputSize="medium"
                  component={TextInput}
                  fieldId="firstname"
                  isError={touched?.firstname && errors?.firstname}
                  errors={touched?.firstname && errors}
                  value={values.firstname}
                  type="text"
                  onChange={(e) => setFieldValue('firstname', e.target.value, true)}
                  onBlur={() => setFieldTouched('firstname', true, false)}
                />
              ) : (
                <EllipsisText text={values.firstname} />
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Last name</OverviewItemName>

            <OverviewItem>
              {isEditFormData ? (
                <FormField
                  errorPosition={-20}
                  placeholder="Last name"
                  fieldName="lastname"
                  component={TextInput}
                  inputSize="medium"
                  fieldId="lastname"
                  isError={touched?.lastname && errors?.lastname}
                  errors={touched?.lastname && errors}
                  value={values.lastname}
                  type="text"
                  onChange={(e) => setFieldValue('lastname', e.target.value, true)}
                  onBlur={() => setFieldTouched('lastname', true, false)}
                />
              ) : (
                <EllipsisText text={values.lastname} />
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Email</OverviewItemName>
            <OverviewItem>
              <EllipsisText text={data?.email} />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper style={{ position: 'relative' }}>
            <OverviewItemName>Role</OverviewItemName>

            <OverviewItem>
              {(isEditFormData && !isUserExternal) ? (
                <CustomSelect
                  currentValue={values.role}
                  isError={!!(touched?.role && errors?.role)}
                  error={(touched?.role && errors?.role) as string}
                  items={roles}
                  getItemLabel={(value) => getRoleLabelById(value, roles)}
                  onChange={handleUpdateRole}
                  onBlur={() => setFieldTouched('role', true, false)}
                />
              ) : (
                getUsersRoleLabel(data?.role)
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Business vertical</OverviewItemName>
            <OverviewItem>
              {isEditFormData ? (
                <CustomSelect
                  currentValue={values.businessVerticalId}
                  placeholder="Business vertical"
                  isError={!!(touched?.businessVerticalId && errors?.businessVerticalId)}
                  error={(touched?.businessVerticalId && errors?.businessVerticalId) as string}
                  items={getAvailableBusinessVerticalsForUser({
                    businessVerticals,
                    userStatus: data?.status,
                  })}
                  onChange={handleBusinessVerticalChange}
                  onBlur={() => setFieldTouched('businessVerticalId', true, false)}
                />
              ) : (
                <OverviewItem>
                  {data?.businessVertical?.name ? (
                    formatBusinessVerticalLabel(data?.businessVertical?.name, data?.businessVertical?.type, true)
                  ) : (
                    N_A
                  )}
                </OverviewItem>
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Team</OverviewItemName>
            <OverviewItem>
              {isEditFormData ? (
                <CustomSelect
                  currentValue={values.teamId}
                  placeholder="Team"
                  isError={!!(touched?.teamId && errors?.teamId)}
                  error={(touched?.teamId && errors?.teamId) as string}
                  items={getAvailableTeamsForUser({
                    businessVerticalId: values?.businessVerticalId,
                    userStatus: data?.status,
                    teams,
                  })}
                  onChange={(value) => setFieldValue('teamId', value, true)}
                  onBlur={() => setFieldTouched('teamId', true, false)}
                />
              ) : (
                <EllipsisText text={data?.team?.name || N_A} />
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Employer</OverviewItemName>
            <OverviewItem>
              {isEditFormData ? (
                <CustomSelect
                  currentValue={values.employerId}
                  placeholder="Employer"
                  isError={!!(touched?.employerId && errors?.employerId)}
                  error={(touched?.employerId && errors?.employerId) as string}
                  items={getAvailableEmployersForUser({
                    businessVerticalId: values?.businessVerticalId,
                    userStatus: data?.status,
                    employers,
                  })}
                  onChange={(value) => setFieldValue('employerId', value, true)}
                  onBlur={() => setFieldTouched('employerId', true, false)}
                />
              ) : (
                <EllipsisText text={data?.employer?.name || N_A} />
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          {isUserRegistered && (
            <>
              <OverviewItemsWrapper>
                <OverviewItemName>Employment type</OverviewItemName>
                <OverviewItem>
                  {isEditFormData ? (
                    <CustomSelect
                      currentValue={values.employmentType}
                      isError={!!(touched?.employmentType && errors?.employmentType)}
                      error={(touched?.employmentType && errors?.employmentType) as string}
                      items={employmentTypes}
                      onChange={(value) => setFieldValue('employmentType', value, true)}
                      onBlur={() => setFieldTouched('employmentType', true, false)}
                    />
                  ) : (
                    <EllipsisText text={data?.employmentType?.label} />
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>

              {isBalancedActiveForUserCompany && (
                <OverviewItemsWrapper>
                  <OverviewItemName>BALANCED.IO integration number</OverviewItemName>

                  <OverviewItem>
                    {isEditFormData ? (
                      <FormField
                        errorPosition={-20}
                        placeholder="Integration number"
                        fieldName="balancedId"
                        component={TextInput}
                        inputSize="medium"
                        fieldId="balancedId"
                        isError={touched?.balancedId && errors?.balancedId}
                        errors={touched?.balancedId && errors}
                        value={values.balancedId}
                        type="text"
                        onChange={(e) => setFieldValue('balancedId', e.target.value, true)}
                        onBlur={() => setFieldTouched('balancedId', true, false)}
                      />
                    ) : (
                      <EllipsisText text={data?.balancedId || N_A} />
                    )}
                  </OverviewItem>
                </OverviewItemsWrapper>
              )}

              <OverviewItemsWrapper>
                <OverviewItemName>Jira ID</OverviewItemName>

                <OverviewItem>
                  {isEditFormData ? (
                    <FormField
                      errorPosition={-20}
                      placeholder="Jira ID"
                      inputSize="medium"
                      fieldName="jiraId"
                      component={TextInput}
                      fieldId="jiraId"
                      isError={touched?.jiraId && errors?.jiraId}
                      errors={touched?.jiraId && errors}
                      value={values.jiraId}
                      type="text"
                      onChange={(e) => setFieldValue('jiraId', e.target.value, true)}
                      onBlur={() => setFieldTouched('jiraId', true, false)}
                    />
                  ) : (
                    <EllipsisText text={data?.jiraId || N_A} /> 
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Manager</OverviewItemName>

                <OverviewItem>
                  {isEditFormData ? (
                    <CustomSelect
                      currentValue={values.manager}
                      isSearchable
                      isClearable
                      placeholder="Manager"
                      isError={!!(touched?.manager && errors?.manager)}
                      error={(touched?.manager && errors?.manager) as string}
                      items={getAvailableEmployeesForUser({
                        businessVerticalId: values.businessVerticalId,
                        employees: managers,
                        userId: values.userId,
                      })}
                      onChange={(value) => setFieldValue('manager', value, true)}
                      onBlur={() => setFieldTouched('manager', true, false)}
                    />
                  ) : (
                    formatManager(data?.manager)
                  )}
                </OverviewItem>
              </OverviewItemsWrapper>
            </>
          )}

          {(isUserRegistered || isUserExternal) && (
            <>
              <OverviewItemsWrapper>
                <OverviewItemName>Created at</OverviewItemName>
                <OverviewItem>
                  <div>
                    {data?.createdAt ? formatDateAndTime(data?.createdAt, true) : N_A}
                  </div>
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Activated at</OverviewItemName>
                <OverviewItem>
                  <div>
                    {data?.activatedAt ? formatDateAndTime(data?.activatedAt, true) : N_A}
                  </div>
                </OverviewItem>
              </OverviewItemsWrapper>
            </>
          )}

          {isManager(values.role, roles) && (
            <OverviewItemsWrapper>
              <OverviewItemName>Assistants</OverviewItemName>
              <OverviewItem>
                {isEditFormData ? (
                  <CustomSelect
                    currentValue={values.assistants}
                    isSearchable
                    placeholder="Assistants"
                    isMulti
                    isError={!!(touched?.assistants && errors?.assistants)}
                    error={(touched?.assistants && errors?.assistants) as string}
                    items={getAvailableEmployeesForUser({
                      businessVerticalId: values.businessVerticalId,
                      employees: companyEmployees,
                      userId: values.userId,
                    })}
                    onChange={handleAssistantsChange}
                    onBlur={() => setFieldTouched('assistants', true, false)}
                  />
                ) : data.assistants.length ? (
                  <span className='user-assistants'>
                    {formatManagerAssistants(data?.assistants)}
                  </span>
                ) : (
                  <EllipsisText text={N_A} />
                )}
              </OverviewItem>
            </OverviewItemsWrapper>
          )}

          <OverviewItemsWrapper>
            <OverviewItemName>Status</OverviewItemName>
            <OverviewItem>
              {getUserStatusLabel(data?.status)}
            </OverviewItem>
          </OverviewItemsWrapper>

          {data?.hibobId && (
            <OverviewItemsWrapper>
              <OverviewItemName>Hibob ID</OverviewItemName>
              <OverviewItem>
                <EllipsisText text={data?.hibobId || N_A} />
              </OverviewItem>
            </OverviewItemsWrapper>
          )}

          {isUserRegistered && (
            <OverviewItemsWrapper>
              <OverviewItemName>Comment</OverviewItemName>
              <OverviewItem>
                {isEditFormData ? (
                  <FormField
                    errorPosition={-20}
                    placeholder="Comment"
                    component={TextInput}
                    fieldName="comment"
                    inputSize="medium"
                    fieldId="comment"
                    isError={touched?.comment && errors?.comment}
                    errors={touched?.comment && errors}
                    value={values.comment}
                    type="text"
                    onChange={(e) => setFieldValue('comment', e.target.value, true)}
                    onBlur={() => setFieldTouched('comment', true, false)}
                  />
                ) : data?.comment || N_A}
              </OverviewItem>
            </OverviewItemsWrapper>
          )}

          {isUserRegistered && isManager(values.role, roles) && (
            <OverviewItemsWrapper>
              <OverviewItemName>Do not notify to approve trips or expenses</OverviewItemName>
              <OverviewItem>
                <Checkbox
                  disabled={!isEditFormData || !values.assistants.length}
                  checked={values.isSkippedManagerNotifications}
                  onChange={() => setFieldValue('isSkippedManagerNotifications', !values.isSkippedManagerNotifications)}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
          )}

          {isUserRegistered && (
            <OverviewItemsWrapper>
              <OverviewItemName>Trips don't require manager's approval</OverviewItemName>
              <OverviewItem>
                <Checkbox
                  disabled={!isEditFormData}
                  checked={values.isSkippedManagerApproval}
                  onChange={() => setFieldValue('isSkippedManagerApproval', !values.isSkippedManagerApproval)}
                />
              </OverviewItem>
            </OverviewItemsWrapper>
          )}

          {isEditFormData && (
            <ButtonsWrapper>
              <Button
                variant="secondary"
                onClick={() => {
                  resetForm();
                  setIsEditFormData(!isEditFormData);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                disabled={!isEmpty(errors) || checkOnIsEqual(values, initialFormValues)}
              >
                Save
              </Button>
            </ButtonsWrapper>
          )}
        </Form>
      </FormikProvider>            

      {isOpenedWarningAboutSkippingManagerApproval && (
        <UserConfirmationSettingsModal
          values={values}
          data={data}
          setIsEditFormData={setIsEditFormData}
          updateDetails={updateDetails}
          setIsOpen={setOpenedWarningAboutSkippingManagerApproval}
        />
      )}
    </>
  );
};

export default UserSettingsForm;
