import { v4 as uuidv4 } from 'uuid';
import Yup from '../../../utils/validation';
import { FavoriteHotel } from '../../../enums/FavoriteHotel';

const validateMessages = {
  regionId: {
    required: 'City cannot be empty',
  },
  hotelId: {
    required: 'Hotel cannot be empty',
  },
  userId: {
    required: 'User cannot be empty',
  },
};

export const userFavoriteHotelsSchema = Yup.object().shape({
  favoriteHotels: Yup.array().of(Yup.object().shape({
    userId: Yup
      .number()
      .required(validateMessages.userId.required),
    regionId: Yup
      .number()
      .required(validateMessages.regionId.required),
    hotelId: Yup
      .string()
      .required(validateMessages.hotelId.required),
  })),
});

export const getInitialFavoriteHotels = (favoriteHotels?: FavoriteHotel[]) => ({
  favoriteHotels: favoriteHotels?.length
    ? favoriteHotels.map(favoriteHotel => ({
        regionId: favoriteHotel?.hotel?.region?.id,
        hotelId: favoriteHotel?.hotel?.id,
        userId: favoriteHotel?.user?.id,
        id: uuidv4(),
      }))
    : [],
});
