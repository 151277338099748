import { useState, useMemo, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Form, FormikProvider, useFormik } from 'formik';
import { GenericEdit } from '@heathmont/moon-icons';
import { TextInput, Button } from '@heathmont/moon-core';
import { N_A, checkOnIsEqual } from '../../../utils/commonFunctions';
import EllipsisText from '../../../components/EllipsisText';
import CustomSelect from '../../../components/CustomSelect';
import DatePicker from '../../../components/DatePicker';
import FormField from '../../../components/FormField';
import Accordion from '../../../components/Accordion';
import InfoBlock from '../../../components/InfoBlock';
import {
  getInitialPassengerValues,
  passengerSchema,
} from './schema';
import { IUserPassengerForm } from './types';
import {
  OverviewItemsWrapper,
  EditButtonWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from '../UserDrawerContent/styles';
import { formatDateFieldOnSave, isGenderValid } from './utils';

const UserPassengerForm: React.FC<IUserPassengerForm> = ({
  isUserRegistered,
  isCurrentUser,
  genders,
  titles,
  data,
  updatePassenger,
}) => {
  const [isEditFormPassengerData, setIsEditFormPassengerData] = useState(false);
  const initialPassengerValues = useMemo(() => getInitialPassengerValues(data), [data]);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: passengerSchema,
    validateOnMount: true,
    initialValues: initialPassengerValues,
    onSubmit: (values): void => {
      values.birthdate = formatDateFieldOnSave(values.birthdate);
      updatePassenger(data, values, setIsEditFormPassengerData);
    },
  });
  const { touched, errors, values, resetForm, setFieldValue, setFieldTouched } = formik;

  useEffect(() => {
    setIsEditFormPassengerData(false);
    resetForm();
  }, [data.id]);

  return (
    <Accordion title="Passenger" openByDefault withoutPadding>
      {!isEditFormPassengerData && !isCurrentUser && (
        <EditButtonWrapper>
          <Button
            variant="ghost"
            onClick={() => setIsEditFormPassengerData(!isEditFormPassengerData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <FormikProvider value={formik}>
        <Form>
          {
            isUserRegistered && values?.passenger_email && values?.passenger_email !== data?.passenger?.email && (
              <InfoBlock text={`All user's future calendar trip events will be resent to the new passenger email.`} />
            )
          }

          <OverviewItemsWrapper>
            <OverviewItemName>First name</OverviewItemName>

            <OverviewItem>
              {isEditFormPassengerData ? (
                <FormField
                  errorPosition={-20}
                  placeholder="First name"
                  fieldName="firstname"
                  component={TextInput}
                  inputSize="medium"
                  fieldId="firstname"
                  isError={touched?.firstname && errors?.firstname}
                  errors={touched?.firstname && errors}
                  value={values.firstname}
                  type="text"
                  onChange={(e) => setFieldValue('firstname', e.target.value, true)}
                  onBlur={() => setFieldTouched('firstname', true, false)}
                />
              ) : (
                values.firstname ? <EllipsisText text={values.firstname} /> : N_A
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Last name</OverviewItemName>

            <OverviewItem>
              {isEditFormPassengerData ? (
                <FormField
                  errorPosition={-20}
                  placeholder="Last name"
                  fieldName="lastname"
                  component={TextInput}
                  inputSize="medium"
                  fieldId="lastname"
                  isError={touched?.lastname && errors?.lastname}
                  errors={touched?.lastname && errors}
                  value={values.lastname}
                  type="text"
                  onChange={(e) => setFieldValue('lastname', e.target.value, true)}
                  onBlur={() => setFieldTouched('lastname', true, false)}
                />
              ) : (
                values.lastname ? <EllipsisText text={values.lastname} /> : N_A
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Passenger email</OverviewItemName>

            <OverviewItem>
              {isEditFormPassengerData ? (
                <FormField
                  errorPosition={-20}
                  placeholder="Passenger email"
                  fieldName="passenger_email"
                  component={TextInput}
                  inputSize="medium"
                  fieldId="passenger_email"
                  isError={touched?.passenger_email && errors?.passenger_email}
                  errors={touched?.passenger_email && errors}
                  value={values.passenger_email}
                  type="email"
                  onChange={(e) => setFieldValue('passenger_email', e.target.value, true)}
                  onBlur={() => setFieldTouched('passenger_email', true, false)}
                />
              ) : (
                values.passenger_email ? <EllipsisText text={values.passenger_email} /> : N_A
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Phone</OverviewItemName>

            <OverviewItem>
              {isEditFormPassengerData ? (
                <FormField
                  errorPosition={-20}
                  placeholder="Phone"
                  fieldName="passenger_mobile_number"
                  component={TextInput}
                  inputSize="medium"
                  fieldId="passenger_mobile_number"
                  isError={touched?.passenger_mobile_number && errors?.passenger_mobile_number}
                  errors={touched?.passenger_mobile_number && errors}
                  value={values.passenger_mobile_number}
                  type="text"
                  onChange={(e) => setFieldValue('passenger_mobile_number', e.target.value, true)}
                  onBlur={() => setFieldTouched('passenger_mobile_number', true, false)}
                />
              ) : (
                values.passenger_mobile_number ? <EllipsisText text={values.passenger_mobile_number} /> : N_A
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Birth date</OverviewItemName>

            <OverviewItem>
              <DatePicker
                isEditView={isEditFormPassengerData}
                isError={!!(touched?.birthdate && errors?.birthdate)}
                errors={touched?.birthdate && errors}
                value={values.birthdate}
                field="birthdate"
                onChange={(field, value) => setFieldValue(field, value, true)}
                onBlur={() => setFieldTouched('birthdate', true, false)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Gender</OverviewItemName>
            <OverviewItem>
              {isEditFormPassengerData ? (
                <CustomSelect
                  currentValue={values.gender}
                  placeholder='Gender'
                  isError={!!(touched?.gender && errors?.gender)}
                  error={(touched?.gender && errors?.gender) as string}
                  items={genders}
                  onChange={(value) => setFieldValue('gender', value, true)}
                  onBlur={() => setFieldTouched('gender', true, false)}
                />
              ) : (
                !isGenderValid(data?.passenger?.isFemale) ? <EllipsisText text={data?.passenger?.isFemale ? 'Female' : 'Male'} /> : N_A
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Title</OverviewItemName>
            <OverviewItem>
              {isEditFormPassengerData ? (
                <CustomSelect
                  currentValue={values.title}
                  placeholder='Title'
                  isError={!!(touched?.title && errors?.title)}
                  error={(touched?.title && errors?.title) as string}
                  items={titles}
                  onChange={(value) => setFieldValue('title', value, true)}
                  onBlur={() => setFieldTouched('title', true, false)}
                />
              ) : (
                data?.passenger?.title?.keyword ? <EllipsisText text={data?.passenger?.title?.keyword} /> : N_A
              )}
            </OverviewItem>
          </OverviewItemsWrapper>
    
          {isEditFormPassengerData && (
            <ButtonsWrapper>
              <Button
                variant="secondary"
                type="reset"
                onClick={() => {
                  resetForm();
                  setIsEditFormPassengerData(!isEditFormPassengerData);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                disabled={!isEmpty(errors) || checkOnIsEqual(values, initialPassengerValues)}
              >
                Save
              </Button>
            </ButtonsWrapper>
          )}
        </Form>
      </FormikProvider>
    </Accordion>
  );
};

export default UserPassengerForm;
