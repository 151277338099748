import moment from 'moment';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import { RadioDateGroup } from '../../components/RadioDateGroup';

export const getDashboardFilters = (
  isOpenDatePicker,
  openDatePickerField,
  filters,
  options,
  handleDatePicketOpen,
  setFilter,
  toggleFiltersAndToFirstPage,
  toggleFilter
): any => {
  return [
    {
      title: 'Time',
      openByDefault: true,
      component: (
        <RadioDateGroup
          isOpenDatePicker={isOpenDatePicker && openDatePickerField === 'period'}
          withAllOption={false}
          tripTime={filters?.period}
          range={filters?.periodRange}
          data={options?.relativeDates}
          name="period"
          setIsOpenDatePicker={handleDatePicketOpen('period')}
          handleDateRange={(value) => setFilter('periodRange', value)}
          handleTripDate={(value) => setFilter('period', value)}
        />
      ),
    },
    ...(options?.businessVerticals?.length > 1 ? ([{
      title: `Business vertical • ${options?.businessVerticals?.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.businessVerticals}
          data={options?.businessVerticals}
          onSelect={(value) => toggleFilter('businessVerticals', value)}
        />
      ),
    }]) : []),
    {
      title: `Employer • ${options?.employers?.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.employers}
          data={options?.employers}
          onSelect={(value) => toggleFilter('employers', value)}
        />
      ),
    },
    {
      title: `Team • ${options?.teams.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.teams}
          data={options?.teams}
          onSelect={(value) => toggleFiltersAndToFirstPage('teams', value)}
        />
      ),
    },
    {
      title: `Purpose • ${options?.purposes.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.purposes}
          data={options?.purposes}
          onSelect={(value) => toggleFiltersAndToFirstPage('purposes', value)}
        />
      ),
    },
  ];
};

export const lineChartOptions = [
  {
    label: 'All',
    dataKey: 'all',
    color: '#3F7EF3',
    yAxisId: 'left',
    isActive: true,
  },
  {
    label: 'Flight',
    dataKey: 'flight',
    color: '#FFC95C',
    yAxisId: 'left',
    isActive: true,
  },
  {
    label: 'Accommodation',
    dataKey: 'accommodation',
    color: '#8A67F4',
    yAxisId: 'left',
    isActive: true,
  },
  {
    label: 'Other transport',
    dataKey: 'otherTransport',
    color: '#0DE76C',
    yAxisId: 'left',
    isActive: true,
  },
];

export const barChartOptions = [
  {
    label: 'Total cost (EUR)',
    dataKey: 'totalCost',
    color: '#3F7EF3',
    yAxisId: 'right',
    isActive: true,
  },
  {
    label: 'Trip number',
    dataKey: 'tripNumber',
    color: '#0DE76C',
    yAxisId: 'left',
    isActive: true,
  },
  {
    label: 'Reservations number',
    dataKey: 'reservationsNumber',
    color: '#8A67F4',
    yAxisId: 'left',
    isActive: true,
  },
];

export const getLineChartData = (chartData) =>
  chartData.map((item) => ({
    name: moment(item.date).format('DD/MM'),
    flight: +item.flightsCount,
    accommodation: +item.accommodationsCount,
    otherTransport: +item.otherTransportsCount,
    all: +item.count,
    tooltip: {
      date: moment(item.date).format('dddd DD/MM'),
      otherTransport: {
        totalCost: item.otherTransportsTotalCost,
        totalReservation: item.otherTransportsCount,
      },
      flight: {
        totalCost: item.flightsTotalCost,
        totalReservation: item.flightsCount,
      },
      accommodation: {
        totalCost: item.accommodationsTotalCost,
        totalReservation: item.accommodationsCount,
      },
      all: {
        totalCost: item.totalCost,
        totalReservation: item.count,
      },
    },
  }));

export const getBarChartData = (chartData) =>
  chartData.map((item) => ({
    name: moment(item.date).format('DD/MM'),
    totalCost: +item.totalCost,
    tripNumber: item.trips,
    reservationsNumber: item.reservations,
    tooltip: {
      date: moment(item.date).format('dddd DD/MM'),
    },
  }));
