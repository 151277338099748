import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import maldives from '../static/bg/maldives.png';
import checkIcons from '../static/icons/check-rounded.svg';

export const LogoImg = styled.img`
  display: block;
  margin-bottom: 2.25rem;
`;

export const Avatar = styled.img`
  height: ${({ small }) => (small ? 40 : 64)}px;
  width: ${({ small }) => (small ? 40 : 64)}px;
  object-fit: cover;
  display: block;
  border-radius: ${({ theme }) => theme.radius.largest}px;
`;

export const FullScreenBg = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem 0;
  z-index: 1;
  background-color: #18191c;

  &::after {
    width: 100vw;
    min-height: 100vh;
    content: '';
    position: absolute;
    background-image: url(${({ image }) => image || maldives});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 87rem;
  width: 95%;
  margin: 0 auto;
  z-index: 3;
`;
export const Form = styled.form`
  color: ${({ theme }) => theme.color.piccolo['100']};
  background-color: ${({ theme }) => theme.color.hit['100']};
  border-radius: 16px;
  width: 100%;
  max-width: 624px;
  min-height: 100px;
  overflow: hidden;
  position: relative;
`;

export const MessageContainer = styled.div`
  color: ${({ theme }) => theme.color.piccolo['100']};
  background-color: ${({ theme }) => theme.color.hit['100']};
  border-radius: 16px;
  width: 100%;
  max-width: 624px;
  min-height: 100px;
  overflow: hidden;
  position: relative;
`;

export const FormContent = styled.div`
  padding: 2.5rem 2.5rem 0;
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  padding: 0 40px;

  & div:first-child {
    margin: 10px 0 10px 0;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
     flex-wrap: initial;
  `}
`;

export const TabButton = styled.button`
  display: inline-flex;
  align-items: center;
  color: ${({ theme, active, checked }) => (active || checked ? theme.color.bulma['100'] : theme.color.trunks['100'])};
  font-size: 14px;
  border: none;
  background-color: transparent;
  font-weight: ${({ active }) => (active ? '400' : '300')};
  cursor: default;

  @media (max-width: 700px) {
    padding: 0 !important;
  }

  &:not(:first-of-type)::before {
    width: 10px;
    height: 1px;
    background-color: ${({ theme, active, checked }) =>
      checked ? theme.color.piccolo['100'] : active ? theme.color.krillin['100'] : theme.color.beerus['100']};
    content: '';
    margin-right: 10px;

    @media (max-width: 700px) {
      display: none;
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  `}
`;

export const TabButtonEdit = styled.button`
  display: inline-flex;
  align-items: center;
  color: ${({ theme, active, checked }) => (active || checked ? theme.color.bulma['100'] : theme.color.trunks['100'])};
  font-size: 14px;
  border: none;
  padding: 4px 16px;
  background-color: ${({ active }) => (active ? 'rgba(253, 239, 196, 0.38)' : 'transparent')};
  border-radius: 5rem;
  border: 1px solid ${({ theme, active }) => (active ? theme.color.hit['120'] : theme.color.beerus['100'])};
  font-weight: ${({ active }) => (active ? '400' : '300')};

  @media (max-width: 700px) {
    padding: 0 !important;
  }
  &:not(:first-of-type) {
    margin-left: 10px;
  }
`;

export const TabButtonIcon = styled.span`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, active }) => (active ? 'transparent' : theme.color.trunks['100'])};
  font-size: 8px;
  border-radius: ${({ theme }) => theme.radius.largest}px;
  width: ${({ theme }) => theme.fontSize.body}px;
  height: ${({ theme }) => theme.fontSize.body}px;
  border: 1px solid
    ${({ theme, active, checked }) =>
      checked ? theme.color.piccolo['100'] : active ? theme.color.krillin['100'] : theme.color.beerus['100']};
  margin-right: 5px;
  position: relative;
  font-weight: 400;
  background-color: ${({ checked, theme }) => checked && theme.color.piccolo['100']};
  background-image: url(${checkIcons});
  background-repeat: no-repeat;
  background-position: 45% 60%;
  ${({ withoutMargin }) =>
    !withoutMargin &&
    `
     margin-right: 5px;
  `}

  @media (max-width: 700px) {
    margin-right: 5px;
  }

  & span {
    line-height: 1;
    transform: translateY(9%);
    ${({ checked }) => checked && 'color: transparent'}

    @media (max-width: 700px) {
      width: 20px;
    }
  }
  &::after {
    position: absolute;
    width: ${({ theme }) => theme.radius.small}px;
    height: ${({ theme }) => theme.radius.small}px;
    border-radius: ${({ theme }) => theme.radius.small}px;
    background-color: ${({ theme }) => theme.color.krillin['100']};
    content: '';
    display: ${({ active, checked }) => (checked ? 'none' : active ? 'block' : 'none')};
  }
`;

export const SelectGroupWrapper = styled.div`
  & div:first-of-type div input,
  & div:first-of-type div span {
    border-radius: 10px 10px 0 0 !important;
  }
  & div:last-of-type div input,
  & div:last-of-type div span {
    border-radius: 0 0 10px 10px !important;
  }
`;

export const FormScrollWrapper = styled.div`
  height: calc(100vh - 490px);
  max-height: 500px;
  min-height: 320px;
  overflow-y: auto;
`;

export const HeadingCustom = styled.h1`
  font-size: 64px;
  color: ${({ theme }) => theme.color.goku['100']};
  max-width: 560px;
  margin-left: 12%;
  @media (max-width: 1199px) {
    display: none;
  }
`;

export const DashWrapper = styled.div`
  display: flex;
  margin: 12px 0;
`;

export const Dash = styled.div`
  height: 2px;
  max-width: 90px;
  flex-grow: 1;
  background-color: ${({ theme, checked, checkedPercent }) => {
    if (!checked) return theme.color.beerus['100'];
    if (checkedPercent === 1) return theme.color.roshi['100'];
    if (checkedPercent > 0.3) return theme.color.krillin['100'];
    if (checkedPercent) return theme.color.chiChi['100'];
  }};
  &:not(:first-of-type) {
    margin-left: 12px;
  }
`;

export const PhoneInputWrapper = styled.div`
  border: 1px solid ${({ theme, isError }) => (isError ? theme.color.chiChi['100'] : theme.color.beerus['100'])};
  padding: 10px 16px;
  border-radius: 0 0 14px 14px;

  & .PhoneInput {
    font-size: 1rem;

    & .PhoneInputInput {
      outline: none !important;
      border: none !important;
    }
  }
`;

export const RefreshIconWrapper = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

export const TertButtonWrapper = styled.div`
  & button {
    border-color: transparent;
    text-decoration: underline;
  }
`;

export const NumberCell = styled.span`
  text-align: right;
  display: block;
  width: 100%;
  font-weight: bold;
`;

export const LabelWrapper = styled.span`
  ${({ isExpenses, withMaxHeight }) =>
    !isExpenses &&
    `
    display: flex;
  `}

  span {
    border-radius: 4px;
    white-space: nowrap;
    display: inline-flex;
    ${({ withSpanMarginRight }) => withSpanMarginRight && `
      margin-right: 10px;
    `}
    ${({ withMaxHeight }) => withMaxHeight && `
        max-height: 24px;
    `}
  }
`;

export const LabelsWrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  height: 100%;

  span {
    display: block;
    border-radius: 4px;
    white-space: nowrap;
    margin: 4px;
    height: 25px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TeamsItemWrapper = styled.div`
  min-height: 30px;
  margin-top: 5px;
`;

export const ExpensesType = styled.div`
  color: #78787d;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
`;

export const AmountRateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

export const ExpensesRate = styled.span`
  color: #78787d;
  font-size: 12px;
  line-height: 12px;
`;

export const EuroLabel = styled.span`
  color: #181b1c;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  height: 16px;
  background: rgba(253, 239, 196, 0.38);
  border-radius: 4px;
  padding: 2px 8px;
`;

export const SlackIconWrapper = styled.span`
  display: flex;
  align-items: center;

  & span {
    margin-left: 12px;
  }

  ${({ isValid }) =>
    isValid &&
    `
    cursor: pointer;
  `}
`;

export const TableContainer = styled.div`
  position: relative;

  table {
    border-spacing: 0;
    border-top: 1px solid ${({ theme }) => theme.color.beerus['100']};
    border-bottom: 1px solid ${({ theme }) => theme.color.beerus['100']};
    font-size: 14px;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid ${({ theme }) => theme.color.beerus['100']};
        &.grouped {
          background-color: #f6f5f4;
          &:not(:first-of-type) {
            border-top: 8px solid ${({ theme }) => theme.color.goku['100']};
          }
          td {
            border-right: transparent;
            &.groupedBy {
              background-color: #f9f3e2;
              border-left: 1px solid ${({ theme }) => theme.color.beerus['100']};
              border-right: 1px solid ${({ theme }) => theme.color.beerus['100']};
            }
          }
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 1rem;
      border-right: 1px solid ${({ theme }) => theme.color.beerus['100']};

      :last-child,
      :first-child {
        border-right: 0;
      }
      &.groupedBy {
        background-color: ${({ theme }) => theme.color.ghost['100']};
      }
    }
    th {
      font-weight: 400;
      font-size: 12px;
      color: ${({ theme }) => theme.color.trunks['100']};
      white-space: nowrap;
    }
    tbody {
      tr {
        td {
          padding-top: ${({ rowHeight }) => rowHeight};
          padding-bottom: ${({ rowHeight }) => rowHeight};
        }
      }
    }
  }
`;

export const TableColumnsFilter = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.color.goku['100']};
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: ${({ theme }) => theme.radius.default}px;
  padding: ${({ theme }) => theme.fontSize.body}px;
  width: 100%;
  max-width: 300px;
  margin-right: 3px;
  z-index: 11;

  & [class^='CheckboxLabel'] {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 1.75rem;
    }
    p {
      margin-bottom: 0;
    }
    & [class^='CheckboxCaption'] {
      margin-left: 0;
    }
    & [class^='CheckboxCaption']::after,
    & [class^='CheckboxCaption']::before {
      right: 0;
      left: auto;
    }
  }

  & label {
    display: flex;
  }
`;

export const SidebarPage = styled.div`
  display: flex;
  width: 100%;
  background-color: #ccc;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

export const SidebarMenuNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 8px;
  width: 64px;
  background-color: #f6f5f4;
`;

export const MenuList = styled.ul`
  list-style: none;
  padding-left: 0;
  & li:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

export const Filters = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.color.goku['100']};
  width: 100%;
  max-width: 320px;
  overflow-y: auto;
  border-right: 1px solid ${({ theme }) => theme.color.beerus['100']};
  & [class^='layout__AccordionWrapper']:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.color.beerus['100']};
    border-radius: 0;
  }
  & [class^='layout__ChildrenWrapper'] {
    padding-top: 0;
    & label {
      margin-bottom: 0;
    }
  }
  & [class^='CheckboxCaption']:before {
    top: 33%;
  }
  & [class^='RadioButtonLabel']:before {
    margin-bottom: 33% !important;
  }
  & [class^='CheckboxLabel'] {
    display: block;
  }
`;

export const TableWrapper = styled.div`
  padding: 24px 32px;
  background-color: ${({ theme }) => theme.color.goku['100']};
  min-width: 400px;
  flex-grow: 1;
  overflow-y: auto;
`;

export const StyledNavLink = styled(NavLink)`
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radius.default}px;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    color: ${({ theme }) => theme.color.trunks['100']};
  }
  &.active {
    background-color: rgba(253, 239, 196, 0.38);
    svg path {
      color: ${({ theme }) => theme.color.piccolo['100']};
    }
  }
`;

export const FlexHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const GroupedLabel = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.color.trunks['100']};
`;

export const CustomText = styled.p`
  white-space: nowrap;
  margin-bottom: 0px;
`;

export const CustomDatesText = styled.p`
  width: 100%;
  width: 133px;
  margin-bottom: 0px;
`;

export const GroupedCount = styled.span`
  font-size: 12px;
  white-space: nowrap;
  margin-left: 5px;
  span {
    color: ${({ theme }) => theme.color.trunks['100']};
  }
`;

export const GroupedTextAligner = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const SubTitle = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.color.trunks['100']};
`;

export const ButtonsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 90%;
  margin: 0 auto;

  button {
    height: 40px;
    width: 100%;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
`;

export const NavBlock = styled.div`
  width: 64px;
`;

export const AdditionItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 4px -4px;
  min-height: 40px;

  svg {
    flex-shrink: 0;
  }
`;

export const AdditionItem = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #78787d;
  margin-left: 7px;
`;

export const HiddenRolePopoverBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: ${({ zIndex }) => (!zIndex ? 10 : zIndex)};
  backdrop-filter: ${({ blur }) => (blur ? `blur(${blur}px)` : 'none')};

  ${({ withBackground }) =>
    withBackground &&
    `
    background: rgba(24, 27, 28, 0.4);
  `}
`;

export const FilterHeaderWrapper = styled.div`
  display: flex;
`;

export const LabelValue = styled.span`
  color: #78787d;
`;

export const UserNameLabel = styled.span`
  ${({ relative }) => relative ? `
    margin-top: -3px;
    line-height: 1rem;
  ` : `
    position: absolute;
    top: -3px;
  `}
  
  margin-left: ${({ marginLeft }) => (marginLeft || '3px')};
  font-size: 0.6rem;
  color: ${({ color }) => (color)};
  background-color: ${({ backgroundColor }) => (backgroundColor)};
  border-radius: 4px;
  padding: 0 5px;
`;

export const BusinessVerticalLabelWrapper = styled.span`
  ${({ relative }) => relative ? `
    margin-top: -6px;
    line-height: 1rem;
  ` : `
    position: absolute;
    top: -3px;
  `}

  ${({ isOverview }) => isOverview ? `
    font-size: 0.4rem;
    display: inline-flex;
    align-items: center;
    height: 70%;
  ` : `
    font-size: 0.6rem;  
  `}
  
  margin-left: ${({ marginLeft }) => (marginLeft || '3px')};
  color: ${({ color }) => (color)};
  background-color: ${({ backgroundColor }) => (backgroundColor)};
  border-radius: 4px;
  padding: 0 5px;
`;

export const UserWrapper = styled.div`
  display: flex;
  position: relative;
  margin-right: ${({ marginRight }) => (marginRight || '25px')};;
`;

export const TextWithLabelWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const TextWithLabel = styled.span`
  display: flex;
  position: relative;
  margin-right: 34px;
  margin-bottom: 0;
  white-space: nowrap;
`;

export const LabelForText = styled.span`
  position: absolute;
  background-color: ${({ backgroundColor }) => (backgroundColor || '#F5A300')};
  color: ${({ color }) => (color || '#FDF5E2')};
  top: -3px;
  left: calc(100% + 3px);
  font-size: 0.6rem;
  border-radius: 4px;
  padding: 0 5px;
`;
