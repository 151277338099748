import { useEffect, useRef } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button, Checkbox } from '@heathmont/moon-core';
import { IDailyAllowanceTypeForm } from './types';
import {
  OverviewItemsWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from '../DailyAllowanceOverviewDrawerContent/styles';
import CustomSelect from '../CustomSelect';
import { dailyAllowanceTypeSchema, getInitialValues } from '../AddNewDailyAllowanceTypeModal/schema';

const DailyAllowanceTypeForm: React.FC<IDailyAllowanceTypeForm> = ({
  data,
  handleUpdateAllowance,
  setIsEditFormData,
}) => {
  const mounted = useRef(false);
  const options = useSelector((state: any) => state.dailyAllowanceTypesReducer.options);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: dailyAllowanceTypeSchema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: getInitialValues(data),
    onSubmit: (values, { resetForm }) => {
      handleUpdateAllowance(values);
      setIsEditFormData(false);
      resetForm();
    },
  });
  const { touched, values, errors, setFieldValue, setFieldTouched, resetForm, setValues } = formik;

  useEffect(() => {
    if (mounted.current) {
      setValues({ ...values, businessVertical: null });
    }
  }, [values.company]);
  
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleApplyToAllCompanies = (e) => {
    const newValues = {
      ...values,
      applyToAllCompanies: e.target.checked,
      company: null,
    };
    setValues(newValues, true);
    setFieldTouched('applyToAllCompanies', true, false);
    setFieldTouched('company', true, false);
  };

  const handleApplyToAllBusinessVerticals = (e) => {
    const newValues = {
      ...values,
      applyToAllBusinessVerticals: e.target.checked,
      businessVertical: null,
    };
    setValues(newValues, true);
    setFieldTouched('applyToAllBusinessVerticals', true, false);
    setFieldTouched('businessVertical', true, false);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <OverviewItemsWrapper>
          <OverviewItemName>ID</OverviewItemName>
          <OverviewItem>{data.id}</OverviewItem>
        </OverviewItemsWrapper>

        <OverviewItemsWrapper>
          <OverviewItemName>Company</OverviewItemName>
          <OverviewItem>
            <CustomSelect
              currentValue={values.company}
              placeholder="Company"
              isDisabled={values.isDefault || values.applyToAllCompanies}
              isError={!!(touched?.company && errors?.company)}
              error={touched?.company && errors?.company}
              items={options.companies}
              onChange={value => setFieldValue('company', value, true)}
              onBlur={() => setFieldTouched('company', true, false)}
            />
          </OverviewItem>
        </OverviewItemsWrapper>

        <OverviewItemsWrapper>
          <OverviewItemName>Apply to all companies</OverviewItemName>
          <OverviewItem>
            <Checkbox
              checked={values.applyToAllCompanies}
              disabled={values.isDefault}
              onChange={handleApplyToAllCompanies}
            />
          </OverviewItem>
        </OverviewItemsWrapper>

        <OverviewItemsWrapper>
          <OverviewItemName>Business Vertical</OverviewItemName>
          <OverviewItem>
            <CustomSelect
              currentValue={values.businessVertical}
              placeholder="Business Vertical"
              isDisabled={values.isDefault || values.applyToAllBusinessVerticals || !values.company}
              isError={!!(touched?.businessVertical && errors?.businessVertical)}
              error={touched?.businessVertical && errors?.businessVertical}
              items={options.businessVerticals.filter(businessVertical => businessVertical.company.id === values.company)}
              onChange={value => setFieldValue('businessVertical', value, true)}
              onBlur={() => setFieldTouched('businessVertical', true, false)}
            />
          </OverviewItem>
        </OverviewItemsWrapper>

        <OverviewItemsWrapper>
          <OverviewItemName>Apply to all business verticals</OverviewItemName>
          <OverviewItem>
            <Checkbox
              checked={values.applyToAllBusinessVerticals}
              disabled={values.isDefault}
              onChange={handleApplyToAllBusinessVerticals}
            />
          </OverviewItem>
        </OverviewItemsWrapper>

        <OverviewItemsWrapper>
          <OverviewItemName>Currency</OverviewItemName>
          <OverviewItem>
            <CustomSelect
              currentValue={values.currency}
              placeholder="Currency"
              isError={!!(touched?.currency && errors?.currency)}
              error={touched?.currency && errors?.currency}
              items={options.currencies}
              onChange={value => setFieldValue('currency', value, true)}
              onBlur={() => setFieldTouched('currency', true, false)}
            />
          </OverviewItem>
        </OverviewItemsWrapper>

        <ButtonsWrapper>
          <Button
            variant="secondary"
            onClick={() => {
              resetForm();
              setIsEditFormData(false);
            }}
          >
            Cancel
          </Button>
          <Button disabled={!isEmpty(errors)} type="submit">
            Save
          </Button>
        </ButtonsWrapper>
      </Form>
    </FormikProvider>
  );
};

export default DailyAllowanceTypeForm;
