import axios from 'axios';
import request from '../../models/request';
import { getErrorStatusMessage } from '../../utils/commonFunctions';
import { setIsLoadingData } from '../spinner/actions';
import {
  UPDATE_EXPENSE_REPORT_INVOICE_CREATION_DATA,
  REMIND_TO_VERIFY_EXPENSES_REPORT,
  UPDATE_BALANCED_INTEGRATION,
  SET_EXPENSES_CHECKBOX_DATA,
  SET_EXPENSES_OPTIONS_DATA,
  SET_IS_UPLOADING_RECEIPTS,
  UPDATE_REIMBURSEMENT_INFO,
  SET_IS_OPEN_ADD_RECEIPTS,
  CLOSE_EXPENSES_OVERVIEW,
  SHOW_EXPENSES_OVERVIEW,
  SET_EXPENSES_DATA,
  TOGGLE_FILTERS,
  CLOSE_FILTERS,
  UPDATE_STATUS,
} from './actionTypes';

export const toggleFilters = () => ({
  type: TOGGLE_FILTERS,
});

export const closeFilters = () => ({
  type: CLOSE_FILTERS,
});

export const updateStatus = (trip) => ({
  payload: { trip },
  type: UPDATE_STATUS,
});

export const setExpensesCheckboxData = (checkboxData, isRemoveAll = false) => {
  return {
    type: SET_EXPENSES_CHECKBOX_DATA,
    payload: { 
      data: checkboxData,
      isRemoveAll,
    },
  };
};

export const updateInvoiceCreationData = (tripId, user, createdAt) => ({
  payload: { tripId, user, createdAt },
  type: UPDATE_EXPENSE_REPORT_INVOICE_CREATION_DATA,
});

export const updateExpensesVerificationData = (trip) => ({
  payload: { trip },
  type: REMIND_TO_VERIFY_EXPENSES_REPORT,
});

const setOptionsData = (data) => ({ type: SET_EXPENSES_OPTIONS_DATA, payload: { data } });

export const updateReimbursementInfo = (tripId, expensePaymentInfo) => async (dispatch) => {
  try {
    const apiExpensePaymentInfo = { 
      reimburseTo: expensePaymentInfo.reimburseTo,
      reimburseEmployer: expensePaymentInfo.reimburseEmployer,
      cryptoWalletId: expensePaymentInfo?.cryptoWallet?.id,
      cryptoCurrencyId: expensePaymentInfo?.cryptoCurrency?.id,
    };
    await request.update(`expenses/reimbursement-info/${tripId}`, { expensePaymentInfo: apiExpensePaymentInfo });
    dispatch({
       type: UPDATE_REIMBURSEMENT_INFO,
       payload: { tripId, expensePaymentInfo }
    });
    getErrorStatusMessage({
      status: 200,
      message: `Reimbursement details updated successfully.`,
    });
  } catch(err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: `The reimbursement information can not be updated!`,
    });
  }
};

export const updateBalancedIntegration = (tripId, balancedIntegration) => async (dispatch) => {
  try {
    await request.update(`expenses/balanced-integration/${tripId}`, { balancedIntegration })
    dispatch({
       type: UPDATE_BALANCED_INTEGRATION,
       payload: { tripId, balancedIntegration }
    });
    getErrorStatusMessage({
      status: 200,
      message: `Balanced.io integration updated successfully.`,
    });
  } catch(err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: `The balanced integration can not be updated!`,
    });
  }
};

export const fetchOptionsData = () => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const { data } = await request.get('expenses/options');
    dispatch(setOptionsData(data));
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

const setExpensesData = (data) => ({ type: SET_EXPENSES_DATA, payload: { data } });

export const fetchExpensesData = (params: any) => async (dispatch) => {
  try {
    dispatch(setIsLoadingData(true));
    const { data } = await request.get('expenses', { params });
    dispatch(setExpensesData(data));
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingData(false));
  }
};

export const showExpensesOverview = (expense) => ({
  type: SHOW_EXPENSES_OVERVIEW,
  payload: { data: expense },
});

export const closeExpensesOverview = () => ({
  type: CLOSE_EXPENSES_OVERVIEW,
});

export const setIsOpenAddReceipts = (isOpen = false) => ({ type: SET_IS_OPEN_ADD_RECEIPTS, payload: { isOpen } });

export const setIsUploadingReceipts = (isUploading = false) => ({
  type: SET_IS_UPLOADING_RECEIPTS,
  payload: { isUploading },
});

const uploadReceipt = async (tripId, file) => {
  const response = await request.get(`files/upload-url`, { params: { type: file.type } });
  await axios.put(response.data.url, file, { headers: { 'Content-Type': file.type, } });
  return request.post(`expenses/receipts/${tripId}`, { key: response.data.key });
}

export const addReceipt = (tripId, files) => async (dispatch, getState) => {
  dispatch(setIsUploadingReceipts(true));
  const uploadedReceipts = [];
  let error;

  for (const file of files) {
    try {
      const result = await uploadReceipt(tripId, file);
      uploadedReceipts.push(result.data.receipt);
    } catch(err) {
      error = err;
      console.error(err);
    }
  }

  if (uploadedReceipts.length === files.length) {
    getErrorStatusMessage({
      status: 200,
      message: `All receipts are successfully uploaded!`,
    });
  } else if (uploadedReceipts?.length) {
    getErrorStatusMessage({
      status: 500,
      message: `One part of the receipts have been uploaded. But some didn't upload. So please try to upload the rest of the receipts a little later!`,
    });
  } else {
    getErrorStatusMessage({
      status: error?.response?.status,
      message: `The receipts can not be uploaded. Try again later!`,
    });
  }

  const { expensesReducer: { overviewData } } = getState();
  const receipts = [ ...overviewData.receipts, ...uploadedReceipts ]
  
  dispatch(showExpensesOverview({ ...overviewData, receipts }));
  dispatch(setIsUploadingReceipts(false));
  dispatch(setIsOpenAddReceipts(false));
};

export const removeReceipt = (tripId, receiptId) => async (dispatch, getState) => {
  try {
    await request.delete(`expenses/receipts/${tripId}/${receiptId}`)

    const { expensesReducer: { overviewData } } = getState();

    dispatch(showExpensesOverview({
      ...overviewData,
      receipts: overviewData.receipts.filter((receipt) => receipt.id !== receiptId),
    }));

    getErrorStatusMessage({
      status: 200,
      message: `The receipt is successfully removed`,
    });
  } catch (err) {
    getErrorStatusMessage({
      status: err?.response?.status,
      message: `Something went wrong. Try again later.`,
    });
  }
};
