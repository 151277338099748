import Yup from '../../../utils/validation';
import validationSchema from '../../../utils/validation-schema';

const validateMessages = {
  loyaltyNumbers: {
    max: `Max number of loyalty programs is ${validationSchema.USER.LOYALTY_PROGRAMS.max}.`,
    number: {
      required: 'Loyalty number cannot be empty',
      min: 'Loyalty number cannot be less than 1 characters',
      max: 'Loyalty number cannot be longer than 255 characters',
    },
    airline: {
      required: 'Airline cannot be empty',
      min: 'Airline number cannot be less than 1',
    },
  },
};

export const userLoyaltyNumbersSchema = Yup.object().shape({
  loyaltyNumbers: Yup
    .array()
    .of(Yup.object().shape({
      number: Yup
        .string()
        .min(1, validateMessages.loyaltyNumbers.number.min)
        .max(255, validateMessages.loyaltyNumbers.number.max)
        .withoutSpaces()
        .required(validateMessages.loyaltyNumbers.number.required),
      airline: Yup
        .number()
        .min(1, validateMessages.loyaltyNumbers.airline.min)
        .required(validateMessages.loyaltyNumbers.airline.required),
    }))
    .max(validationSchema.USER.LOYALTY_PROGRAMS.max, validateMessages.loyaltyNumbers.max),
});

export const getInitialLoyaltyNumbersValues = (data?: any) => ({
  loyaltyNumbers: data?.loyaltyNumbers?.length
    ? data.loyaltyNumbers.map(loyaltyNumber => ({
        id: loyaltyNumber.id,
        number: loyaltyNumber.number,
        airline: loyaltyNumber.airline.id,
      }))
    : [],
});
