import { useState, useMemo, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Form, FormikProvider, useFormik } from 'formik';
import { GenericEdit } from '@heathmont/moon-icons';
import { TextInput, Button } from '@heathmont/moon-core';
import { N_A, checkOnIsEqual } from '../../../utils/commonFunctions';
import EllipsisText from '../../../components/EllipsisText';
import CustomSelect from '../../../components/CustomSelect';
import DatePicker from '../../../components/DatePicker';
import FormField from '../../../components/FormField';
import Accordion from '../../../components/Accordion';
import {
  userOverviewDocumentSchema,
  getInitialDocumentValues,
} from './schema';
import { IUserPassengerDocumentForm } from './types';
import {
  OverviewItemsWrapper,
  EditButtonWrapper,
  OverviewItemName,
  ButtonsWrapper,
  OverviewItem,
} from '../UserDrawerContent/styles';
import { formatDateFieldOnSave } from './utils';

const UserPassengerDocumentForm: React.FC<IUserPassengerDocumentForm> = ({
  travelDocumentTypes,
  isCurrentUser,
  countries,
  data,
  updateDocument,
}) => {
  const [isEditFormPassportData, setIsEditPassportData] = useState(false);
  const initialDocumentValues = useMemo(() => getInitialDocumentValues(data), [data]);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userOverviewDocumentSchema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialDocumentValues,
    onSubmit: (values): void => {
      values.document_valid = formatDateFieldOnSave(values.document_valid);
      updateDocument(data, values, setIsEditPassportData);
    },
  });
  const { touched, errors, values, resetForm, setFieldValue, setFieldTouched } = formik;

  useEffect(() => {
    setIsEditPassportData(false);
    resetForm();
  }, [data.id]);

  return (
    <Accordion title="Document" openByDefault withoutPadding>
      {!isEditFormPassportData && !isCurrentUser && (
        <EditButtonWrapper>
          <Button
            variant="ghost"
            onClick={() => setIsEditPassportData(!isEditFormPassportData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}

      <FormikProvider value={formik}>
        <Form>
          <OverviewItemsWrapper>
            <OverviewItemName>Type</OverviewItemName>
            <OverviewItem>
              {isEditFormPassportData ? (
                <CustomSelect
                  currentValue={values.document_type}
                  placeholder='Type'
                  isError={!!(touched?.document_type && errors?.document_type)}
                  error={(touched?.document_type && errors?.document_type) as string}
                  items={travelDocumentTypes}
                  onChange={(value) => setFieldValue('document_type', value, true)}
                  onBlur={() => setFieldTouched('document_type', true, false)}
                />
              ) : (
                data?.userDocument?.type?.name || N_A
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Country</OverviewItemName>

            <OverviewItem>
              {isEditFormPassportData ? (
                <CustomSelect
                  currentValue={values.country}
                  isSearchable
                  placeholder='Country'
                  isError={!!(touched?.country && errors?.country)}
                  error={(touched?.country && errors?.country) as string}
                  items={countries}
                  onChange={(value) => setFieldValue('country', value, true)}
                  onBlur={() => setFieldTouched('country', true, false)}
                />
              ) : (
                data?.userDocument?.country?.name || N_A
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Number</OverviewItemName>

            <OverviewItem>
              {isEditFormPassportData ? (
                <FormField
                  errorPosition={5}
                  placeholder="Number"
                  fieldName="document_number"
                  component={TextInput}
                  inputSize="medium"
                  fieldId="document_number"
                  isError={!!(touched?.document_number && errors?.document_number)}
                  errors={touched?.document_number && errors}
                  value={values.document_number}
                  type="text"
                  onChange={e => setFieldValue('document_number', e.target.value, true)}
                  onBlur={() => setFieldTouched('document_number', true, false)}
                />
              ) : (
                values.document_number ? <EllipsisText text={values.document_number} /> : N_A
              )}
            </OverviewItem>
          </OverviewItemsWrapper>

          <OverviewItemsWrapper>
            <OverviewItemName>Expiration date</OverviewItemName>

            <OverviewItem>
              <DatePicker
                isEditView={isEditFormPassportData}
                isError={!!(touched?.document_valid && errors?.document_valid)}
                errors={touched?.document_valid && errors}
                value={values.document_valid}
                field="document_valid"
                onChange={(field, value) => setFieldValue(field, value, true)}
                onBlur={() => setFieldTouched('document_valid', true, false)}
              />
            </OverviewItem>
          </OverviewItemsWrapper>

          {isEditFormPassportData && (
            <ButtonsWrapper>
              <Button
                variant="secondary"
                type="reset"
                onClick={() => {
                  resetForm();
                  setIsEditPassportData(!isEditFormPassportData);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                disabled={!isEmpty(errors) || checkOnIsEqual(values, initialDocumentValues)}
              >
                Save
              </Button>
            </ButtonsWrapper>
          )}
        </Form>
      </FormikProvider>
    </Accordion>
  );
};

export default UserPassengerDocumentForm;
