import styled from 'styled-components';

export const DoubleFieldWrapper = styled.div`
  margin: ${({ margin }) => margin || 0};

  & div {
    & div {
      font-size: 14px;

      &::after {
        z-index: 0;
      }
    }
  }
`;

export const FieldWrapper = styled.div`
  & div {
    & div {
      &:nth-child(4) {
        & div {
          font-size: 14px;
        }
      }
      & div {
        & div {
          &:nth-child(2) {
            & div {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;
