import { fetchCompanyBusinessVerticals } from '../../../store/tools/businessVerticals/api';

export const onCompanyChangeHandler = async (companyId, newCompanyId, setFieldValue, setBusinessVerticals) => {
  const isCompanyChanged = newCompanyId !== companyId;

  if (isCompanyChanged) {
    const businessVerticals = await fetchCompanyBusinessVerticals(newCompanyId);
    setBusinessVerticals(businessVerticals);
    setFieldValue('businessVerticals', []);
  }
  
  setFieldValue('company', newCompanyId);
};
