import moment from 'moment';
import Yup from '../../../utils/validation';
import validationSchema from '../../../utils/validation-schema';

const validateMessages = {
  passenger: {
    required: 'Passenger cannot be empty',
  },
  countryIssuedDocument: {
    required: 'Country issued document cannot be empty'
  },
  documentType: {
    required: 'Document type cannot be empty',
  },
  expirationDate: {
    required: 'Document expiration date cannot be empty',
    min: 'Document expiration date cannot be today or earlier',
  },
  documentNumber: {
    required: 'Document number cannot be empty',
    min: `Document number cannot be less than ${validationSchema.USER.DOCUMENT_NUMBER.min} characters`,
    max: `Document number cannot be longer than ${validationSchema.USER.DOCUMENT_NUMBER.max} characters`,
  },
};

export const userOverviewDocumentSchema = Yup.object().shape({
  passengerId: Yup
    .number()
    .typeError(validateMessages.passenger.required)
    .required(validateMessages.passenger.required),
  country: Yup
    .number()
    .typeError(validateMessages.countryIssuedDocument.required)
    .required(validateMessages.countryIssuedDocument.required),
  document_type: Yup
    .number()
    .typeError(validateMessages.documentType.required)
    .required(validateMessages.documentType.required),
  document_number: Yup
    .string()
    .min(validationSchema.USER.DOCUMENT_NUMBER.min, validateMessages.documentNumber.min)
    .max(validationSchema.USER.DOCUMENT_NUMBER.max, validateMessages.documentNumber.max)
    .withoutSpaces()
    .required(validateMessages.documentNumber.required),
  document_valid: Yup
    .string()
    .nullable()
    .test('DOB', validateMessages.expirationDate.min, (value) => !moment().isSameOrAfter(value))
    .required(validateMessages.expirationDate.required),
});

export const getInitialDocumentValues = (data?: any) => {
  const { expiredDate, country, number, type } = data?.userDocument;
  return {
    passengerId: data?.passenger?.id,
    document_number: number 
      ? number 
      : '',
    document_valid: expiredDate
      ? moment.utc(expiredDate).format('MMM DD, YYYY')
      : null,
    document_type: type?.id
      ? type.id
      : null,
    country: country?.id
      ? country.id
      : null,
  };
};
