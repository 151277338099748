import { BUSINESS_VERTICAl_TYPES } from "../../../constants";
import { EmployerTypes } from "../../../enums/EmployerTypes";
import { UserRoleType } from "../../../enums/UserRoleTypes";
import { UserStatuses } from "../../../enums/UserStatuses";
import { TeamTypes } from "../../../enums/TeamTypes";
import { getLabels } from '../../../utils/tableHelpers';

export const getAssistantsWithTheSameUserBusinessVertical = (userBusinessVertical, assistants = []) => { 
  return assistants
    .filter(assistant => userBusinessVertical?.id && userBusinessVertical.id === assistant?.businessVerticalId)
    .map(assistant => assistant?.id);
};

export const isTheSameBusinessVerticalWithManager = (userBusinessVertical, manager) => { 
  return userBusinessVertical?.id && userBusinessVertical?.id === manager?.businessVerticalId;
};

export const isTheSameBusinessVerticalWithEmployer = (userBusinessVertical, employer) => { 
  return employer?.businessVerticals?.some(businessVertical => userBusinessVertical?.id && userBusinessVertical?.id === businessVertical?.id);
};

export const isTheSameBusinessVerticalWithTeam = (userBusinessVertical, team) => { 
  return userBusinessVertical?.id && userBusinessVertical?.id === team?.businessVerticalId;
};

export const isManager = (role, roles) => {
  const managerRoleData = roles.find(({ value }) => value === role);
  return managerRoleData?.title === UserRoleType.Manager;
};

export const formatManagerAssistants = (assistants) => {
  const items = assistants.map(({ id, lastName, firstName }) => ({
    name: `${firstName} ${lastName}`,
    id,
  }));

  return getLabels(items, 'name');
};

export const getAvailableBusinessVerticalsForUser = ({
  businessVerticals,
  userStatus,
}: {
  businessVerticals: any[];
  userStatus: UserStatuses,
}) => {
  return businessVerticals.filter(businessVertical => (
    userStatus === UserStatuses.External
      ? businessVertical.type === BUSINESS_VERTICAl_TYPES.EXTERNAL
      : businessVertical.type === BUSINESS_VERTICAl_TYPES.INTERNAL
  ));
};

export const getAvailableTeamsForUser = ({
  businessVerticalId,
  userStatus,
  teams,
}: {
  businessVerticalId: number;
  userStatus: UserStatuses;
  teams: any[];
}) => {
  return teams.filter((team) => (
    team?.businessVerticals?.length
    && team?.businessVerticals.includes(businessVerticalId)
    && (userStatus === UserStatuses.External
      ? team.type === TeamTypes.EXTERNAL
      : team.type === TeamTypes.INTERNAL
    )
  ));
};

export const getAvailableEmployersForUser = ({
  businessVerticalId,
  userStatus,
  employers,
}: {
  businessVerticalId: number;
  userStatus: UserStatuses;
  employers: any[];
}) => {
  return employers.filter((employer) => (
    employer?.businessVerticals?.length
    && employer.businessVerticals.some(businessVertical => businessVertical.id === businessVerticalId)
    && (userStatus === UserStatuses.External
      ? employer.type === EmployerTypes.EXTERNAL
      : employer.type === EmployerTypes.INTERNAL
    )
  ));
};

export const getAvailableEmployeesForUser = ({
  businessVerticalId,
  employees,
  userId,
}: {
  businessVerticalId: number;
  employees: any[];
  userId: number;
}) => {
  return employees.filter((employee) => (
    employee.businessVertical?.id === businessVerticalId && employee.value !== userId
  ));
};
