import moment from 'moment';
import Yup from '../../../utils/validation';
import validationSchema from '../../../utils/validation-schema';
import { isGenderValid } from './utils';

const validateMessages = {
  user: {
    required: 'User cannot be empty',
  },
  firstName: {
    required: 'First name cannot be empty',
    min: `First name cannot be less than ${validationSchema.USER.FIRST_NAME.min} characters`,
    max: `First name cannot be longer than ${validationSchema.USER.FIRST_NAME.max} characters`,
  },
  lastName: {
    required: 'Last name cannot be empty',
    min: `Last name cannot be less than ${validationSchema.USER.LAST_NAME.min} characters`,
    max: `Last name cannot be longer than ${validationSchema.USER.LAST_NAME.max} characters`,
  },
  email: {
    email: 'Email email must be a valid email', 
    required: 'Email can not be empty',  
  },
  birthDate: {
    required: 'Birth date cannot be empty',
    min: 'Age cannot be less then 18 years old'
  },
  phone: {
    required: 'Phone cannot be empty',
  },
  title: {
    required: 'Title cannot be empty',
  },
  gender: {
    required: 'Gender cannot be empty',
  },
};

export const passengerSchema = Yup.object().shape({
  userId: Yup
    .number()
    .typeError(validateMessages.user.required)
    .required(validateMessages.user.required),
  firstname: Yup
    .string()
    .min(validationSchema.USER.FIRST_NAME.min, validateMessages.firstName.min)
    .max(validationSchema.USER.FIRST_NAME.max, validateMessages.firstName.max)
    .isInLatinCharacters()
    .withoutSpaces()
    .required(validateMessages.firstName.required),
  lastname: Yup.
    string()
    .min(validationSchema.USER.LAST_NAME.min, validateMessages.lastName.min)
    .max(validationSchema.USER.LAST_NAME.max, validateMessages.lastName.max)
    .isInLatinCharacters()
    .withoutSpaces()
    .required(validateMessages.lastName.required),
  passenger_email: Yup
    .string()
    .email(validateMessages.email.email)
    .withoutSpaces()
    .isEmailInLatinCharacters()
    .required(validateMessages.email.required),
  passenger_mobile_number: Yup
    .string()
    .isValidPhoneNumber()
    .required(validateMessages.phone.required),
  birthdate: Yup
    .string()
    .nullable()
    .test('DOB', validateMessages.birthDate.min, (value) => moment().diff(moment(value), 'years') >= 18)
    .required(validateMessages.birthDate.required),
  gender: Yup
    .number()
    .typeError(validateMessages.gender.required)
    .required(validateMessages.gender.required),
  title: Yup
    .number()
    .typeError(validateMessages.title.required)
    .required(validateMessages.title.required),
});

export const getInitialPassengerValues = (data: any) => ({
  userId: data?.id,
  passenger_mobile_number: data?.passenger?.phone ?? '',
  passenger_email: data?.passenger?.email ?? '',
  birthdate: data?.passenger?.birthDate 
    ? moment.utc(data?.passenger?.birthDate).format('MMM DD, YYYY')
    : null,
  firstname: data?.passenger?.firstName ?? '',
  lastname: data?.passenger?.lastName ?? '',
  gender: !isGenderValid(data?.passenger?.isFemale)
    ? data?.passenger?.isFemale
    : null,
  title: data?.passenger?.title?.id ?? null,
  
});
