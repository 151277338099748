import { useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { isEmpty } from "lodash";
import { Button, Checkbox } from "@heathmont/moon-core";
import { ButtonsWrapper, FormFieldWrapper  } from "./styles";
import { IAddNewDailyAllowanceTypeModal } from "./types";
import CustomSelect from "../CustomSelect";
import Dialog from "../Dialog";
import { dailyAllowanceTypeSchema, getInitialValues } from "./schema";

const AddNewDailyAllowanceTypeModal: React.FC<IAddNewDailyAllowanceTypeModal> = ({
  businessVerticalsList,
  secondButtonLabel,
  currencyList,
  companyList,
  handleCloseDialog,
  handleAgree,
}) => {
  const formik = useFormik({
    validationSchema: dailyAllowanceTypeSchema,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: getInitialValues(),
    onSubmit: handleAgree,
  });
  const { touched, values, errors, setFieldValue, setFieldTouched, resetForm, setValues } = formik;

  useEffect(() => {
    setValues({ ...values, businessVertical: null });
  }, [values.company]);

  const handleApplyToAllCompanies = (e) => {
    const newValues = {
      ...values,
      applyToAllCompanies: e.target.checked,
      company: null,
    };
    setValues(newValues, true);
    setFieldTouched('applyToAllCompanies', true, false);
    setFieldTouched('company', true, false);
  };

  const handleApplyToAllBusinessVerticals = (e) => {
    const newValues = {
      ...values,
      applyToAllBusinessVerticals: e.target.checked,
      businessVertical: null,
    };
    setValues(newValues, true);
    setFieldTouched('applyToAllBusinessVerticals', true, false);
    setFieldTouched('businessVertical', true, false);
  };

  return (
    <Dialog
      title="Add daily allowance type"
      withFooter={false}
      width={500}
      onClose={() => handleCloseDialog()}
    >
      <FormikProvider value={formik}>
        <Form>
          <FormFieldWrapper>
            <CustomSelect
              currentValue={values.company}
              placeholder="Company"
              isDisabled={values.applyToAllCompanies}
              isError={!!(touched?.company && errors?.company)}
              error={(touched?.company && errors?.company) as string}
              items={companyList}
              onChange={value => setFieldValue('company', value, true)}
              onBlur={() => setFieldTouched('company', true, false)}
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <Checkbox
              checked={values.applyToAllCompanies}
              label={'Apply to all companies'}
              onChange={handleApplyToAllCompanies}
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <CustomSelect
              currentValue={values.businessVertical}
              placeholder="Business vertical"
              isDisabled={values.applyToAllBusinessVerticals || !values.company}
              isError={!!(touched?.businessVertical && errors?.businessVertical)}
              error={(touched?.businessVertical && errors?.businessVertical) as string}
              items={businessVerticalsList.filter(businessVertical => businessVertical.company.id === values.company)}
              onChange={value => setFieldValue('businessVertical', value, true)}
              onBlur={() => setFieldTouched('businessVertical', true, false)}
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <Checkbox
              checked={values.applyToAllBusinessVerticals}
              label={'Apply to all business verticals'}
              onChange={handleApplyToAllBusinessVerticals}
            />
          </FormFieldWrapper>

          <FormFieldWrapper>
            <CustomSelect
              currentValue={values.currency}
              placeholder="Currency"
              isError={!!(touched?.currency && errors?.currency)}
              error={(touched?.currency && errors?.currency) as string}
              items={currencyList}
              onChange={value => setFieldValue('currency', value, true)}
              onBlur={() => setFieldTouched('currency', true, false)}
            />
          </FormFieldWrapper>

          <ButtonsWrapper>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                resetForm();
                handleCloseDialog();
              }}
            >Cancel</Button>
            {handleAgree && secondButtonLabel && (
              <Button
                type="submit"
                variant="primary"
                disabled={!isEmpty(errors)}
              >{secondButtonLabel}</Button>
            )}
          </ButtonsWrapper>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default AddNewDailyAllowanceTypeModal;
