import {
  SET_ALL_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA,
  SET_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA,
  UPDATE_DAILY_ALLOWANCE_TYPE_OPTIONS,
  SET_DAILY_ALLOWANCE_TYPES_OPTIONS,
  SET_DAILY_ALLOWANCE_TYPES,
  SET_SHOULD_REFRESH_OFF,
  SET_SHOULD_REFRESH_ON,
} from './actionTypes';

const initState = {
  isActiveAllAllowancesCheckboxes: false,
  allowancesCheckboxesData: [],
  dailyAllowances: [],
  shouldRefresh: false,
  totalCount: 0,
  options: {
    businessVerticals: [],
    currencies: [],
    companies: [],
  },
};

const dailyAllowanceTypesReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_DAILY_ALLOWANCE_TYPES: {
      return {
        ...state,
        dailyAllowances: action.payload.dailyAllowancesTypes,
        totalCount: action.payload.totalCount,
      };
    }

    case SET_DAILY_ALLOWANCE_TYPES_OPTIONS: {
      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload,
        },
      };
    }

    case UPDATE_DAILY_ALLOWANCE_TYPE_OPTIONS: {
      const { payload } = action; 
      return {
        ...state,
        dailyAllowances: state.dailyAllowances.map((allowance) => allowance.id === payload.id ? payload : allowance),
      };
    }
    
    case SET_SHOULD_REFRESH_ON: {
      return {
        ...state,
        shouldRefresh: true,
      };
    }
    
    case SET_SHOULD_REFRESH_OFF: {
      return {
        ...state,
        shouldRefresh: false,
      };
    }

    case SET_ALL_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA: {
      const { data, isActive } = action.payload;

      return {
        ...state,
        isActiveAllAllowancesCheckboxes: isActive,
        allowancesCheckboxesData: data,
      };
    }

    case SET_DAILY_ALLOWANCE_TYPES_CHECKBOX_DATA: {
      const { data } = action.payload;

      if (!data) {
        return {
          ...state,
          isActiveAllAllowancesCheckboxes: false,
          allowancesCheckboxesData: [],
        };
      }

      if (data.isActive) {
        return {
          ...state,
          allowancesCheckboxesData: [...state.allowancesCheckboxesData, data],
        };
      }

      return {
        ...state,
        allowancesCheckboxesData: state.allowancesCheckboxesData.filter((item) => item.allowanceId !== data.allowanceId),
        isActiveAllAllowancesCheckboxes: false,
      };
    }

    default: 
      return state;
  }
};

export default dailyAllowanceTypesReducer;
