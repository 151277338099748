import { ControlsChevronDown, ControlsChevronRight } from '@heathmont/moon-icons';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import { RadioGroup } from '../../components/RadioGroup';
import { strToUpperCase } from '../../utils/commonFunctions';

export const initialColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => {
      return (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <ControlsChevronDown /> : <ControlsChevronRight />}
        </span>
      );
    },
    SubCell: () => null,
  },
  {
    Header: 'User & Team',
    accessor: 'userAndTeam',
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Role',
    accessor: 'role',
    disableSortBy: true,
  },
  {
    Header: 'Phone',
    accessor: 'phone',
    disableSortBy: true,
  },
  {
    Header: 'Employer',
    accessor: 'employer',
    disableSortBy: true,
  },
  {
    Header: 'Business vertical',
    accessor: 'businessVertical',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },
  {
    Header: 'Registered at',
    accessor: 'registeredAt',
  },
  {
    Header: 'Slack id',
    accessor: 'slackId',
    disableSortBy: true,
  },
  {
    Header: 'Reservations',
    accessor: 'totalReservation',
  },
  {
    Header: 'Total spent, EUR',
    accessor: 'totalSpent',
  },
];

export const getUsersFilters = (filters, options, toggleFilter, setFilter): any => {
  return [
    {
      title: 'Status',
      openByDefault: true,
      component: (
        <RadioGroup
          withAllOption={false}
          selectedValue={filters?.status}
          data={options.userStatuses}
          name="status"
          onSelect={(value) => setFilter('status', value)}
        />
      ),
    },
    {
      title: `Role • ${options?.roles?.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.roles}
          data={options?.roles.map(role => ({ ...role, title: strToUpperCase(role.title) }))}
          onSelect={(value) => toggleFilter('roles', value)}
        />
      ),
    },
    ...(options?.businessVerticals?.length > 1 ? ([{
      title: `Business vertical • ${options?.businessVerticals?.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.businessVerticals}
          data={options?.businessVerticals}
          onSelect={(value) => toggleFilter('businessVerticals', value)}
        />
      ),
    }]) : []),
    {
      title: `Employer • ${options?.employers?.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.employers}
          data={options?.employers}
          onSelect={(value) => toggleFilter('employers', value)}
        />
      ),
    },
    {
      title: `Team • ${options?.teams?.length}`,
      openByDefault: true,
      component: (
        <CheckboxGroup
          selectedValues={filters?.teams}
          data={options?.teams}
          onSelect={(value) => toggleFilter('teams', value)}
        />
      ),
    },
  ];
};
