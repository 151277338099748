import { useState, useMemo, useEffect } from 'react';
import { Button, TextInput } from '@heathmont/moon-core';
import { GenericEdit } from '@heathmont/moon-icons';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { formatDateAndTime } from '../../utils/tableHelpers';
import { schema } from './schema';
import FormField from '../FormField';
import { IFeedbackOverviewDrawerContent } from './types';
import { OverviewItemsWrapper, OverviewItemName, OverviewItem, EditButtonWrapper, ButtonsWrapper } from './styles';
import { N_A } from '../../utils/commonFunctions';
import StarRating from '../StarRating';

const getInitialValues = (data) => ({
  adminComments: data?.adminComments ?? '',
});

const FeedbackOverviewDrawerContent: React.FC<IFeedbackOverviewDrawerContent> = ({
  data,
  handleUpdate,
}) => {
  const [isEditFormData, setIsEditFormData] = useState(false);

  const initialValues = useMemo(() => getInitialValues(data), [data]);

  useEffect(() => {
    setIsEditFormData(false);
  }, [data]);

  return (
    <div>
      {!isEditFormData && (
        <EditButtonWrapper>
          <Button
            onClick={() => setIsEditFormData(!isEditFormData)}
            iconLeft={<GenericEdit fontSize="1.2rem" />}
            variant="ghost"
          >
            Edit
          </Button>
        </EditButtonWrapper>
      )}
      <Formik
        enableReinitialize
        isInitialValid
        validateOnBlur={true}
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        {({ errors, values, resetForm, setFieldValue, touched, handleBlur, dirty }: any): JSX.Element => {
          return (
            <Form>
              <OverviewItemsWrapper>
                <OverviewItemName>Travel app experience</OverviewItemName>
                <OverviewItem>
                  {data?.app ? <StarRating rate={+data?.app} readOnly /> : N_A}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Web app experience</OverviewItemName>
                <OverviewItem>
                  {data?.webApp ? <StarRating rate={+data?.webApp} readOnly /> : N_A}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Flight experience</OverviewItemName>
                <OverviewItem>
                  {data?.flight ? <StarRating rate={+data?.flight} readOnly /> : N_A}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Accommodation experience</OverviewItemName>
                <OverviewItem>
                  {data?.accommodation ? <StarRating rate={+data?.accommodation} readOnly /> : N_A}
                </OverviewItem>
              </OverviewItemsWrapper>

              <OverviewItemsWrapper>
                <OverviewItemName>Comments and Recommendations</OverviewItemName>
                <OverviewItem>
                  {data?.comments ?? N_A}
                </OverviewItem>
              </OverviewItemsWrapper>

              {data?.submittedBy && (
                <OverviewItemsWrapper>
                  <OverviewItemName>Submitted by</OverviewItemName>
                  <OverviewItem>
                    {data?.submittedBy.fullName}
                  </OverviewItem>
                </OverviewItemsWrapper>
              )}
              
              <OverviewItemsWrapper>
                <OverviewItemName>Submitted at</OverviewItemName>
                <OverviewItem>
                  {formatDateAndTime(data?.submittedAt, true)}
                </OverviewItem>
              </OverviewItemsWrapper> 

               <OverviewItemsWrapper>
                <OverviewItemName>Admin comments</OverviewItemName>
                <OverviewItem>
                  {
                    isEditFormData ? (
                      <FormField
                        value={values.adminComments}
                        fieldId="adminComments"
                        fieldName="adminComments"
                        component={TextInput}
                        type="text"
                        inputSize="xlarge"
                        onChange={(e) => setFieldValue('adminComments', e.target.value)}
                        onBlur={handleBlur}
                        isError={touched?.adminComments && errors?.adminComments}
                        errors={touched?.adminComments && errors}
                        errorPosition={-20}
                        label="Admin comments"
                      />
                    ) : (
                      data?.adminComments ?? N_A
                    ) 
                  }
                </OverviewItem>
              </OverviewItemsWrapper> 

              {
                isEditFormData && (
                  <ButtonsWrapper>
                    <Button
                      onClick={() => {
                        resetForm();
                        setIsEditFormData(false);
                      }}
                      variant="secondary"
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      disabled={!isEmpty(errors) || !dirty}
                      onClick={() => {
                        handleUpdate(data.id, values);
                        setIsEditFormData(false);
                        resetForm();
                      }}
                    >
                      Save
                    </Button>
                  </ButtonsWrapper>
                )
              }
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FeedbackOverviewDrawerContent;
