import { PreventDeletionReason } from '../../enums/PreventDeletionReason';
import { UserPreventDeletionReasons } from '../../enums/UserPreventDeletionReasons';

export const formatPreventDeletionReason = (reason: PreventDeletionReason) => {
  const [reasonName, reasonValues] = reason;
  const hasValues = Array.isArray(reasonValues) && reasonValues?.length;

  if (reasonName === UserPreventDeletionReasons.HAS_PENDING_TRIPS && hasValues){
    return `Pending trip${reasonValues.length > 1 ? 's' : ''}: `;      
  }

  if (reasonName === UserPreventDeletionReasons.HAS_APPROVED_TRIPS && hasValues){
    return `Approved trip${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.HAS_PENDING_EXPENSES && hasValues){
    return `Pending expense${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.HAS_UNDER_CONSIDERATION_EXPENSES && hasValues){
    return `Under consideration expense${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.HAS_VERIFYING_EXPENSES && hasValues){
    return `Verifying expense${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.HAS_CHECKED_EXPENSES && hasValues){
    return `Checked expense${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.HAS_TO_BE_PAID_EXPENSES && hasValues){
    return `To be paid expense${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.IS_USER_ONLY_ONE_TRAVELOPS_FOR_SOME_VERTICAL && hasValues){
    return `User is the only travelops for following business vertical${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.IS_USER_ONLY_ONE_ACCOUNTANT_FOR_SOME_VERTICAL && hasValues){
    return `User is the only accountant for following business vertical${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.IS_USER_SENIOR_ACCOUNTANT_FOR_SOME_VERTICAL && hasValues) {
    return `User is the senior accountant for following business vertical${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.IS_USER_ONLY_ONE_CEO_FOR_SOME_VERTICAL && hasValues){
    return `User is the only CEO for following business vertical${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.IS_USER_ONLY_ONE_TRAVELOPS_FOR_SOME_TEAM && hasValues){
    return `User is the only travelops for following team${reasonValues.length > 1 ? 's' : ''}: `;
  }

  if (reasonName === UserPreventDeletionReasons.IS_LAST_DEFAULT_COMPANY_TRAVELOPS && reasonValues){
    return 'User is the only default company travelops';
  }

  if (reasonName === UserPreventDeletionReasons.IS_LAST_DEFAULT_COMPANY_ACCOUNTANT && reasonValues){
    return 'User is the only default company accountant';
  }

  if (reasonName === UserPreventDeletionReasons.IS_LAST_DEFAULT_COMPANY_CEO && reasonValues){
    return 'User is the only default company CEO';
  }

  if (reasonName === UserPreventDeletionReasons.IS_TRAVEL_MANAGER && reasonValues){
    return 'User is the company travel manager (TTRs assignee)';
  }

  if (reasonName === UserPreventDeletionReasons.IS_SENIOR_ACCOUNTANT && reasonValues){
    return 'User is the company senior accountant (ETRs assignee)';
  }
};

export const getUserFullName = (user): string => {
  return `${(user?.firstname || user?.firstName)[0]}${(user?.lastname || user?.lastName)[0]}`;
};
