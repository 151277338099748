import { useState } from 'react';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { TextInput, Button, Checkbox } from '@heathmont/moon-core';
import FormField from '../FormField';
import { businessVerticalSchema } from './schema';
import { IAddNewBusinessVertical } from './types';
import {
  FormFieldWrapperForExternal,
  FormFieldWrapper,
  ButtonsWrapper,
} from './styles';
import { onCompanyChangeHandler } from '../../pages/Tools/BusinessVerticals/utils';
import CustomSelect from '../CustomSelect';
import Dialog from '../Dialog';
import { BUSINESS_VERTICAl_TYPES } from '../../constants';
import InfoBlock from '../InfoBlock';

const AddNewBusinessVertical: React.FC<IAddNewBusinessVertical> = ({
  companies,
  handleCloseDialog,
  handleConfirm,
}) => {
  const [options, setOptions] = useState({
    defaultTravelopses: [],
    defaultAccountants: [],
    defaultCeos: [],
    accountants: [],
    travelopses: [],
    ceos: [],
  });

  const initialValues = {
    seniorAccountant: null,
    accountants: [],
    travelopses: [],
    isDefault: false,
    company: null,
    ceos: [],
    name: '',
    type: BUSINESS_VERTICAl_TYPES.INTERNAL,
  };

  const handleBusinessVerticalTypeChange = ({
    values,
    setFieldTouched,
    setFieldValue,
  }) => {
    const newValue = values?.type === BUSINESS_VERTICAl_TYPES.INTERNAL
      ? BUSINESS_VERTICAl_TYPES.EXTERNAL
      : BUSINESS_VERTICAl_TYPES.INTERNAL;

    setFieldTouched('ceos', false, false);
    setFieldValue('ceos', []);
    setFieldTouched('type', true, false);
    setFieldValue('type', newValue);
  };

  const handleChangeAccountants = ({
    values,
    value,
    setFieldTouched,
    setFieldValue,
  }) => {
    setFieldTouched('accountants', true, false);
    setFieldValue('accountants', value);

    if (values.seniorAccountant && !value.includes(values.seniorAccountant)) {
      setFieldValue('seniorAccountant', null, false);
    }
  };

  return (
    <Dialog
      title="Add business vertical"
      withFooter={false}
      onClose={() => handleCloseDialog(false)}
      width={500}
    >
      <Formik
        isInitialValid
        validateOnMount={true}
        validateOnBlur={true}
        validationSchema={businessVerticalSchema}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }): void => {
          handleConfirm(values);
          resetForm();
        }}
      >
        {({ errors, values, touched, resetForm, setFieldValue, setFieldTouched }: any): JSX.Element => {
         return (
            <Form>
              { values?.isDefault &&
                <InfoBlock
                  text={`Default business vertical will be set to all teams and users without business vertical`}
                />
              }
              <FormFieldWrapper>
                <FormField
                  value={values.name}
                  fieldId="name"
                  fieldName="name"
                  component={TextInput}
                  type="text"
                  inputSize="xlarge"
                  onChange={(e) => setFieldValue('name', e.target.value)}
                  isError={touched?.name && errors?.name}
                  error={touched?.name && errors?.name}
                  onBlur={() => setFieldTouched('name')}
                  errors={touched?.name && errors}
                  errorPosition={-20}
                  label="Business vertical name"
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.company}
                  placeholder="Company"
                  error={touched?.company && errors?.company}
                  isError={!!(touched?.company && errors?.company)}
                  items={companies}
                  onChange={(newCompanyId) => {
                    setFieldTouched('company', true, false);
                    onCompanyChangeHandler(values.company, newCompanyId, setFieldValue, setOptions)
                  }}
                  onBlur={() => setFieldTouched('company', true, false)}                    
                />
              </FormFieldWrapper>

              <FormFieldWrapperForExternal>
                <Checkbox
                  checked={values?.type === BUSINESS_VERTICAl_TYPES.EXTERNAL}
                  label={'Only external users'}
                  onChange={() => handleBusinessVerticalTypeChange({
                    values,
                    setFieldTouched,
                    setFieldValue,
                  })}
                />
              </FormFieldWrapperForExternal>

              <FormFieldWrapper>
                <Checkbox
                  checked={values?.isDefault}
                  label={'Default business vertical'}
                  onChange={() => setFieldValue('isDefault', !values.isDefault)}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.ceos}
                  isSearchable
                  placeholder="CEO(s)"
                  isDisabled={!values.company || values.type === BUSINESS_VERTICAl_TYPES.EXTERNAL}
                  isMulti
                  isError={touched?.ceos && errors?.ceos}
                  error={touched?.ceos && errors?.ceos}
                  items={options.ceos}
                  onChange={value => {  
                    setFieldTouched('ceos', true, false);
                    setFieldValue('ceos', value)
                  }}
                  onBlur={() => setFieldTouched('ceos', true, false)}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.travelopses}
                  isSearchable
                  placeholder="Travelopses"
                  isDisabled={!values.company}
                  isMulti
                  isError={touched?.travelopses && errors?.travelopses}
                  error={touched?.travelopses && errors?.travelopses}
                  items={options.travelopses}
                  onChange={value => {
                    setFieldTouched('travelopses', true);
                    setFieldValue('travelopses', value)
                  }}
                  onBlur={() => setFieldTouched('travelopses', true, false)}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.accountants}
                  isSearchable
                  placeholder="Accountants"
                  isDisabled={!values.company}
                  isMulti
                  isError={touched?.accountants && errors?.accountants}
                  error={touched?.accountants && errors?.accountants}
                  items={options.accountants}
                  onChange={value => handleChangeAccountants({
                    values,
                    value,
                    setFieldTouched,
                    setFieldValue,
                  })}
                  onBlur={() => setFieldTouched('accountants', true, false)}
                />
              </FormFieldWrapper>

              <FormFieldWrapper>
                <CustomSelect
                  currentValue={values.seniorAccountant}
                  isSearchable
                  placeholder="Senior Accountant"
                  isDisabled={!values.company}
                  isError={touched?.seniorAccountant && errors?.seniorAccountant}
                  error={touched?.seniorAccountant && errors?.seniorAccountant}
                  items={options.accountants.filter(accountant => (
                    values.accountants.includes(accountant.value)
                  ))}
                  onChange={value => { 
                    setFieldTouched('seniorAccountant', true, false);
                    setFieldValue('seniorAccountant', value)
                  }}
                  onBlur={() => setFieldTouched('seniorAccountant', true, false)}
                />
              </FormFieldWrapper>

              <ButtonsWrapper>
                <Button
                  variant="secondary"
                  onClick={() => {
                    resetForm();
                    handleCloseDialog();
                  }}
                >Cancel</Button>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isEmpty(errors)}
                >Add</Button>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddNewBusinessVertical;
