import request from '../../../models/request';

const getFormattedAirlines = (airlines = []) => {
  return airlines.map((airline) => ({
    title: airline.title,
    value: airline.value,
  }));
};

export const fetchAirlines = async (params: { search?: string, id?: string | number }) => {
  try {
    const { data } = await request.get('airlines', { params });
    return getFormattedAirlines(data.airlines || []);
  } catch (err) {
    return [];
  }
};
