import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TextInputGroup, Select } from '@heathmont/moon-select';
import { checkOnIsEqual } from '../../../../../utils/commonFunctions';
import { setIsChangedTabField } from '../../../../../store/editProfile/actions';
import FormField from '../../../../../components/FormField';
import { ITravelDocuments } from '../../../DesktopEditProfile/Tabs/TravelDocuments/types';
import { ErrorMessage, ErrorMessageWrapper } from '../../../DesktopEditProfile/styles';
import { DoubleFieldWrapper, FieldWrapper } from './styles';

const MobileTravelDocument: React.FC<ITravelDocuments> = ({
  initialValues,
  options,
  values,
  errors,
  setFieldValue,
}) => {
  const { type, country, number, expirationDate } = values.document;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkOnIsEqual(values.document, initialValues.document)) {
      dispatch(setIsChangedTabField('document', true));
    } else {
      dispatch(setIsChangedTabField('document', false));
    }
  }, [values.document, initialValues.document]);

  return (
    <div>
      <DoubleFieldWrapper margin="0 0 24px 0">
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          inputProps={{
            input1: {
              menuPlacement: 'bottom',
              isSearchable: true,
              inputSize: 'xlarge',
              options: options.travelDocumentTypes ?? [],
              value: (options.travelDocumentTypes ?? []).find((item) => +item.value === type),
              type: 'select',
              label: 'Document type',
              onChange: ({ value }) => setFieldValue('document.type', +value),
            },
            input2: {
              menuPlacement: 'bottom',
              isSearchable: true,
              inputSize: 'xlarge',
              options: options.countries ?? [],
              value: (options.countries ?? []).find((item) => +item.value === country),
              type: 'select',
              label: 'Country issued document',
              onChange: ({ value }) => setFieldValue('document.country', +value),
            },
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <FormField
          component={TextInputGroup}
          orientation="vertical"
          type="text"
          inputProps={{
            input1: {
              type: 'text',
              value: number,
              onChange: ({ target: { value } }) => setFieldValue('document.number', value),
              inputSize: 'xlarge',
              label: 'Document number',
              dateFormat: 'dd MMM yyyy',
              isError: errors?.document?.number,
            },
            input2: {
              type: 'date',
              value: expirationDate,
              onChange: ({ target: { value } }) => setFieldValue('document.expirationDate', value),
              inputSize: 'xlarge',
              label: 'Document expiration date',
            },
          }}
        />

        {errors?.document?.number && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.document?.number}</ErrorMessage>
          </ErrorMessageWrapper>
        )}

        {errors?.document?.expirationDate && (
          <ErrorMessageWrapper>
            <ErrorMessage>{errors?.document?.expirationDate}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </DoubleFieldWrapper>
    </div>
  );
};

export default MobileTravelDocument;
