import { formatLabelForApplyToAll } from '../../utils/tableHelpers';
import { DailyAllowanceType } from '../../enums/DailyAllowanceType';

export const getDefaultTypeWarningText = () => {
  return `
    This is the default daily allowance set.
    It will be used in expense report form
    if no custom type for company or business vertical is created.
  `;
};

export const formatCompanyName = (dailyAllowanceType: DailyAllowanceType) => {
  if (dailyAllowanceType?.company?.id) {
    return dailyAllowanceType?.company?.name;
  }

  return formatLabelForApplyToAll('companies');
};

export const formatBusinessVerticalName = (dailyAllowanceType: DailyAllowanceType) => {
  if (dailyAllowanceType?.businessVertical?.id) {
    return dailyAllowanceType?.businessVertical?.name;
  }

  return formatLabelForApplyToAll('business verticals');
};
