import { Text } from '@heathmont/moon-core';
import InfoBlock from '../../../components/InfoBlock';
import Dialog from '../../../components/Dialog';
import { ConfirmDialogBodyWrapper } from '../UserDrawerContent/styles';
import { IUserConfirmationSettingsModal } from './types';

const UserConfirmationSettingsModal: React.FC<IUserConfirmationSettingsModal> = ({
  values,
  data,
  setIsEditFormData,
  updateDetails,
  setIsOpen,
}) => {
  return (
    <Dialog
      submitButtonLabel="Confirm"
      maxWidth={500}
      title="Confirm"
      onSubmit={() => {
        setIsEditFormData(false);
        updateDetails(data, values, setIsEditFormData);
        setIsOpen(false);
      }}
      onClose={() => setIsOpen(false)}
    >
      <ConfirmDialogBodyWrapper>
        <InfoBlock
          text={`
            You selected "Trips don't require manager's approval" setting for user.
            The manager approval step will be skipped for user.
            If user has pending trips, they will be approved automatically.
          `}
        />

        <Text size={16}>
          {'Are you sure you want to activate this setting?'}
        </Text>
      </ConfirmDialogBodyWrapper>
    </Dialog>
  );
};

export default UserConfirmationSettingsModal;
