import moment from 'moment';
import Yup from '../../utils/validation';
import validationSchema from '../../utils/validation-schema';

const validateMessages = {
  required: 'Field cannot be empty',
  phone: 'Phone cannot be empty',
  email: {
    required: 'Email cannot be empty',
    valid: 'Email should be in format "user@example.com"',
  },
  businessVertical: 'Business vertical cannot be empty',
  employer: 'Employer cannot be empty',
  team: 'Team cannot be empty',
  firstname: {
    required: 'First name cannot be empty',
    min: `First name cannot be less than ${validationSchema.USER.FIRST_NAME.min} characters`,
    max: `First name cannot be longer than ${validationSchema.USER.FIRST_NAME.max} characters`,
  },
  lastname: {
    required: 'Last name cannot be empty',
    min: `Last name cannot be less than ${validationSchema.USER.LAST_NAME.min} characters`,
    max: `Last name cannot be longer than ${validationSchema.USER.LAST_NAME.max} characters`,
  },
  birthDate: 'Birth date cannot be empty',
  gender: 'Gender cannot be empty',
  title: 'Title cannot be empty',
  documentType: 'Document type cannot be empty',
  issuedDocument: 'Country issued document cannot be empty',
  expirationDate: 'Document expiration date cannot be empty',
  documentNumber: {
    required: 'Document number cannot be empty',
    min: `Document number cannot be less than ${validationSchema.USER.DOCUMENT_NUMBER.min} characters`,
    max: `Document number cannot be longer than ${validationSchema.USER.DOCUMENT_NUMBER.max} characters`,
  },
  password: {
    required: 'Password cannot be empty',
    min: 'Password cannot be less than 8 characters',
  },
};

export const signUpSchema = Yup.object().shape({
  details: Yup.object().shape({
    businessVertical: Yup
      .string()
      .required(validateMessages.businessVertical)
      .nullable(),
    employer: Yup
      .string()
      .required(validateMessages.employer)
      .nullable(),
    team: Yup
      .number()
      .required(validateMessages.team)
      .nullable(),
  }),
  settings: Yup.object().shape({
    firstname: Yup
      .string()
      .min(validationSchema.USER.FIRST_NAME.min, validateMessages.firstname.min)
      .max(validationSchema.USER.FIRST_NAME.max, validateMessages.firstname.max)
      .withoutSpaces()
      .isInLatinCharacters()
      .required(validateMessages.required),
    lastname: Yup
      .string()
      .min(validationSchema.USER.LAST_NAME.min, validateMessages.lastname.min)
      .max(validationSchema.USER.LAST_NAME.max, validateMessages.lastname.max)
      .withoutSpaces()
      .isInLatinCharacters()
      .required(validateMessages.required),
    email: Yup
      .string()
      .email(validateMessages.email.valid)
      .required(validateMessages.email.required)
      .withoutSpaces()
      .isEmailInLatinCharacters(),
    passengerNumber: Yup
      .string()
      .required(validateMessages.phone)
      .isValidPhoneNumber(),
    birthDate: Yup
      .string()
      .nullable()
      .test('DOB', 'Age cannot be less then 18 years old', (value) => moment().diff(moment(value), 'years') >= 18)
      .required(validateMessages.required),
    gender: Yup
      .number()
      .required(validateMessages.gender)
      .nullable(),
    title: Yup
      .number()
      .required(validateMessages.title)
      .nullable(),
  }),
  document: Yup.object().shape({
    type: Yup
      .number()
      .required(validateMessages.documentType)
      .nullable(),
    issuedDocument: Yup
      .number()
      .required(validateMessages.issuedDocument)
      .nullable(),
    number: Yup
      .string()
      .min(validationSchema.USER.DOCUMENT_NUMBER.min, validateMessages.documentNumber.min)
      .max(validationSchema.USER.DOCUMENT_NUMBER.max, validateMessages.documentNumber.max)
      .withoutSpaces()
      .required(validateMessages.documentNumber.required),
    expirationDate: Yup
      .string()
      .nullable()
      .test('DOB', 'Document expiration date cannot be today or earlier', (value) => !moment().isSameOrAfter(value))
      .required(),
  }),
  security: Yup.object().shape({
    password: Yup
      .string()
      .required(validateMessages.password.required),
  }),
});
